import { AccountWithAddress } from './account-with-address';
import { ActivityLabel, Image } from '../atom';
import { PriceRendererCard } from 'modules/nft';
import { Address, DirectBuyStatus } from 'api';
import format from 'date-fns/format';
import { Bid, NftMedia, Offer, TToken } from 'shared/types';
import VerifiedIcon from 'assets/icons/verified-icon.svg';
import { wallet } from 'shared/lib/wallet';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { defaultDateFormat } from 'shared/config';
import { observer } from 'mobx-react-lite';

enum DirectSellStatus {
  Create = 0,
  AwaitNFT = 1,
  Active = 2,
  Filled = 3,
  Cancelled = 4,
  Expired = 5,
}

type ActivityOffer = {
  type: 'DirectBuyStateChanged';
  category: 'DirectBuy';
  nftImage: string;
  nftName: string;
  nftAddress: Address;
  collectionName: string;
  collectionAddress: Address;
  status: DirectBuyStatus;
  token: TToken;
  price: string;
  usdPrice: string;
  createdAt: number;
  createdBy: Address;
  acceptedBy: Address;
  cancelledBy: Address;
  timestamp: number;
};

type ActivityAuctionDeployed = {
  type: 'AuctionDeployed';
  category: 'Auction';
  nftImage: string;
  nftName: string;
  nftAddress: Address;
  collectionName: string;
  collectionAddress: Address;
  startBid: string;
  createdBy: Address;
  favorOf: Address;
  token: TToken;
  createdAt: Address;
  duration: number;
  timestamp: number;
  usdPrice: string;
};
type ActivityAuctionCancelled = {
  type: 'AuctionCancelled';
  category: 'Auction';
  nftImage: string;
  nftName: string;
  nftAddress: Address;
  collectionName: string;
  collectionAddress: Address;
  startBid: string;
  maxBid: string;
  createdBy: Address;
  favorOf: Address;
  token: TToken;
  createdAt: Address;
  duration: number;
  timestamp: number;
  usdPrice: string;
};
type ActivityAuctionComplete = {
  type: 'AuctionComplete';
  category: 'Auction';
  nftImage: string;
  nftName: string;
  nftAddress: Address;
  collectionName: string;
  collectionAddress: Address;
  startBid: string;
  maxBid: string;
  createdBy: Address;
  favorOf: Address;
  token: TToken;
  createdAt: Address;
  duration: number;
  timestamp: number;
  usdPrice: string;
};

type ActivityAuctionBid = {
  type: 'AuctionBidDeclined' | 'AuctionBidPlaced';
  category: 'Auction';
  nftImage: NftMedia;
  nftName: string;
  nftAddress: Address;
  collectionName: string;
  collectionAddress: Address;
  startBid: string;
  maxBid: string;
  prevBid: string;
  token: TToken;
  usdPrice: string;
  createdBy: Address;
  createdAt: Address;
  timestamp: number;
  active: boolean;
  auctionAddress: Address;
};

type ActivityTransfer = {
  type: 'NftOwnerChanged';
  category: 'Nft';
  nftImage: NftMedia;
  nftName: string;
  nftAddress: Address;
  collectionName: string;
  collectionAddress: Address;
  from: Address;
  to: Address;
  timestamp: number;
};

type ActivityMinted = {
  type: 'NftCreated';
  category: 'Collection';
  nftImage: NftMedia;
  nftName: string;
  nftAddress: Address;
  collectionName: string;
  collectionAddress: Address;
  createdBy: Address;
  createdFor: Address;
  timestamp: number;
};

type ActivityPurchase = {
  type: 'DirectSellStateChanged';
  category: 'DirectSell';
  nftImage: NftMedia;
  nftName: string;
  nftAddress: Address;
  collectionName: string;
  collectionAddress: Address;
  createdBy: Address; // put on sale or cancel sale events
  token: TToken;
  price: string;
  usdPrice: string;
  createdAt: number; // put on sale or cancel sale events
  startAt: number;
  expiredAt?: number;
  timestamp: number;
  status: keyof typeof DirectSellStatus;
};

type ActivityStructure =
  | ActivityOffer
  | ActivityAuctionDeployed
  | ActivityAuctionCancelled
  | ActivityAuctionComplete
  | ActivityAuctionBid
  | ActivityTransfer
  | ActivityMinted
  | ActivityPurchase;

export const ProfileActivity = ({ activity, date, type, from }: any) => {
  return (
    <>
      <div className="activity-item grid grid-flow-col justify-between items-center px-3 py-5">
        <div className="grid grid-flow-col justify-between items-center gap-x-4">
          <div className="w-16 h-16 bg-grey-400"></div>
          <div className="grid row-gap-y-1">
            <div className="grid grid-flow-col gap-x-1 justify-start">
              <ActivityLabel event={activity} />
              <div className="text-base text-black font-medium">Item name</div>
            </div>
            <div className="grid grid-flow-col gap-x-1 justify-start">
              <div className="text-grey-400">Placed by</div>
              <div className="mr-2">
                <AccountWithAddress size={16} address={from} />
              </div>
              <div className="text-grey-400 font-medium">expiration</div>
            </div>
            <div>{date}</div>
          </div>
        </div>
        <div className="grid grid-flow-col justify-between items-center"></div>
      </div>
    </>
  );
};

export const ActivityBid = observer(
  ({
    active,
    bid,
    createdAt,
    nftName,
    nftImage,
    collectionName,
    isCollectionVerified,
    direction = 'in',
    from,
    onProfile,
    nftAddress,
  }: Omit<Bid, 'nftImage' | 'collectionName'> & {
    direction?: 'in' | 'out';
    onProfile?: boolean;
    nftImage?: Bid['nftImage'];
    collectionName?: Bid['collectionName'];
  }) => {
    const intl = useIntl();
    const navigate = useNavigate();

    return (
      <>
        <div className="activity-item grid grid-flow-col justify-between items-center px-3 py-5">
          <div className="grid grid-flow-col justify-between items-center gap-x-4 ">
            {nftImage && (
              <Image
                src={nftImage.src}
                mimetype={nftImage?.mimetype}
                className="w-16 hover:cursor-pointer"
                onClick={() => navigate(`/nft/${nftAddress}`)}
              />
            )}

            <div className="grid gap-y-1">
              <div className="grid grid-flow-col gap-x-2 justify-start items-center">
                <ActivityLabel event={'bid'} />
                {nftName && (
                  <div
                    className="text-base text-black font-medium hover:cursor-pointer"
                    onClick={() => navigate(`/nft/${nftAddress}`)}
                  >
                    {nftName}
                  </div>
                )}
              </div>
              {collectionName && (
                <div className="grid grid-flow-col justify-start items-center gap-x-1">
                  {collectionName}
                  {isCollectionVerified && (
                    <Image
                      className="inline"
                      src={VerifiedIcon}
                      alt="verified"
                    />
                  )}
                </div>
              )}
              <div className="grid grid-flow-col gap-x-4 justify-start">
                {direction === 'in' && (
                  <div className="grid grid-flow-col justify-start gap-x-1">
                    <div className="text-grey-400">
                      {intl.formatMessage({
                        id: 'text.placed_by',
                        defaultMessage: 'Placed by',
                      })}
                    </div>
                    <div className="mr-2">
                      {from && <AccountWithAddress size={16} address={from} />}
                    </div>
                  </div>
                )}
                {onProfile && !active && (
                  <div className="text-sm text-red-400 font-medium">
                    {intl.formatMessage({
                      id: 'status.outbid',
                      defaultMessage: 'Outbid',
                    })}
                  </div>
                )}
              </div>

              <div>{format(createdAt, defaultDateFormat)}</div>
            </div>
          </div>
          <div className="grid grid-flow-col justify-between items-center gap-x-4">
            <PriceRendererCard token={bid.priceToken} price={bid.price} />
          </div>
        </div>
      </>
    );
  },
);

export const OfferBox = observer(
  ({
    buyer,
    createdAt,
    nftAddress,
    nftOwner,
    expiredAt,
    finishedAt,
    onAccept,
    onCancel,
    offerAddress,
    direction = 'in',
    nftImage,
    nftName,
    withLabel = false,
    status,
    price,
    collectionName,
    isCollectionVerified,
    isLoading,
    saleType,
  }: Offer & {
    onAccept?: () => void;
    onCancel?: () => void;
    direction?: 'in' | 'out';
    withLabel?: boolean;
    isLoading: boolean;
  }) => {
    const intl = useIntl();
    const navigate = useNavigate();

    return (
      <>
        <div className="activity-item grid grid-flow-col justify-between items-center px-3 py-5">
          <div className="grid grid-flow-col justify-between items-center gap-x-4 ">
            {nftImage && (
              <Image
                src={nftImage.src}
                mimetype={nftImage.mimetype}
                className="w-16 hover:cursor-pointer"
                onClick={() => navigate(`/nft/${nftAddress}`)}
              />
            )}

            <div className="grid gap-y-1">
              <div className="grid grid-flow-col gap-x-2 justify-start items-center">
                {withLabel && <ActivityLabel event={'offer'} />}
                {nftName && (
                  <div
                    className="text-base text-black font-medium hover:cursor-pointer"
                    onClick={() => navigate(`/nft/${nftAddress}`)}
                  >
                    {nftName}
                  </div>
                )}
              </div>
              {collectionName && (
                <div className="grid grid-flow-col justify-start items-center gap-x-1">
                  {collectionName}
                  {isCollectionVerified && (
                    <Image
                      className="inline"
                      src={VerifiedIcon}
                      alt="verified"
                    />
                  )}
                </div>
              )}
              <div className="grid grid-flow-col gap-x-4 justify-start">
                {direction === 'in' && (
                  <div className="grid grid-flow-col justify-start gap-x-1">
                    <div className="text-grey-400">
                      {intl.formatMessage({
                        id: 'text.placed_by',
                        defaultMessage: 'Placed by',
                      })}
                    </div>
                    <div className="mr-2">
                      {buyer && (
                        <AccountWithAddress size={16} address={buyer} />
                      )}
                    </div>
                  </div>
                )}
                {status === DirectBuyStatus.Active && Boolean(expiredAt) && (
                  <div className="text-grey-400">
                    {intl.formatMessage({
                      id: 'text.expiration_in',
                      defaultMessage: 'Expiration in',
                    })}
                    <span className="mx-1">
                      {expiredAt && format(expiredAt, defaultDateFormat)}
                    </span>
                  </div>
                )}
                {status === DirectBuyStatus.Cancelled && (
                  <div className="text-sm text-red-400 font-medium">
                    {intl.formatMessage({
                      id: 'status.cancelled',
                      defaultMessage: 'Cancelled',
                    })}
                  </div>
                )}
                {status === DirectBuyStatus.Expired && (
                  <div className="text-sm text-yellow-400 font-medium">
                    {intl.formatMessage({
                      id: 'status.expired',
                      defaultMessage: 'Expired',
                    })}
                  </div>
                )}
                {status === DirectBuyStatus.Filled && (
                  <div className="text-sm text-green-400 font-medium">
                    {intl.formatMessage({
                      id: 'status.filled',
                      defaultMessage: 'Filled',
                    })}
                  </div>
                )}
              </div>

              <div>{format(createdAt, defaultDateFormat)}</div>
            </div>
          </div>
          <div className="grid grid-flow-col justify-between items-center gap-x-4">
            <PriceRendererCard token={price.priceToken} price={price.price} />
            <div className="grid grid-flow-col justify-between items-center">
              {wallet.address === buyer &&
                (status === DirectBuyStatus.Active ||
                  status === DirectBuyStatus.Expired) && (
                  <button
                    className={`btn ghost ${isLoading && 'loading'}`}
                    data-color={'blue'}
                    onClick={onCancel || (() => {})}
                  >
                    {intl.formatMessage({
                      id: 'button.cancel',
                      defaultMessage: 'Cancel',
                    })}
                  </button>
                )}
              {!saleType && wallet.address === nftOwner && nftOwner !== buyer && (
                <button
                  className={`btn ghost ${isLoading && 'loading'}`}
                  data-color={'blue'}
                  onClick={onAccept || (() => {})}
                >
                  {intl.formatMessage({
                    id: 'button.accept',
                    defaultMessage: 'Accept',
                  })}
                </button>
              )}
            </div>
          </div>
        </div>
      </>
    );
  },
);
