import { Address, ProviderRpcClient } from 'everscale-inpage-provider';
import { EverscaleStandaloneClient } from 'everscale-standalone-client';
import {
  COLLECTION_ABI,
  NFT_ABI,
  INDEX_BASIS_ABI,
  INDEX_ABI,
  AUCTION_ROOT_TIP_3_ABI,
  AUCTION_TIP_3,
  TOKEN_WALLET_ABI,
  TOKEN_ROOT_ABI,
  DIRECT_BUY,
  FACTORY_DIRECT_BUY,
  DIRECT_SELL,
  FACTORY_DIRECT_SELL,
  GET_TYPE_CONTRACT,
  CALLBACK_ABI,
  DOMAIN_ABI,
} from 'abi';
import { getConnectionConfig } from './constants';

export const client = new ProviderRpcClient({
  forceUseFallback: true,
  fallback: () => {
    return EverscaleStandaloneClient.create({
      connection: {
        id: getConnectionConfig().networkId,
        group: getConnectionConfig().networkName,
        type: 'graphql',
        data: {
          endpoints: [getConnectionConfig().networkEndpoint],
          local: false,
        },
      },
      // initInput: '../../node_modules/nekoton-wasm/nekoton_wasm_bg.wasm',
    });
  },
});
export const walletProvider = new ProviderRpcClient();

const contractAbi =
  <Abi>(abi: Abi) =>
  (address: Address, provider?: ProviderRpcClient) =>
    new (provider || client).Contract(abi, address);

export const nftContract = contractAbi(NFT_ABI);
export const nftCollectionContract = contractAbi(COLLECTION_ABI);
export const indexBasisContract = contractAbi(INDEX_BASIS_ABI);
export const indexContract = contractAbi(INDEX_ABI);
export const auctionRootTip3 = contractAbi(AUCTION_ROOT_TIP_3_ABI);
export const auctionTip3 = contractAbi(AUCTION_TIP_3);
export const walletContract = contractAbi(TOKEN_WALLET_ABI);
export const tokenRootContract = contractAbi(TOKEN_ROOT_ABI);
export const factoryDirectBuyContract = contractAbi(FACTORY_DIRECT_BUY);
export const directBuyContract = contractAbi(DIRECT_BUY);
export const directSellContract = contractAbi(DIRECT_SELL);
export const factoryDirectSellContract = contractAbi(FACTORY_DIRECT_SELL);
export const contractTypeContract = contractAbi(GET_TYPE_CONTRACT);
export const callbacksContract = contractAbi(CALLBACK_ABI);
export const domainContract = contractAbi(DOMAIN_ABI);
