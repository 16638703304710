import BigNumber from 'bignumber.js';

export function formattedTokenAmount(
  value: string | number,
  decimals: number,
  options?: {
    decimalPlaces?: number;
    roundMode?: BigNumber.RoundingMode;
  },
) {
  return new BigNumber(value)
    .shiftedBy(decimals)
    .dp(options?.decimalPlaces ?? 5, options?.roundMode ?? BigNumber.ROUND_UP)
    .toFixed();
}
