import BidIcon from 'assets/icons/bid-icon.svg';
import OfferIcon from 'assets/icons/offer-icon.svg';
import AuctionCreatedIcon from 'assets/icons/auction-created-icon.svg';
import CollectionCreatedIcon from 'assets/icons/collection-created-icon.svg';
import MintNftIcon from 'assets/icons/mint-nft-icon.svg';
import TransferIcon from 'assets/icons/transfer-icon.svg';
import UpForSaleIcon from 'assets/icons/up-for-sale-icon.svg';
import PurchaseIcon from 'assets/icons/purchase-icon.svg';
import CrossIcon from 'assets/icons/cross-icon.svg';

const icons = {
  offer: OfferIcon,
  bid: BidIcon,
  auctionCreated: AuctionCreatedIcon,
  collectionCreated: CollectionCreatedIcon,
  mint: MintNftIcon,
  transfer: TransferIcon,
  upForSale: UpForSaleIcon,
  purchase: PurchaseIcon,
  cancel: CrossIcon,
};

export type TEvent = keyof typeof icons;

const whichStyle = (event: TEvent) => {
  const yellow = ['offer', 'bid'];
  const blue = ['auctionCreated', 'collectionCreated', 'mint', 'upForSale'];
  const green = ['purchase'];
  const grey = ['cancel'];

  if (yellow.includes(event)) {
    return `y-${event}`;
  }
  if (blue.includes(event)) {
    return `b-${event}`;
  }
  if (green.includes(event)) {
    return `g-${event}`;
  }
  if (grey.includes(event)) {
    return `gr-${event}`;
  }

  return '';
};

export const ActivityLabel = ({ event }: { event: TEvent }) => {
  const readableEvent = event.replace(/([A-Z])/g, ' $1');

  if (whichStyle(event)) {
    return (
      <div className="activity-label" data-event={whichStyle(event)}>
        <img src={icons[event]} />
        {readableEvent}
      </div>
    );
  }

  return null;
};
