import { tokenRepository } from 'shared/repository/token-repository';

export async function getTokenListUseCase() {
  try {
    const tokens = await tokenRepository.getTokens();

    return Array.from(tokens.values(), (tokens) => tokens);
  } catch (error) {
    console.error(error);

    return [];
  }
}
