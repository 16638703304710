//@ts-nocheck
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { SETTINGS_MODAL } from 'shared/config';
import { Input, Modal, ModalContext } from 'shared/ui';

export const SettingsModalContent = observer(
  ({ content }: { content: any }): JSX.Element | null => {
    const { handleModal } = useContext(ModalContext);
    if (settingsModal.data?.type !== SETTINGS_MODAL) {
      return null;
    }

    return (
      <Modal
        isOpen={settingsModal.data?.type === SETTINGS_MODAL}
        onClose={settingsModal.close}
      >
        <div className="flex flex-col h-full md:block md:h-auto">
          <div className="flex-1 md:w-[460px]">
            <h3 className="modal-title title">Profile details</h3>
            <div className="modal-description">
              You can set preferred display name, create your branded profile
              URL and manage other personal settings
            </div>
            <form id="settings-form" onSubmit={settingsForm.handleSubmit}>
              <div className="mb-4 form-control">
                <label className="text-black mb-1 inline-block">
                  Display name
                </label>
                <Input
                  name="name"
                  value={settingsForm.name}
                  onChange={settingsForm.handleNameChange}
                />
              </div>

              <div className="mb-4 form-control">
                <label className="text-black mb-1 inline-block">Bio</label>
                <Input
                  name="bio"
                  value={settingsForm.bio}
                  onChange={settingsForm.handleBioChange}
                />
              </div>

              <div className="mb-4 form-control">
                <label className="text-black mb-1 inline-block">
                  Personal site or portfolio
                </label>
                <Input
                  name="url"
                  placeholder="https://"
                  value={settingsForm.url}
                  onChange={settingsForm.handleUrlChange}
                />
              </div>

              <div className="mb-4 form-control">
                <label className="text-black mb-1 inline-block">Email</label>
                <Input
                  name="email"
                  value={settingsForm.email}
                  onChange={settingsForm.handleEmailChange}
                />
                <p className="text-grey-600 text-xs font-medium mt-1">
                  Your email for marketplace notifications
                </p>
              </div>

              <div className="mb-4 form-control">
                <label className="text-black mb-1 inline-block">
                  Twitter Username
                </label>
                <Input
                  name="twitter"
                  before="@"
                  after="Confirm"
                  value={settingsForm.twitter}
                  onChange={settingsForm.handleTwitterChange}
                />
                <p className="text-grey-600 text-xs font-medium mt-1">
                  Link your Twitter account to gain more trust on the
                  marketplace
                </p>
              </div>

              <div className="mb-4">
                <label className="text-black mb-2 inline-block">
                  Profile Image
                </label>
                <p className="text-xs mb-2">
                  We recommend an image of at least 300x300. Gifs work too. Max
                  5mb.
                </p>
                <label
                  className="btn secondary inline-flex w-auto px-4"
                  data-color="blue"
                >
                  Choose file
                  <input
                    className="hidden"
                    type="file"
                    name="avatar"
                    accept="image/*"
                    onChange={settingsForm.handleAvatarChange}
                  />
                </label>
              </div>

              <div className="mb-4">
                <label className="text-black mb-2 inline-block">
                  Profile Image
                </label>
                <p className="text-xs mb-2">
                  We recommend to upload images in 1440x260 resolution. Max
                  15mb.
                </p>
                <label
                  className="btn secondary inline-flex w-auto px-4"
                  data-color="blue"
                >
                  Choose file
                  <input
                    className="hidden"
                    type="file"
                    name="cover"
                    accept="image/*"
                    onChange={settingsForm.handleCoverChange}
                  />
                </label>
              </div>
            </form>
          </div>
          <div className="modal-actions mt-9">
            <button
              className="btn secondary"
              data-color="blue"
              type="button"
              onClick={settingsModal.close}
            >
              Close
            </button>
            <button
              className="btn primary"
              data-color="blue"
              type="submit"
              form="settings-form"
            >
              Update profile
            </button>
          </div>
        </div>
      </Modal>
    );
  },
);
