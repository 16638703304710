import { useContext, useEffect } from 'react';
import {
  NftCardSmall,
  Select,
  SelectOption,
  DatePicker,
  ModalContext,
} from 'shared/ui';
import { observer } from 'mobx-react-lite';
import { directSellForm } from '../model';
import { WEVER_ADDRESS } from 'shared/config';

import 'react-datepicker/dist/react-datepicker.css';
import { reservedDates } from 'shared/config';
import { TNft } from 'shared/types';
import { isOwner } from 'shared/lib/is-owner';
import { tokenSelectMapper } from 'shared/lib/select-mappers';
import { tokenService } from 'services/token-service';
import { useForm, useWatch } from 'react-hook-form';
import { SaleFormSchema, saleFormSchema } from '../model/start-sell-form';
import { addDateUnit } from 'shared/lib/add-date-unit';
import { zodResolver } from '@hookform/resolvers/zod';
import { InputN } from 'shared/ui/molecules/input';
import { endOfDay, isToday, startOfDay } from 'date-fns/esm';
import { useIntl } from 'react-intl';
import { addMinutes } from 'date-fns';

const filterPassedTime = (time: number | Date) => {
  const currentDate = new Date();
  const selectedDate = new Date(time);

  return currentDate.getTime() < selectedDate.getTime();
};

export const StartSellModalContent = observer(
  ({ content }: { content: TNft }) => {
    const intl = useIntl();
    const { handleModal } = useContext(ModalContext);
    const {
      control,
      watch,
      getValues,
      setValue,
      reset,
      getFieldState,
      resetField,
      handleSubmit,
      trigger,
      formState: { isDirty, isValid, errors, isValidating, isSubmitSuccessful },
    } = useForm<SaleFormSchema>({
      mode: 'onChange',
      resolver: zodResolver(saleFormSchema),
      defaultValues: {
        startTime: new Date(),
        endTime: null,
        price: {
          paymentToken: WEVER_ADDRESS,
          value: '',
        },
        selectDuration: '',
      },
    });
    useEffect(() => {
      reset();
    }, [isSubmitSuccessful, reset]);

    const selectDurationField = useWatch({
      control,
      name: 'selectDuration',
    });
    const startDateField = useWatch({
      control,
      name: 'startTime',
    });
    const selectPaymentToken = useWatch({
      control,
      name: 'price.paymentToken',
    });
    const endTime = useWatch({
      control,
      name: 'endTime',
    });

    useEffect(() => {
      resetField('price.value');
    }, [selectPaymentToken, resetField]);
    useEffect(() => {
      if (!selectDurationField || !startDateField) {
        resetField('endTime');

        return;
      }

      setValue(
        'endTime',
        addDateUnit({
          date: startDateField,
          value: selectDurationField,
        }),
      );
    }, [selectDurationField, startDateField, setValue]);
    if (!content) {
      return null;
    }

    const {
      name,

      image,
      owner,
      address,
      mimetype,
      collection: { name: collectionName },
    } = content;

    return (
      <div className="max-w-[417px]">
        <div className="modal-title title">
          {intl.formatMessage({
            id: 'modal.put_on_sale_title',
            defaultMessage: 'Put on sale',
          })}
        </div>
        <div className="modal-description">
          {intl.formatMessage(
            {
              id: 'modal.put_on_sale_title.description',
              defaultMessage:
                'You are about put your item {name} from the {collectionName} up for sale',
            },
            { name: name, collectionName: collectionName },
          )}
        </div>
        <div className="mb-9">
          <NftCardSmall
            media={{ src: image, mimetype }}
            collection={collectionName!}
            name={name}
            sellingMode="DIRECT_SELL"
            isOwner={isOwner(owner)}
          />
        </div>
        <div className="mb-4 form-control">
          <label className="text-black mb-1 inline-block">
            {intl.formatMessage({
              id: 'text.price',
              defaultMessage: 'Price',
            })}
          </label>
          <div className=" grid grid-cols-[minmax(125px,_135px)_1fr] items-start gap-x-2">
            <Select
              controller={{
                name: 'price.paymentToken',
                control,
              }}
              options={tokenSelectMapper(tokenService.tokens)}
            >
              {tokenService.tokens.map((token, index) => (
                <SelectOption
                  key={index}
                  value={token.address}
                  className="grid grid-flow-col items-center justify-start gap-x-2"
                >
                  <img src={token.logoURI} className="w-5 h-5" />
                  {token.symbol}
                </SelectOption>
              ))}
            </Select>

            <InputN
              disabled={Boolean(getFieldState('price.paymentToken').error)}
              controller={{
                name: 'price.value',
                control,
              }}
              number
            />
          </div>
        </div>
        <div className="mb-4 form-control">
          <label className="text-black mb-1 inline-block">
            {intl.formatMessage({
              id: 'text.start_date',
              defaultMessage: 'Start date',
            })}
          </label>

          <DatePicker
            controller={{
              name: 'startTime',
              control,
            }}
            startDate={new Date()}
            minDate={new Date()}
            filterTime={filterPassedTime}
            selectsStart
            showTimeSelect
          />
          {errors['startTime'] && (
            <div className="helper-text" data-invalid={true}>
              {errors['startTime']?.message}
            </div>
          )}
        </div>
        <div className="mb-4 form-control">
          <label className="text-black mb-1 inline-block">
            {intl.formatMessage({
              id: 'text.sale_expiration',
              defaultMessage: 'Sale expiration',
            })}
          </label>
          <div className=" grid grid-cols-[minmax(125px,_135px)_1fr] items-start gap-x-2">
            <Select
              controller={{
                name: 'selectDuration',
                control,
              }}
              options={reservedDates}
            >
              {/* TODO: Translate select value */}
              <SelectOption value="">none</SelectOption>
              {reservedDates.map((item, index) => (
                <SelectOption key={index} value={item.value}>
                  {item.label}
                </SelectOption>
              ))}
            </Select>

            {/* @ts-expect-error */}
            <DatePicker
              controller={{ name: 'endTime', control }}
              readOnly={selectDurationField !== 'custom-date'}
              disabled={!selectDurationField}
              selectsEnd
              showTimeSelect
              {...(selectDurationField === 'custom-date' && {
                endDate: endTime,
                minDate: startDateField,
                minTime:
                  endTime && isToday(endTime)
                    ? startDateField && addMinutes(startDateField, 30)
                    : endTime && startOfDay(endTime),
                maxTime: endTime && endOfDay(endTime),
              })}
            />
          </div>
          {errors['endTime'] && (
            <div className="helper-text" data-invalid={true}>
              {errors['endTime']?.message}
            </div>
          )}
        </div>
        <div className="mb-9 grid gap-y-2">
          {/* <div className="grid grid-flow-col items-center justify-between gap-x-2">
            <div>Royalty</div>
            <div className="text-black">5%</div>
          </div>
          <div className="grid grid-flow-col items-center justify-between gap-x-2">
            <div>Marketplace fee</div>
            <div className="text-black">5%</div>
          </div>
          <div className="grid grid-flow-col items-center justify-between gap-x-2">
            <div>You will receive</div>
            <div className="text-black">5%</div>
          </div>
          <div className="grid grid-flow-col items-center justify-between gap-x-2">
            <div>Service fee</div>
            <div className="text-black">5%</div>
          </div> */}
        </div>
        <div className="modal-actions">
          <button
            className="btn secondary"
            data-color="blue"
            onClick={() => handleModal()}
          >
            {intl.formatMessage({
              id: 'button.close',
              defaultMessage: 'Close',
            })}
          </button>
          <button
            className={`btn primary ${isValidating && 'loading'}`}
            data-color="blue"
            onClick={handleSubmit((data) =>
              directSellForm.startSell(data, address),
            )}
            disabled={!isDirty || !isValid || isValidating}
          >
            {intl.formatMessage({
              id: 'button.put_on_sale',
              defaultMessage: 'Put on sale',
            })}
          </button>
        </div>
      </div>
    );
  },
);
