import { makeAutoObservable, runInAction } from 'mobx';
import { PaginationOptions, Offer, PaginationItems } from 'shared/types';
import { Address, DirectBuyBody, DirectBuyStatus } from 'api';
import { getOffersForNftUseCase } from 'application/get-offers';
import { acceptOfferUseCase } from 'application/accept-offer';
import { cancelOfferUseCase } from 'application/cancel-offer';
import { nftStore } from 'modules/nft';

export const OFFERS_LIMIT = 20;
class NftDetailsOffersStore {
  offers: PaginationItems<Offer> = {
    items: [],
    totalCount: 0,
  };
  offersLoading = new Map<Address, boolean>();
  isLoading = false;
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  async getOffers(params: DirectBuyBody, options?: PaginationOptions) {
    this.isLoading = true;
    const { items, totalCount } = await getOffersForNftUseCase(params, options);

    runInAction(() => {
      this.offers = { items, totalCount };
      this.isLoading = false;
    });
  }

  async acceptOffer(offerAddress: Address, nftAddress: Address) {
    this.offersLoading.set(offerAddress, true);

    await acceptOfferUseCase(offerAddress, nftAddress);
    console.log('offer accept');
    runInAction(() => {
      this.offersLoading.delete(offerAddress);
      this.getOffers(
        { nft: nftAddress, status: [DirectBuyStatus.Active] },
        { limit: OFFERS_LIMIT },
      );
      nftStore.fetchNftDetails(nftAddress);
    });
  }
  async cancelOffer(offerAddress: Address, nftAddress: Address) {
    this.offersLoading.set(offerAddress, true);

    await cancelOfferUseCase(offerAddress);
    console.log('cancel');
    runInAction(() => {
      this.offersLoading.delete(offerAddress);

      this.getOffers(
        { nft: nftAddress, status: [DirectBuyStatus.Active] },
        { limit: OFFERS_LIMIT },
      );
      nftStore.fetchNftDetails(nftAddress);
    });
  }
}
export const offersStore = new NftDetailsOffersStore();
