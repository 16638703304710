import { observer } from 'mobx-react-lite';
import { useLanguageStore } from 'providers/intl-provider';
import { Menu } from '@headlessui/react';
import WorldIcon from 'assets/icons/world-icon.svg';

export const Language = observer(() => {
  const langStore = useLanguageStore();

  return (
    <div className="select-none relative">
      <Menu>
        <Menu.Button className=" p-2">
          <div className="grid grid-flow-col items-center ">
            <img src={WorldIcon} className="w-5 h-5" />
            <div className="ml-2 text-left">{langStore.language.text}</div>
          </div>
        </Menu.Button>
        <Menu.Items as="ul" className="dropdown-list  inset-x-0 w-max">
          {langStore.availableLanguages.map((lang) => (
            <Menu.Item
              onClick={() => langStore.setLanguage(lang)}
              key={lang.id}
              className="dropdown-item"
              as="li"
            >
              {lang.text}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Menu>
    </div>
  );
});
