export function intervalToDuration(countDown: number) {
  const years = Math.floor(countDown / (1000 * 60 * 60 * 24 * 365));
  const months = Math.floor(countDown / (1000 * 60 * 60 * 24 * 30));
  const weeks = Math.floor(countDown / (1000 * 60 * 60 * 24 * 7));
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
  );
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return { years, months, weeks, days, hours, minutes, seconds };
}
