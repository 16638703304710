import { Address } from 'api';
import { notificationMapper } from 'mappers';
import { tokenService } from 'services/token-service';
import { offerRepository } from 'shared/repository';
import { notificationStore } from 'shared/ui';

export async function acceptOfferUseCase(
  offerAddress: Address,
  nftAddress: Address,
) {
  try {
    const { notificationData } = await offerRepository.acceptOffer(
      offerAddress,
      nftAddress,
    );

    if (notificationData.event === 'directBuySuccess') {
      const {
        nft,
        newOwner,
        amount,
        token: paymentToken,
      } = notificationData.response;
      const token = tokenService.getToken(paymentToken.toString());
      const data = notificationMapper({
        nftAddress: nft.toString(),
        userAddress: newOwner.toString(),
        token,
        amount,
      });

      notificationStore.enqueueNotify({
        type: 'DirectBuyApproved',
        amount: data?.amount ?? '',
        nftAddress: data?.nftAddress ?? '',
        token: data?.token ?? '',
        userAddress: data?.userAddress ?? '',
      });
      return;
    }
  } catch (error) {
    console.error(error);
  }
}
