import { formattedTokenAmount } from 'shared/lib/formatters';
import { secondsToMilliseconds } from 'date-fns';
import { AuctionBidsBody, OwnerBidsinBody, OwnerBidsoutBody } from 'api';
import { auctionRepository } from 'shared/repository';
import { tokenRepository } from 'shared/repository/token-repository';
import { PaginationItems, PaginationOptions } from 'shared/types';
import { Bid } from 'shared/types';
import { bidMapper } from 'mappers';
export type NftBids = Awaited<ReturnType<typeof getBidsUseCase>>;
export async function getBidsUseCase(
  params: AuctionBidsBody,
  options?: PaginationOptions,
): Promise<PaginationItems<Bid>> {
  try {
    const { items, collection, totalCount, nft, auction } =
      await auctionRepository.getBids(params, options);

    const bids: Bid[] = [];

    if (!items || !collection || !nft || !auction) {
      return { items: [], totalCount: 0 };
    }

    for (const item of items) {
      const tempNft = nft[item.nft];
      const tempCollection = collection[tempNft.collection];
      const tempAuction = auction[item.auction];
      const token = tokenRepository.tokens.get(tempAuction.bidToken)!;
      const bid = bidMapper({
        auction: tempAuction.address,
        nft: tempNft.address,
        nftName: tempNft.name,
        nftImage: { src: tempNft.image, mimetype: tempNft.mimetype },
        collectionName: tempCollection.name,
        createdAt: secondsToMilliseconds(item.createdAt),
        from: item.from,
        active: item.active,
        nftType: tempNft.type,
        isCollectionVerified: tempCollection.verified,
        saleType: 'auction',
        bid: {
          price: formattedTokenAmount(item.price, -token.decimals),
          priceToken: token,
          usdPrice: item.usdPrice,
        },
      });

      bids.push(bid);
    }

    return { items: bids, totalCount };
  } catch (error) {
    console.log(error);

    return { items: [], totalCount: 0 };
  }
}

export type OwnerBidsIn = Awaited<ReturnType<typeof getBidsByOwnerInUseCase>>;
export async function getBidsByOwnerInUseCase(
  params: Omit<OwnerBidsinBody, 'limit' | 'offset'>,
  options?: PaginationOptions,
): Promise<PaginationItems<Bid>> {
  try {
    const { items, collection, totalCount, nft, auction } =
      await auctionRepository.getBidsByOwnerIn(params, options);
    const bids: Bid[] = [];

    if (!items || !collection || !nft || !auction) {
      return { items: [], totalCount: 0 };
    }

    for (const item of items) {
      const tempNft = nft[item.nft];
      const tempCollection = collection[tempNft.collection];
      const tempAuction = auction[item.auction];
      const token = tokenRepository.tokens.get(tempAuction.bidToken)!;
      const bid = bidMapper({
        auction: tempAuction.address,
        nft: tempNft.address,
        nftName: tempNft.name,
        nftImage: { src: tempNft.image, mimetype: tempNft.mimetype },
        collectionName: tempCollection.name,
        createdAt: secondsToMilliseconds(item.createdAt),
        from: item.from,
        active: item.active,
        nftType: tempNft.type,
        isCollectionVerified: tempCollection.verified,
        saleType: 'auction',
        bid: {
          price: formattedTokenAmount(item.price, -token.decimals),
          priceToken: token,
          usdPrice: item.usdPrice,
        },
      });

      bids.push(bid);
    }

    return { items: bids, totalCount };
  } catch (error) {
    console.log(error);

    return { items: [], totalCount: 0 };
  }
}

export type OwnerBidsOut = Awaited<ReturnType<typeof getBidsByOwnerOutUseCase>>;
export async function getBidsByOwnerOutUseCase(
  params: Omit<OwnerBidsoutBody, 'limit' | 'offset'>,
  options?: PaginationOptions,
): Promise<PaginationItems<Bid>> {
  try {
    const { items, collection, totalCount, nft, auction } =
      await auctionRepository.getBidsByOwnerOut(params, options);

    const bids: Bid[] = [];

    if (!items || !collection || !nft || !auction) {
      return { items: [], totalCount: 0 };
    }

    for (const item of items) {
      const tempNft = nft[item.nft];
      const tempCollection = collection[tempNft.collection];
      const tempAuction = auction[item.auction];
      const token = tokenRepository.tokens.get(tempAuction.bidToken)!;
      const bid = bidMapper({
        auction: tempAuction.address,
        nft: tempNft.address,
        nftName: tempNft.name,
        nftImage: { src: tempNft.image, mimetype: tempNft.mimetype },
        collectionName: tempCollection.name,
        createdAt: secondsToMilliseconds(item.createdAt),
        from: item.from,
        active: item.active,
        nftType: tempNft.type,
        isCollectionVerified: tempCollection.verified,
        saleType: 'auction',
        bid: {
          price: formattedTokenAmount(item.price, -token.decimals),
          priceToken: token,
          usdPrice: item.usdPrice,
        },
      });

      bids.push(bid);
    }
    return { items: bids, totalCount };
  } catch (error) {
    console.log(error);

    return { items: [], totalCount: 0 };
  }
}
