import { addDays, addMonths } from 'date-fns/esm';

export function addDateUnit({ date, value }: { date: Date; value: string }) {
  //TODO: extract left-side value from regex
  if (/(?:-month)/g.test(value)) {
    return addMonths(date, 1);
  }

  if (value === 'custom-date') {
    return addDays(date, 1);
  }
  return addDays(date, Number(value));
}
