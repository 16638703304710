import { BigNumber } from 'bignumber.js';
import {
  AbiEventName,
  Address,
  Contract,
  DecodedEventWithTransaction,
  EventsBatch,
  GetPastEventParams,
} from 'everscale-inpage-provider';
import { client, contractTypeContract, EVER_DECIMALS } from 'shared/config';
import { TAddress } from 'shared/types';

export function toAddress(address: TAddress): Address {
  if (typeof address === 'string') {
    return new Address(address);
  }

  return address;
}

export async function waitForDeploy(address: TAddress): Promise<void> {
  const subscriber = new client.Subscriber();

  try {
    const { state } = await client.getFullContractState({
      address: toAddress(address),
    });

    if (!state?.isDeployed) {
      await subscriber
        .states(toAddress(address))
        .filter(({ state }) => state.isDeployed)
        .first();
    }
  } finally {
    await subscriber.unsubscribe();
  }
}

export async function getAllPastEvents<
  Abi,
  E extends AbiEventName<Abi> = AbiEventName<Abi>,
>(
  contract: Contract<Abi>,
  args: Omit<GetPastEventParams<Abi, E>, 'continuation'> = {},
): Promise<DecodedEventWithTransaction<Abi, AbiEventName<Abi>>[]> {
  const batches: EventsBatch<Abi, E>[] = [];

  do {
    const batch = await contract.getPastEvents({
      ...args,
      continuation: batches[batches.length - 1]?.continuation,
    });

    batches.push(batch);
  } while (batches[batches.length - 1]?.continuation);

  return batches.flatMap((batch) => batch.events);
}

export function toEver(value: string | number): string {
  return new BigNumber(value).shiftedBy(EVER_DECIMALS).toFixed();
}

export async function getContractType(address: TAddress) {
  const contract = contractTypeContract(toAddress(address));
  const { value0: contractType } = await contract.methods
    .getTypeContract({})
    .call();

  return contractType;
}
// test function
export const sleep = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));
