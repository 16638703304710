import { Popover } from '@headlessui/react';
import { useIntl } from 'react-intl';
import CheckIcon from 'assets/icons/check-icon.svg';
import { Checkbox, Input, ModalContext } from '../../molecules';
import { ChangeEvent, useContext, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { SearchIcon } from '@heroicons/react/solid';
import { useDebounce } from 'shared/lib/use-debounce';
import {
  FilterCollectionsStore,
  FilterSaleTypeStore,
  SaleType,
} from 'features/filter';
import { useLocation } from 'react-router-dom';
import { ReactComponent as Reload } from 'assets/icons/reload.svg';
import { Image } from 'shared/ui';
import debounce from 'lodash/debounce';
import { ModalIdx } from 'shared/config';

export const OptCollectionContent = observer(
  ({ content }: { content: FilterCollectionsStore }) => {
    const intl = useIntl();
    const { handleModal, modalContent } = useContext(ModalContext);
    const debouncedSearch = useDebounce(content.input, 700);

    useEffect(() => {
      if (debouncedSearch) {
        content.getCollectionList({
          filter: {
            name: `%${debouncedSearch}%`,
          },
          options: { limit: 100 },
        });
        return;
      }
      content.getCollectionList({
        options: { limit: 100 },
      });
    }, [debouncedSearch]);

    useEffect(() => {
      if (!modalContent) {
        const selectedCollections =
          content.filterStore.filter?.collections ?? [];

        if (selectedCollections.length > 0) {
          content.selectedCollections = new Set(selectedCollections);
        }

        handleModal();
      }
    }, [modalContent, handleModal]);

    return (
      <div>
        <div className="max-w-[360px] min-w-[360px] max-h-[625px] ">
          <div className="title mb-5">
            {intl.formatMessage({
              id: 'text.collections',
              defaultMessage: 'Collections',
            })}
          </div>
          <div className="mb-5">
            <Input
              onChange={content.onChangeInput}
              placeholder="Search by collection, NFT or user"
              after={<SearchIcon className="fill-grey-750 h-5 w-5" />}
            />
          </div>
          <div className="overflow-y-auto max-h-[400px]">
            {content.isLoading && <div className="loader"></div>}
            {content.collections.items.map(
              ({ logo, name, ownersCount, address, nftCount }) => (
                <div
                  className="grid grid-cols-8 justify-start items-center"
                  key={address}
                >
                  <div className="col-span-1 grid items-center checkbox-primary">
                    <Checkbox
                      checked={content.selectedCollections.has(address)}
                      value={address}
                      onChange={content.selectCollection}
                    />
                  </div>
                  <div className="col-span-7 grid grid-flow-col items-center justify-between gap-x-2 border-b border-grey-850 py-4">
                    <div className="grid grid-flow-col items-center justify-start gap-x-3">
                      <Image
                        className="w-8 h-8 rounded-[50%] overflow-hidden"
                        src={logo}
                        alt=""
                      />

                      <div>
                        <div className="text-bold font-medium ">{name}</div>
                      </div>
                    </div>
                    <div>{nftCount}</div>
                  </div>
                </div>
              ),
            )}
          </div>

          <div className="grid grid-cols-2 gap-x-2">
            <button
              disabled={content.selectedCollections.size < 1}
              data-color="blue"
              className="btn secondary"
              onClick={content.clear}
            >
              {intl.formatMessage({
                id: 'button.clear',
                defaultMessage: 'Clear',
              })}
            </button>

            <button
              data-color="blue"
              className="btn primary"
              onClick={content.submit}
            >
              {intl.formatMessage({
                id: 'button.apply',
                defaultMessage: 'Apply',
              })}
            </button>
          </div>
        </div>
      </div>
    );
  },
);

const SelectSaleType = observer(
  ({ filterSaleTypeStore }: { filterSaleTypeStore: FilterSaleTypeStore }) => {
    const intl = useIntl();
    const location = useLocation();

    const debounceRef = useRef<any>();
    debounceRef.current = debounce(filterSaleTypeStore.selectSaleType, 1000);

    const handleSelectType = (event: ChangeEvent<HTMLInputElement>) => {
      const type = event.target.value as SaleType;
      const isChecked = event.target.checked;

      debounceRef.current({ type, isChecked });
    };

    return (
      // in future replace the popover because it's shit
      // p.s. checkboxes doesn't work properly inside there
      <Popover onChange={handleSelectType} className="relative min-w-max">
        {({ open }) => {
          return (
            <>
              <Popover.Button
                data-open={open}
                className="p-2 bg-grey-950 py-2 pl-3 px-10 text-black btn-with-arrow focus:outline-none"
              >
                {intl.formatMessage({
                  id: 'text.sale_type',
                  defaultMessage: 'Sale type',
                })}
              </Popover.Button>

              <Popover.Panel className="absolute z-20 dropdown-list min-w-[240px]">
                <div>
                  {[
                    {
                      label: intl.formatMessage({
                        id: 'saletype.buy_now',
                        defaultMessage: 'Buy now',
                      }),
                      value: 'forsale',
                    },
                    {
                      label: intl.formatMessage({
                        id: 'saletype.live_auction',
                        defaultMessage: 'Live auction',
                      }),
                      value: 'auction',
                    },
                  ].map(({ value, label }) => {
                    return (
                      <div className="dropdown-item filter" key={label}>
                        <input
                          hidden
                          type="checkbox"
                          id={label}
                          className="checkbox sale-type-checkbox"
                          value={value}
                          defaultChecked={Boolean(
                            filterSaleTypeStore.saleType.get(value),
                          )}
                        />
                        <label className="sale-type-label" htmlFor={label}>
                          {label}
                          <img src={CheckIcon} className="w-[14px]" />
                        </label>
                      </div>
                    );
                  })}
                </div>
              </Popover.Panel>
            </>
          );
        }}
      </Popover>
    );
  },
);

export const Filter = observer(
  ({
    filterSaleTypeStore,
    filterCollectionsStore,
    onClearFilter,
  }: {
    filterSaleTypeStore?: FilterSaleTypeStore;
    filterCollectionsStore?: FilterCollectionsStore;
    onClearFilter: () => void;
  }) => {
    const intl = useIntl();
    const { handleModal, modalContent } = useContext(ModalContext);

    return (
      <div className="mb-5">
        <div className="grid grid-flow-col items-center justify-center lg:justify-start gap-2">
          <button
            className="bg-grey-950 p-2 md:pl-3 md:px-10 text-black focus:outline-none"
            onClick={onClearFilter}
          >
            <span className="hidden md:inline">
              {intl.formatMessage({
                id: 'button.reset_filter',
                defaultMessage: 'Reset filter',
              })}
            </span>
            <span className="md:hidden">
              <Reload />
            </span>
          </button>

          {filterSaleTypeStore && (
            <SelectSaleType filterSaleTypeStore={filterSaleTypeStore} />
          )}

          {filterCollectionsStore && (
            <div
              onClick={() =>
                handleModal({
                  id: ModalIdx.FILTER_BY_COLLECTION_MODAL,
                  content: filterCollectionsStore,
                })
              }
              data-open={
                modalContent?.id === ModalIdx.FILTER_BY_COLLECTION_MODAL
              }
              className="p-2 bg-grey-950 py-2 pl-3 px-10 text-black btn-with-arrow focus:outline-none"
            >
              {intl.formatMessage({
                id: 'text.collections',
                defaultMessage: 'Collections',
              })}
            </div>
            // <OptCollectionModal
            //   collectionModalName={collectionModalName}
            //   filterCollectionsStore={filterCollectionsStore}
            // />
          )}
        </div>
      </div>
    );
  },
);
