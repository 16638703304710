import { matchPath } from 'react-router-dom';
import { DirectBuyStatus } from 'api';
import {
  getOffersByOwnerOutUseCase,
  getOffersByOwnerInUseCase,
} from 'application/get-offers';
import { CreateFilterStore } from 'shared/lib/create-filter';
import { ListConstructor } from 'shared/lib/list-contructor';

export const profileOffersOut = new ListConstructor({
  request: getOffersByOwnerOutUseCase,
});
export const profileOffersIn = new ListConstructor({
  request: getOffersByOwnerInUseCase,
});

export const filterOffersIn = new CreateFilterStore({
  request(params) {
    const matchRes = matchPath<'profileAddress', string>(
      '/profile/:profileAddress',
      location.pathname,
    );
    if (!matchRes) {
      return;
    }
    const profileAddress = matchRes.params.profileAddress;

    profileOffersIn.getList(
      {
        ...params,
        owner: profileAddress,
        status: [DirectBuyStatus.Active],
      },
      { limit: 2 },
    );
  },
  filter: {
    verified: false,
  },
});
export const filterOffersOut = new CreateFilterStore({
  request(params) {
    const matchRes = matchPath<'profileAddress', string>(
      '/profile/:profileAddress',
      location.pathname,
    );
    if (!matchRes) {
      return;
    }
    const profileAddress = matchRes.params.profileAddress;

    profileOffersOut.getList(
      {
        ...params,
        owner: profileAddress,
        status: [DirectBuyStatus.Active, DirectBuyStatus.Expired],
      },
      { limit: 2 },
    );
  },
  filter: {
    verified: false,
  },
});
