import { TToken } from 'shared/types';

type PriceRendererProps = {
  price: string;
  token: TToken;
  altPrice?: string;
  disableIcon?: boolean;
};

export const PriceRenderer = ({
  price,
  token,
  altPrice,
  disableIcon,
}: PriceRendererProps) => {
  if (!token) {
    return null;
  }

  return (
    <div className="grid grid-flow-col  items-center justify-start">
      {!disableIcon && token.logoURI && (
        <div className="mr-2">
          <img className="min-w-[16px] w-[16px]" src={token.logoURI} />
        </div>
      )}
      <div className="text-black  mr-1 text-2xl">{price}</div>
      <div className="font-bold  text-2xl">{token.symbol}</div>
    </div>
  );
};

export const PriceRendererCard = ({
  price,
  token,
  altPrice,
  disableIcon = false,
}: PriceRendererProps) => {
  if (!token) {
    return null;
  }

  return (
    <div className="grid grid-flow-col  items-center justify-start">
      {!disableIcon && token.logoURI && (
        <div className="mr-2">
          <img className="min-w-[16px] w-[16px]" src={token.logoURI} />
        </div>
      )}
      <div className="text-black  mr-1 text-sm">{price}</div>
      <div className="font-bold  text-sm">{token.symbol}</div>
    </div>
  );
};
