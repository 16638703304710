import { ChangeEvent, memo } from 'react';
import ArrowIcon from 'assets/icons/arrow-icon.svg';
import { Input } from 'shared/ui';

interface Props {
  page: number;
  total: number;
  onChange: (page: number) => void;
}

export const Pagination = memo((props: Props): JSX.Element => {
  const { page, total, onChange } = props;

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      return;
    }

    const value = parseInt(e.target.value.trim(), 10) || page;

    if (value > 0 && value <= total) {
      onChange(value);
    }
  };
  const handleIncrement = () => onChange(page + 1);
  const handleDecrement = () => onChange(page - 1);

  return (
    <div className="inline-flex items-center">
      <Input
        className="w-14 h-9 py-0"
        type="number"
        value={page}
        onChange={handleInputChange}
      />
      <span className="text-black text-sm ml-1.5 select-none">
        page of {total}
      </span>
      <button
        className="btn secondary w-9 min-h-[36px] py-0 ml-3"
        data-color="blue"
        type="button"
        disabled={page <= 1}
        onClick={handleDecrement}
      >
        <img src={ArrowIcon} alt="back" />
      </button>
      <button
        className="btn secondary w-9 min-h-[36px] py-0 ml-1"
        data-color="blue"
        type="button"
        disabled={page >= total}
        onClick={handleIncrement}
      >
        <img className="rotate-180" src={ArrowIcon} alt="forward" />
      </button>
    </div>
  );
});
