import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { nftStore } from 'modules/nft';
import { bidsStore, BIDS_LIMIT } from '../../model';
import { ActivityBid } from 'shared/ui';
import { useIntl } from 'react-intl';

export const Bids = observer(() => {
  useEffect(() => {
    const auction = nftStore.data?.auction;
    if (auction) {
      bidsStore.getBids({ auction }, { limit: BIDS_LIMIT });
    }
  }, []);

  if (bidsStore.isLoading) {
    return <div className="loader"></div>;
  }

  if (bidsStore.bids.items.length <= 0) {
    return <EmptyBids />;
  }
  return (
    <div>
      {bidsStore.bids.items.map(
        (
          {
            auctionAddress,
            bid,
            createdAt,
            nftAddress,
            from,
            active,
            nftType,
            saleType,
            isCollectionVerified,
            nftName,
          },
          index,
        ) => {
          return (
            <ActivityBid
              key={index}
              active={active}
              bid={bid}
              auctionAddress={auctionAddress}
              createdAt={createdAt}
              from={from}
              nftType={nftType}
              isCollectionVerified={isCollectionVerified}
              saleType={saleType}
              nftAddress={nftAddress}
              nftName={nftName}
            />
          );
        },
      )}
    </div>
  );
});

const EmptyBids = () => {
  const intl = useIntl();
  return (
    <div className="title text-center py-5">
      {intl.formatMessage({
        id: 'nft.empty_bids',
        defaultMessage: 'No bids yet',
      })}
    </div>
  );
};
