import { makeAutoObservable, runInAction } from 'mobx';
import { getTokenListUseCase } from 'application/get-token-list';
import { tokenRepository } from 'shared/repository/token-repository';
import { TToken } from 'shared/types';

class TokenService {
  tokens: TToken[] = [];

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  getToken(tokenAddress: string) {
    if (tokenRepository.tokens.has(tokenAddress)) {
      return tokenRepository.tokens.get(tokenAddress);
    }
  }
  async getTokens() {
    const tokens = await getTokenListUseCase();

    runInAction(() => {
      this.tokens = tokens;
    });
  }
}

export const tokenService = new TokenService();
