import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { wallet } from 'shared/lib/wallet';
import { Price } from 'shared/types';

export const OfferCard = observer(
  ({
    price,
    label,
    myOffers,
  }: {
    price: Price;
    label: string;
    myOffers?: number;
  }) => {
    if (!price) {
      return null;
    }

    const { price: amount, priceToken: token } = price;

    return (
      <div className="p-3 bg-grey-950 grid grid-flow-col  items-center justify-start">
        <div className="mr-2">{label && label}</div>
        <div className="mr-1">
          <img className="w-4 h-4" src={token.logoURI} />
        </div>
        <div className="text-black  mr-1">{amount}</div>
        <div className="font-bold ">{token.symbol}</div>
        {myOffers && myOffers >= 1 ? (
          <div className="mx-2">
            {<Link to={`/profile/${wallet.address}`}>+{myOffers}</Link>}
          </div>
        ) : null}
      </div>
    );
  },
);
