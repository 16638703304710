import {
  CollectionListFilter,
  CollectionListOptions,
  collectionRepository,
} from 'shared/repository';

export async function getCollectionListUseCase({
  filter,
  options,
}: {
  filter?: CollectionListFilter;
  options?: CollectionListOptions;
}) {
  try {
    return collectionRepository.fetchCollections({
      filter,
      options,
    });
  } catch (error) {
    return { items: [], totalCount: 0 };
  }
}
