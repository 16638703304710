import { intervalToDuration } from '../../lib';
import { formatDuration } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { Timer } from 'shared/lib/timer';

const formatDistanceLocale = {
  xSeconds: '{{count}}s',
  xMinutes: '{{count}}m',
  xHours: '{{count}}h',
  xDays: '{{count}}d',
  xWeeks: '{{count}}w',
  xMonths: '{{count}}m',
  xYears: '{{count}}y',
};
const shortEnLocale = {
  formatDistance: (token: string, count: number) =>
    formatDistanceLocale[token].replace('{{count}}', count),
};

export const SellingTimer = observer(
  ({
    timer,
    startTime,
    endTime,
  }: {
    timer: Timer;
    startTime: number;
    endTime: number;
  }) => {
    const int = intervalToDuration(timer.time);
    const { years, months, weeks, days, hours, minutes, seconds } = int;
    const res = years + months + weeks + days + hours + minutes + seconds;

    if (res <= 0) {
      return null;
    }

    const conditionalFormat = [
      // 'years',
      // 'months',
      // 'weeks',
      'days',
      'hours',
      'minutes',
      'seconds',
    ];

    // console.log(int);
    const formattedDuration = formatDuration(int, {
      format: conditionalFormat,
      delimiter: ':',
      locale: shortEnLocale,
    });

    return <div>{formattedDuration}</div>;
  },
);
