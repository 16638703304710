/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * nft indexer api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.1
 * 
 *
 * NOTE: This file is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the file manually.
 */

import * as url from "url";
import * as isomorphicFetch from "isomorphic-fetch";
import { Configuration } from "./configuration";

const BASE_PATH = "https://indexer-api.bf.works".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = isomorphicFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * contract address
 * @export
 */
export type Address = string
/**
 * 
 * @export
 * @interface Auction
 */
export interface Auction {
    /**
     * 
     * @type {Address}
     * @memberof Auction
     */
    address: Address;
    /**
     * 
     * @type {Address}
     * @memberof Auction
     */
    nft: Address;
    /**
     * 
     * @type {Address}
     * @memberof Auction
     */
    bidToken: Address;
    /**
     * 
     * @type {Address}
     * @memberof Auction
     */
    walletForBids?: Address;
    /**
     * 
     * @type {string}
     * @memberof Auction
     */
    startBid?: string;
    /**
     * 
     * @type {string}
     * @memberof Auction
     */
    startUsdBid?: string;
    /**
     * 
     * @type {string}
     * @memberof Auction
     */
    maxBid?: string;
    /**
     * 
     * @type {string}
     * @memberof Auction
     */
    minBid?: string;
    /**
     * 
     * @type {string}
     * @memberof Auction
     */
    maxUsdBid?: string;
    /**
     * 
     * @type {string}
     * @memberof Auction
     */
    minUsdBid?: string;
    /**
     * 
     * @type {Ts}
     * @memberof Auction
     */
    startTime?: Ts;
    /**
     * 
     * @type {Ts}
     * @memberof Auction
     */
    finishTime?: Ts;
    /**
     * 
     * @type {AuctionStatus}
     * @memberof Auction
     */
    status?: AuctionStatus;
    /**
     * 
     * @type {Address}
     * @memberof Auction
     */
    lastBidFrom?: Address;
    /**
     * 
     * @type {Ts}
     * @memberof Auction
     */
    lastBidTime?: Ts;
    /**
     * 
     * @type {string}
     * @memberof Auction
     */
    lastBidValue?: string;
    /**
     * 
     * @type {string}
     * @memberof Auction
     */
    lastBidUsdValue?: string;
}
/**
 * 
 * @export
 * @interface AuctionBid
 */
export interface AuctionBid {
    /**
     * 
     * @type {Address}
     * @memberof AuctionBid
     */
    from: Address;
    /**
     * 
     * @type {Address}
     * @memberof AuctionBid
     */
    auction: Address;
    /**
     * 
     * @type {Address}
     * @memberof AuctionBid
     */
    nft: Address;
    /**
     * 
     * @type {string}
     * @memberof AuctionBid
     */
    price: string;
    /**
     * 
     * @type {string}
     * @memberof AuctionBid
     */
    usdPrice?: string;
    /**
     * 
     * @type {Ts}
     * @memberof AuctionBid
     */
    createdAt: Ts;
    /**
     * 
     * @type {boolean}
     * @memberof AuctionBid
     */
    active?: boolean;
}
/**
 * 
 * @export
 * @interface AuctionBidsBody
 */
export interface AuctionBidsBody {
    /**
     * 
     * @type {Address}
     * @memberof AuctionBidsBody
     */
    auction?: Address;
    /**
     * 
     * @type {number}
     * @memberof AuctionBidsBody
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof AuctionBidsBody
     */
    offset?: number;
}
/**
 * 
 * @export
 * @interface AuctionBody
 */
export interface AuctionBody {
    /**
     * 
     * @type {Address}
     * @memberof AuctionBody
     */
    auction?: Address;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum AuctionStatus {
    Active = <any> 'active',
    Cancelled = <any> 'cancelled',
    Completed = <any> 'completed',
    Expired = <any> 'expired'
}
/**
 * 
 * @export
 * @interface AuctionsBody
 */
export interface AuctionsBody {
    /**
     * 
     * @type {Array<Address>}
     * @memberof AuctionsBody
     */
    owners?: Array<Address>;
    /**
     * 
     * @type {Array<Address>}
     * @memberof AuctionsBody
     */
    collections?: Array<Address>;
    /**
     * 
     * @type {Array<Address>}
     * @memberof AuctionsBody
     */
    tokens?: Array<Address>;
    /**
     * 
     * @type {string}
     * @memberof AuctionsBody
     */
    sort?: AuctionsBody.SortEnum;
    /**
     * 
     * @type {number}
     * @memberof AuctionsBody
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof AuctionsBody
     */
    offset?: number;
}

/**
 * @export
 * @namespace AuctionsBody
 */
export namespace AuctionsBody {
    /**
     * @export
     * @enum {string}
     */
    export enum SortEnum {
        StartDate = <any> 'start-date',
        BidsCount = <any> 'bids-count',
        Average = <any> 'average',
        AverageInHour = <any> 'average-in-hour',
        AverageInDay = <any> 'average-in-day'
    }
}
/**
 * 
 * @export
 * @interface Collection
 */
export interface Collection extends Contract {
    /**
     * 
     * @type {boolean}
     * @memberof Collection
     */
    verified?: boolean;
    /**
     * 
     * @type {Ts}
     * @memberof Collection
     */
    createdAt: Ts;
    /**
     * 
     * @type {string}
     * @memberof Collection
     */
    wallpaper?: string;
    /**
     * 
     * @type {string}
     * @memberof Collection
     */
    logo?: string;
    /**
     * 
     * @type {string}
     * @memberof Collection
     */
    lowestPrice?: string;
    /**
     * 
     * @type {string}
     * @memberof Collection
     */
    totalPrice?: string;
    /**
     * 
     * @type {number}
     * @memberof Collection
     */
    ownersCount: number;
    /**
     * 
     * @type {number}
     * @memberof Collection
     */
    nftCount?: number;
}
/**
 * 
 * @export
 * @interface CollectionDetailsBody
 */
export interface CollectionDetailsBody {
    /**
     * 
     * @type {Address}
     * @memberof CollectionDetailsBody
     */
    collection?: Address;
}
/**
 * 
 * @export
 * @interface CollectionsBody
 */
export interface CollectionsBody {
    /**
     * 
     * @type {string}
     * @memberof CollectionsBody
     */
    name?: string;
    /**
     * 
     * @type {Array<Address>}
     * @memberof CollectionsBody
     */
    owners?: Array<Address>;
    /**
     * 
     * @type {Array<Address>}
     * @memberof CollectionsBody
     */
    collections?: Array<Address>;
    /**
     * 
     * @type {boolean}
     * @memberof CollectionsBody
     */
    verified?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CollectionsBody
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof CollectionsBody
     */
    offset?: number;
}
/**
 * 
 * @export
 * @interface CollectionsByownerBody
 */
export interface CollectionsByownerBody {
    /**
     * 
     * @type {Address}
     * @memberof CollectionsByownerBody
     */
    owner?: Address;
    /**
     * 
     * @type {number}
     * @memberof CollectionsByownerBody
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof CollectionsByownerBody
     */
    offset?: number;
}
/**
 * 
 * @export
 * @interface Contract
 */
export interface Contract {
    /**
     * 
     * @type {Address}
     * @memberof Contract
     */
    address: Address;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    description?: string;
    /**
     * 
     * @type {Address}
     * @memberof Contract
     */
    owner: Address;
    /**
     * 
     * @type {boolean}
     * @memberof Contract
     */
    verified?: boolean;
}
/**
 * 
 * @export
 * @interface DirectBuy
 */
export interface DirectBuy {
    /**
     * 
     * @type {Address}
     * @memberof DirectBuy
     */
    address: Address;
    /**
     * 
     * @type {Address}
     * @memberof DirectBuy
     */
    buyer: Address;
    /**
     * 
     * @type {Address}
     * @memberof DirectBuy
     */
    nft: Address;
    /**
     * 
     * @type {Price}
     * @memberof DirectBuy
     */
    price: Price;
    /**
     * 
     * @type {Ts}
     * @memberof DirectBuy
     */
    createdAt: Ts;
    /**
     * 
     * @type {Ts}
     * @memberof DirectBuy
     */
    expiredAt?: Ts;
    /**
     * 
     * @type {Ts}
     * @memberof DirectBuy
     */
    finishedAt?: Ts;
    /**
     * 
     * @type {DirectBuyStatus}
     * @memberof DirectBuy
     */
    status?: DirectBuyStatus;
}
/**
 * 
 * @export
 * @interface DirectBuyBody
 */
export interface DirectBuyBody {
    /**
     * 
     * @type {Address}
     * @memberof DirectBuyBody
     */
    nft?: Address;
    /**
     * 
     * @type {Array<DirectBuyStatus>}
     * @memberof DirectBuyBody
     */
    status?: Array<DirectBuyStatus>;
    /**
     * 
     * @type {number}
     * @memberof DirectBuyBody
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof DirectBuyBody
     */
    offset?: number;
}
/**
 * 
 * @export
 * @interface DirectBuyBody1
 */
export interface DirectBuyBody1 {
    /**
     * 
     * @type {Address}
     * @memberof DirectBuyBody1
     */
    owner?: Address;
    /**
     * 
     * @type {Array<Address>}
     * @memberof DirectBuyBody1
     */
    collections?: Array<Address>;
    /**
     * 
     * @type {Array<DirectBuyStatus>}
     * @memberof DirectBuyBody1
     */
    status?: Array<DirectBuyStatus>;
    /**
     * 
     * @type {number}
     * @memberof DirectBuyBody1
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof DirectBuyBody1
     */
    offset?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum DirectBuyStatus {
    Create = <any> 'create',
    AwaitTokens = <any> 'await_tokens',
    Active = <any> 'active',
    Filled = <any> 'filled',
    Cancelled = <any> 'cancelled',
    Expired = <any> 'expired'
}
/**
 * 
 * @export
 * @interface DirectBuyinBody
 */
export interface DirectBuyinBody {
    /**
     * 
     * @type {Address}
     * @memberof DirectBuyinBody
     */
    owner?: Address;
    /**
     * 
     * @type {Array<Address>}
     * @memberof DirectBuyinBody
     */
    collections?: Array<Address>;
    /**
     * 
     * @type {Array<DirectBuyStatus>}
     * @memberof DirectBuyinBody
     */
    status?: Array<DirectBuyStatus>;
    /**
     * 
     * @type {number}
     * @memberof DirectBuyinBody
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof DirectBuyinBody
     */
    offset?: number;
}
/**
 * 
 * @export
 * @interface DirectSell
 */
export interface DirectSell {
    /**
     * 
     * @type {Address}
     * @memberof DirectSell
     */
    address: Address;
    /**
     * 
     * @type {Address}
     * @memberof DirectSell
     */
    buyer?: Address;
    /**
     * 
     * @type {Address}
     * @memberof DirectSell
     */
    seller: Address;
    /**
     * 
     * @type {Address}
     * @memberof DirectSell
     */
    nft: Address;
    /**
     * 
     * @type {Price}
     * @memberof DirectSell
     */
    price: Price;
    /**
     * 
     * @type {Ts}
     * @memberof DirectSell
     */
    createdAt: Ts;
    /**
     * 
     * @type {Ts}
     * @memberof DirectSell
     */
    finishedAt?: Ts;
    /**
     * 
     * @type {Ts}
     * @memberof DirectSell
     */
    expiredAt?: Ts;
    /**
     * 
     * @type {DirectSellStatus}
     * @memberof DirectSell
     */
    status?: DirectSellStatus;
}
/**
 * 
 * @export
 * @interface DirectSellBody
 */
export interface DirectSellBody {
    /**
     * 
     * @type {Address}
     * @memberof DirectSellBody
     */
    owner?: Address;
    /**
     * 
     * @type {Array<Address>}
     * @memberof DirectSellBody
     */
    collections?: Array<Address>;
    /**
     * 
     * @type {Array<DirectBuyStatus>}
     * @memberof DirectSellBody
     */
    status?: Array<DirectBuyStatus>;
    /**
     * 
     * @type {number}
     * @memberof DirectSellBody
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof DirectSellBody
     */
    offset?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum DirectSellStatus {
    Create = <any> 'create',
    AwaitNft = <any> 'await_nft',
    Active = <any> 'active',
    Cancelled = <any> 'cancelled',
    Expired = <any> 'expired'
}
/**
 * 
 * @export
 * @interface Event
 */
export interface Event {
    /**
     * 
     * @type {number}
     * @memberof Event
     */
    id: number;
    /**
     * 
     * @type {EventType}
     * @memberof Event
     */
    type: EventType;
    /**
     * 
     * @type {EventCategory}
     * @memberof Event
     */
    cat: EventCategory;
    /**
     * 
     * @type {Address}
     * @memberof Event
     */
    address: Address;
    /**
     * 
     * @type {Ts}
     * @memberof Event
     */
    ts: Ts;
    /**
     * 
     * @type {any}
     * @memberof Event
     */
    args?: any;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum EventCategory {
    Auction = <any> 'auction',
    DirectBuy = <any> 'direct_buy',
    DirectSell = <any> 'direct_sell'
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum EventType {
    AuctionDeployed = <any> 'auction_deployed',
    AuctionCreated = <any> 'auction_created',
    AuctionRootOwnershipTransferred = <any> 'auction_root_ownership_transferred',
    AuctionActive = <any> 'auction_active',
    AuctionDeclined = <any> 'auction_declined',
    AuctionBidPlaced = <any> 'auction_bid_placed',
    AuctionBidDeclined = <any> 'auction_bid_declined',
    AuctionCancelled = <any> 'auction_cancelled',
    AuctionComplete = <any> 'auction_complete',
    DirectBuyDeployed = <any> 'direct_buy_deployed',
    DirectBuyDeclined = <any> 'direct_buy_declined',
    FactoryDirectBuyOwnershipTransferred = <any> 'factory_direct_buy_ownership_transferred',
    DirectBuyStateChanged = <any> 'direct_buy_state_changed',
    DirectSellDeployed = <any> 'direct_sell_deployed',
    DirectSellDeclined = <any> 'direct_sell_declined',
    FactoryDirectSellOwnershipTransferred = <any> 'factory_direct_sell_ownership_transferred',
    DirectSellStateChanged = <any> 'direct_sell_state_changed',
    NftOwnerChanged = <any> 'nft_owner_changed',
    NftManagerChanged = <any> 'nft_manager_changed',
    CollectionOwnershipTransferred = <any> 'collection_ownership_transferred',
    NftCreated = <any> 'nft_created',
    NftBurned = <any> 'nft_burned'
}
/**
 * 
 * @export
 * @interface EventsBody
 */
export interface EventsBody {
    /**
     * 
     * @type {Address}
     * @memberof EventsBody
     */
    nft?: Address;
    /**
     * 
     * @type {Address}
     * @memberof EventsBody
     */
    collection?: Address;
    /**
     * 
     * @type {Address}
     * @memberof EventsBody
     */
    owner?: Address;
    /**
     * 
     * @type {Array<EventType>}
     * @memberof EventsBody
     */
    type?: Array<EventType>;
    /**
     * 
     * @type {number}
     * @memberof EventsBody
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof EventsBody
     */
    offset?: number;
}
/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse200
     */
    count?: number;
    /**
     * 
     * @type {Array<InlineResponse200Items>}
     * @memberof InlineResponse200
     */
    items?: Array<InlineResponse200Items>;
}
/**
 * 
 * @export
 * @interface InlineResponse2001
 */
export interface InlineResponse2001 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2001
     */
    totalCount: number;
    /**
     * 
     * @type {Array<Collection>}
     * @memberof InlineResponse2001
     */
    items: Array<Collection>;
}
/**
 * 
 * @export
 * @interface InlineResponse20010
 */
export interface InlineResponse20010 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20010
     */
    totalCount: number;
    /**
     * 
     * @type {Array<DirectSell>}
     * @memberof InlineResponse20010
     */
    items: Array<DirectSell>;
    /**
     * 
     * @type {{ [key: string]: Nft; }}
     * @memberof InlineResponse20010
     */
    nft?: { [key: string]: Nft; };
    /**
     * 
     * @type {{ [key: string]: Collection; }}
     * @memberof InlineResponse20010
     */
    collection?: { [key: string]: Collection; };
}
/**
 * 
 * @export
 * @interface InlineResponse20011
 */
export interface InlineResponse20011 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20011
     */
    totalCount: number;
    /**
     * 
     * @type {Array<Event>}
     * @memberof InlineResponse20011
     */
    items: Array<Event>;
}
/**
 * 
 * @export
 * @interface InlineResponse2002
 */
export interface InlineResponse2002 {
    /**
     * 
     * @type {Array<DirectBuy>}
     * @memberof InlineResponse2002
     */
    items?: Array<DirectBuy>;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2002
     */
    totalCount?: number;
    /**
     * 
     * @type {{ [key: string]: Nft; }}
     * @memberof InlineResponse2002
     */
    nft?: { [key: string]: Nft; };
    /**
     * 
     * @type {{ [key: string]: Collection; }}
     * @memberof InlineResponse2002
     */
    collection?: { [key: string]: Collection; };
}
/**
 * 
 * @export
 * @interface InlineResponse2003
 */
export interface InlineResponse2003 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2003
     */
    totalCount: number;
    /**
     * 
     * @type {Array<NftPrice>}
     * @memberof InlineResponse2003
     */
    items: Array<NftPrice>;
}
/**
 * 
 * @export
 * @interface InlineResponse2004
 */
export interface InlineResponse2004 {
    /**
     * 
     * @type {Nft}
     * @memberof InlineResponse2004
     */
    nft?: Nft;
    /**
     * 
     * @type {{ [key: string]: Auction; }}
     * @memberof InlineResponse2004
     */
    auction?: { [key: string]: Auction; };
    /**
     * 
     * @type {{ [key: string]: DirectSell; }}
     * @memberof InlineResponse2004
     */
    directSell?: { [key: string]: DirectSell; };
    /**
     * 
     * @type {{ [key: string]: DirectBuy; }}
     * @memberof InlineResponse2004
     */
    directBuy?: { [key: string]: DirectBuy; };
    /**
     * 
     * @type {{ [key: string]: Collection; }}
     * @memberof InlineResponse2004
     */
    collection?: { [key: string]: Collection; };
}
/**
 * 
 * @export
 * @interface InlineResponse2005
 */
export interface InlineResponse2005 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2005
     */
    totalCount: number;
    /**
     * 
     * @type {Array<Nft>}
     * @memberof InlineResponse2005
     */
    items: Array<Nft>;
    /**
     * 
     * @type {{ [key: string]: Collection; }}
     * @memberof InlineResponse2005
     */
    collection?: { [key: string]: Collection; };
    /**
     * 
     * @type {{ [key: string]: Auction; }}
     * @memberof InlineResponse2005
     */
    auction?: { [key: string]: Auction; };
    /**
     * 
     * @type {{ [key: string]: DirectSell; }}
     * @memberof InlineResponse2005
     */
    directSell?: { [key: string]: DirectSell; };
    /**
     * 
     * @type {{ [key: string]: DirectBuy; }}
     * @memberof InlineResponse2005
     */
    directBuy?: { [key: string]: DirectBuy; };
}
/**
 * 
 * @export
 * @interface InlineResponse2006
 */
export interface InlineResponse2006 {
    /**
     * 
     * @type {Auction}
     * @memberof InlineResponse2006
     */
    auction: Auction;
    /**
     * 
     * @type {AuctionBid}
     * @memberof InlineResponse2006
     */
    bid?: AuctionBid;
    /**
     * 
     * @type {{ [key: string]: Nft; }}
     * @memberof InlineResponse2006
     */
    nft: { [key: string]: Nft; };
    /**
     * 
     * @type {{ [key: string]: Collection; }}
     * @memberof InlineResponse2006
     */
    collection?: { [key: string]: Collection; };
}
/**
 * 
 * @export
 * @interface InlineResponse2007
 */
export interface InlineResponse2007 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2007
     */
    totalCount: number;
    /**
     * 
     * @type {Array<Auction>}
     * @memberof InlineResponse2007
     */
    items: Array<Auction>;
    /**
     * 
     * @type {{ [key: string]: Nft; }}
     * @memberof InlineResponse2007
     */
    nft?: { [key: string]: Nft; };
    /**
     * 
     * @type {{ [key: string]: Collection; }}
     * @memberof InlineResponse2007
     */
    collection?: { [key: string]: Collection; };
}
/**
 * 
 * @export
 * @interface InlineResponse2008
 */
export interface InlineResponse2008 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2008
     */
    totalCount: number;
    /**
     * 
     * @type {Array<AuctionBid>}
     * @memberof InlineResponse2008
     */
    items: Array<AuctionBid>;
    /**
     * 
     * @type {{ [key: string]: Nft; }}
     * @memberof InlineResponse2008
     */
    nft?: { [key: string]: Nft; };
    /**
     * 
     * @type {{ [key: string]: Collection; }}
     * @memberof InlineResponse2008
     */
    collection?: { [key: string]: Collection; };
    /**
     * 
     * @type {{ [key: string]: Auction; }}
     * @memberof InlineResponse2008
     */
    auction?: { [key: string]: Auction; };
}
/**
 * 
 * @export
 * @interface InlineResponse2009
 */
export interface InlineResponse2009 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2009
     */
    totalCount: number;
    /**
     * 
     * @type {Array<DirectBuy>}
     * @memberof InlineResponse2009
     */
    items: Array<DirectBuy>;
    /**
     * 
     * @type {{ [key: string]: Nft; }}
     * @memberof InlineResponse2009
     */
    nft?: { [key: string]: Nft; };
    /**
     * 
     * @type {{ [key: string]: Collection; }}
     * @memberof InlineResponse2009
     */
    collection?: { [key: string]: Collection; };
}
/**
 * 
 * @export
 * @interface InlineResponse200Items
 */
export interface InlineResponse200Items {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200Items
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200Items
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200Items
     */
    contractType: InlineResponse200Items.ContractTypeEnum;
}

/**
 * @export
 * @namespace InlineResponse200Items
 */
export namespace InlineResponse200Items {
    /**
     * @export
     * @enum {string}
     */
    export enum ContractTypeEnum {
        Nft = <any> 'nft',
        Collection = <any> 'collection',
        Auction = <any> 'auction',
        DirectBuy = <any> 'directBuy',
        DirectSell = <any> 'directSell'
    }
}
/**
 * 
 * @export
 * @interface Nft
 */
export interface Nft extends Contract {
    /**
     * 
     * @type {string}
     * @memberof Nft
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof Nft
     */
    image: string;
    /**
     * 
     * @type {string}
     * @memberof Nft
     */
    mimetype?: string;
    /**
     * 
     * @type {Address}
     * @memberof Nft
     */
    collection: Address;
    /**
     * 
     * @type {Array<any>}
     * @memberof Nft
     */
    attributes: Array<any>;
    /**
     * 
     * @type {Price}
     * @memberof Nft
     */
    currentPrice?: Price;
    /**
     * 
     * @type {Price}
     * @memberof Nft
     */
    lastPrice?: Price;
    /**
     * 
     * @type {Address}
     * @memberof Nft
     */
    auction?: Address;
    /**
     * 
     * @type {Address}
     * @memberof Nft
     */
    forsale?: Address;
    /**
     * 
     * @type {Address}
     * @memberof Nft
     */
    bestOffer?: Address;
    /**
     * 
     * @type {Address}
     * @memberof Nft
     */
    manager?: Address;
}
/**
 * 
 * @export
 * @interface NftDetailsBody
 */
export interface NftDetailsBody {
    /**
     * 
     * @type {Address}
     * @memberof NftDetailsBody
     */
    nft?: Address;
}
/**
 * 
 * @export
 * @interface NftPrice
 */
export interface NftPrice {
    /**
     * 
     * @type {string}
     * @memberof NftPrice
     */
    price: string;
    /**
     * 
     * @type {Address}
     * @memberof NftPrice
     */
    priceToken?: Address;
    /**
     * 
     * @type {Ts}
     * @memberof NftPrice
     */
    ts: Ts;
}
/**
 * 
 * @export
 * @interface NftPricehistoryBody
 */
export interface NftPricehistoryBody {
    /**
     * 
     * @type {Address}
     * @memberof NftPricehistoryBody
     */
    nft?: Address;
    /**
     * periods by days/hours
     * @type {string}
     * @memberof NftPricehistoryBody
     */
    scale?: NftPricehistoryBody.ScaleEnum;
    /**
     * 
     * @type {number}
     * @memberof NftPricehistoryBody
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof NftPricehistoryBody
     */
    offset?: number;
}

/**
 * @export
 * @namespace NftPricehistoryBody
 */
export namespace NftPricehistoryBody {
    /**
     * @export
     * @enum {string}
     */
    export enum ScaleEnum {
        D = <any> 'd',
        H = <any> 'h'
    }
}
/**
 * 
 * @export
 * @interface NftReloadmetaBody
 */
export interface NftReloadmetaBody {
    /**
     * 
     * @type {Address}
     * @memberof NftReloadmetaBody
     */
    nft?: Address;
}
/**
 * 
 * @export
 * @interface NftsBody
 */
export interface NftsBody {
    /**
     * 
     * @type {Array<Address>}
     * @memberof NftsBody
     */
    owners?: Array<Address>;
    /**
     * 
     * @type {Array<Address>}
     * @memberof NftsBody
     */
    collections?: Array<Address>;
    /**
     * 
     * @type {Address}
     * @memberof NftsBody
     */
    priceToken?: Address;
    /**
     * 
     * @type {number}
     * @memberof NftsBody
     */
    priceFrom?: number;
    /**
     * 
     * @type {number}
     * @memberof NftsBody
     */
    priceTo?: number;
    /**
     * 
     * @type {boolean}
     * @memberof NftsBody
     */
    verified?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NftsBody
     */
    forsale?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NftsBody
     */
    auction?: boolean;
    /**
     * 
     * @type {number}
     * @memberof NftsBody
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof NftsBody
     */
    offset?: number;
}
/**
 * 
 * @export
 * @interface OwnerBidsinBody
 */
export interface OwnerBidsinBody {
    /**
     * 
     * @type {Address}
     * @memberof OwnerBidsinBody
     */
    owner?: Address;
    /**
     * 
     * @type {Array<Address>}
     * @memberof OwnerBidsinBody
     */
    collections?: Array<Address>;
    /**
     * 
     * @type {boolean}
     * @memberof OwnerBidsinBody
     */
    active?: boolean;
    /**
     * 
     * @type {number}
     * @memberof OwnerBidsinBody
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof OwnerBidsinBody
     */
    offset?: number;
}
/**
 * 
 * @export
 * @interface OwnerBidsoutBody
 */
export interface OwnerBidsoutBody {
    /**
     * 
     * @type {Address}
     * @memberof OwnerBidsoutBody
     */
    owner?: Address;
    /**
     * 
     * @type {Array<Address>}
     * @memberof OwnerBidsoutBody
     */
    collections?: Array<Address>;
    /**
     * 
     * @type {boolean}
     * @memberof OwnerBidsoutBody
     */
    lastbid?: boolean;
    /**
     * 
     * @type {number}
     * @memberof OwnerBidsoutBody
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof OwnerBidsoutBody
     */
    offset?: number;
}
/**
 * 
 * @export
 * @interface Price
 */
export interface Price {
    /**
     * 
     * @type {Address}
     * @memberof Price
     */
    priceToken: Address;
    /**
     * 
     * @type {string}
     * @memberof Price
     */
    price: string;
    /**
     * 
     * @type {string}
     * @memberof Price
     */
    usdPrice?: string;
}
/**
 * 
 * @export
 * @interface Profile
 */
export interface Profile {
    /**
     * 
     * @type {Address}
     * @memberof Profile
     */
    address: Address;
    /**
     * 
     * @type {Ts}
     * @memberof Profile
     */
    created: Ts;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    bio?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    site?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    twitter?: string;
}
/**
 * unix timestamp
 * @export
 */
export type Ts = number
/**
 * DefaultApi - fetch parameter creator
 * @export
 */
export const DefaultApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CollectionDetailsBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionDetailsPost(body: CollectionDetailsBody, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling collectionDetailsPost.');
            }
            const localVarPath = `/collection/details`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CollectionDetailsBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CollectionsByownerBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionsByOwnerPost(body: CollectionsByownerBody, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling collectionsByOwnerPost.');
            }
            const localVarPath = `/collections/by-owner`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CollectionsByownerBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AuctionBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuction(body: AuctionBody, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getAuction.');
            }
            const localVarPath = `/auction`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AuctionBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DirectBuyBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNftDirectBuy(body: DirectBuyBody, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getNftDirectBuy.');
            }
            const localVarPath = `/nft/direct/buy`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DirectBuyBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NftPricehistoryBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNftPriceHistory(body: NftPricehistoryBody, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getNftPriceHistory.');
            }
            const localVarPath = `/nft/price-history`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"NftPricehistoryBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AuctionBidsBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAuctionBids(body: AuctionBidsBody, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling listAuctionBids.');
            }
            const localVarPath = `/auction/bids`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AuctionBidsBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AuctionsBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAuctions(body: AuctionsBody, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling listAuctions.');
            }
            const localVarPath = `/auctions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AuctionsBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CollectionsBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCollections(body?: CollectionsBody, options: any = {}): FetchArgs {
            const localVarPath = `/collections`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CollectionsBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EventsBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEvents(body: EventsBody, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling listEvents.');
            }
            const localVarPath = `/events`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"EventsBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NftsBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNFT(body: NftsBody, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling listNFT.');
            }
            const localVarPath = `/nfts/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"NftsBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NftDetailsBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nftDetailsPost(body: NftDetailsBody, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling nftDetailsPost.');
            }
            const localVarPath = `/nft/details`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"NftDetailsBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NftReloadmetaBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nftReloadMetaPost(body: NftReloadmetaBody, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling nftReloadMetaPost.');
            }
            const localVarPath = `/nft/reload-meta`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"NftReloadmetaBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OwnerBidsinBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ownerBidsInPost(body: OwnerBidsinBody, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling ownerBidsInPost.');
            }
            const localVarPath = `/owner/bids-in`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"OwnerBidsinBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OwnerBidsoutBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ownerBidsOutPost(body: OwnerBidsoutBody, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling ownerBidsOutPost.');
            }
            const localVarPath = `/owner/bids-out`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"OwnerBidsoutBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DirectBuyinBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ownerDirectBuyInPost(body: DirectBuyinBody, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling ownerDirectBuyInPost.');
            }
            const localVarPath = `/owner/direct/buy-in`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DirectBuyinBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DirectBuyBody1} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ownerDirectBuyPost(body: DirectBuyBody1, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling ownerDirectBuyPost.');
            }
            const localVarPath = `/owner/direct/buy`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DirectBuyBody1" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DirectSellBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ownerDirectSellPost(body: DirectSellBody, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling ownerDirectSellPost.');
            }
            const localVarPath = `/owner/direct/sell`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DirectSellBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchEverything(body: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling searchEverything.');
            }
            const localVarPath = `/search`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'text/plain';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"string" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CollectionDetailsBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionDetailsPost(body: CollectionDetailsBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Collection> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).collectionDetailsPost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {CollectionsByownerBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionsByOwnerPost(body: CollectionsByownerBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2001> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).collectionsByOwnerPost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {AuctionBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuction(body: AuctionBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2006> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getAuction(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {DirectBuyBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNftDirectBuy(body: DirectBuyBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2002> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getNftDirectBuy(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {NftPricehistoryBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNftPriceHistory(body: NftPricehistoryBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2003> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getNftPriceHistory(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {AuctionBidsBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAuctionBids(body: AuctionBidsBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2008> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).listAuctionBids(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {AuctionsBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAuctions(body: AuctionsBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2007> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).listAuctions(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {CollectionsBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCollections(body?: CollectionsBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2001> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).listCollections(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {EventsBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEvents(body: EventsBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20011> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).listEvents(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {NftsBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNFT(body: NftsBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2005> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).listNFT(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {NftDetailsBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nftDetailsPost(body: NftDetailsBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2004> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).nftDetailsPost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {NftReloadmetaBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nftReloadMetaPost(body: NftReloadmetaBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Nft> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).nftReloadMetaPost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {OwnerBidsinBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ownerBidsInPost(body: OwnerBidsinBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2008> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).ownerBidsInPost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {OwnerBidsoutBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ownerBidsOutPost(body: OwnerBidsoutBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2008> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).ownerBidsOutPost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {DirectBuyinBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ownerDirectBuyInPost(body: DirectBuyinBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2009> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).ownerDirectBuyInPost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {DirectBuyBody1} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ownerDirectBuyPost(body: DirectBuyBody1, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2009> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).ownerDirectBuyPost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {DirectSellBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ownerDirectSellPost(body: DirectSellBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20010> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).ownerDirectSellPost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchEverything(body: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse200> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).searchEverything(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {CollectionDetailsBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionDetailsPost(body: CollectionDetailsBody, options?: any) {
            return DefaultApiFp(configuration).collectionDetailsPost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {CollectionsByownerBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionsByOwnerPost(body: CollectionsByownerBody, options?: any) {
            return DefaultApiFp(configuration).collectionsByOwnerPost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {AuctionBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuction(body: AuctionBody, options?: any) {
            return DefaultApiFp(configuration).getAuction(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {DirectBuyBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNftDirectBuy(body: DirectBuyBody, options?: any) {
            return DefaultApiFp(configuration).getNftDirectBuy(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {NftPricehistoryBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNftPriceHistory(body: NftPricehistoryBody, options?: any) {
            return DefaultApiFp(configuration).getNftPriceHistory(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {AuctionBidsBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAuctionBids(body: AuctionBidsBody, options?: any) {
            return DefaultApiFp(configuration).listAuctionBids(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {AuctionsBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAuctions(body: AuctionsBody, options?: any) {
            return DefaultApiFp(configuration).listAuctions(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {CollectionsBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCollections(body?: CollectionsBody, options?: any) {
            return DefaultApiFp(configuration).listCollections(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {EventsBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEvents(body: EventsBody, options?: any) {
            return DefaultApiFp(configuration).listEvents(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {NftsBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNFT(body: NftsBody, options?: any) {
            return DefaultApiFp(configuration).listNFT(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {NftDetailsBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nftDetailsPost(body: NftDetailsBody, options?: any) {
            return DefaultApiFp(configuration).nftDetailsPost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {NftReloadmetaBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nftReloadMetaPost(body: NftReloadmetaBody, options?: any) {
            return DefaultApiFp(configuration).nftReloadMetaPost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {OwnerBidsinBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ownerBidsInPost(body: OwnerBidsinBody, options?: any) {
            return DefaultApiFp(configuration).ownerBidsInPost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {OwnerBidsoutBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ownerBidsOutPost(body: OwnerBidsoutBody, options?: any) {
            return DefaultApiFp(configuration).ownerBidsOutPost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {DirectBuyinBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ownerDirectBuyInPost(body: DirectBuyinBody, options?: any) {
            return DefaultApiFp(configuration).ownerDirectBuyInPost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {DirectBuyBody1} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ownerDirectBuyPost(body: DirectBuyBody1, options?: any) {
            return DefaultApiFp(configuration).ownerDirectBuyPost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {DirectSellBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ownerDirectSellPost(body: DirectSellBody, options?: any) {
            return DefaultApiFp(configuration).ownerDirectSellPost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchEverything(body: string, options?: any) {
            return DefaultApiFp(configuration).searchEverything(body, options)(fetch, basePath);
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {CollectionDetailsBody} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public collectionDetailsPost(body: CollectionDetailsBody, options?: any) {
        return DefaultApiFp(this.configuration).collectionDetailsPost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {CollectionsByownerBody} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public collectionsByOwnerPost(body: CollectionsByownerBody, options?: any) {
        return DefaultApiFp(this.configuration).collectionsByOwnerPost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {AuctionBody} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAuction(body: AuctionBody, options?: any) {
        return DefaultApiFp(this.configuration).getAuction(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {DirectBuyBody} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getNftDirectBuy(body: DirectBuyBody, options?: any) {
        return DefaultApiFp(this.configuration).getNftDirectBuy(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {NftPricehistoryBody} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getNftPriceHistory(body: NftPricehistoryBody, options?: any) {
        return DefaultApiFp(this.configuration).getNftPriceHistory(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {AuctionBidsBody} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listAuctionBids(body: AuctionBidsBody, options?: any) {
        return DefaultApiFp(this.configuration).listAuctionBids(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {AuctionsBody} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listAuctions(body: AuctionsBody, options?: any) {
        return DefaultApiFp(this.configuration).listAuctions(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {CollectionsBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listCollections(body?: CollectionsBody, options?: any) {
        return DefaultApiFp(this.configuration).listCollections(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {EventsBody} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listEvents(body: EventsBody, options?: any) {
        return DefaultApiFp(this.configuration).listEvents(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {NftsBody} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listNFT(body: NftsBody, options?: any) {
        return DefaultApiFp(this.configuration).listNFT(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {NftDetailsBody} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public nftDetailsPost(body: NftDetailsBody, options?: any) {
        return DefaultApiFp(this.configuration).nftDetailsPost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {NftReloadmetaBody} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public nftReloadMetaPost(body: NftReloadmetaBody, options?: any) {
        return DefaultApiFp(this.configuration).nftReloadMetaPost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {OwnerBidsinBody} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public ownerBidsInPost(body: OwnerBidsinBody, options?: any) {
        return DefaultApiFp(this.configuration).ownerBidsInPost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {OwnerBidsoutBody} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public ownerBidsOutPost(body: OwnerBidsoutBody, options?: any) {
        return DefaultApiFp(this.configuration).ownerBidsOutPost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {DirectBuyinBody} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public ownerDirectBuyInPost(body: DirectBuyinBody, options?: any) {
        return DefaultApiFp(this.configuration).ownerDirectBuyInPost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {DirectBuyBody1} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public ownerDirectBuyPost(body: DirectBuyBody1, options?: any) {
        return DefaultApiFp(this.configuration).ownerDirectBuyPost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {DirectSellBody} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public ownerDirectSellPost(body: DirectSellBody, options?: any) {
        return DefaultApiFp(this.configuration).ownerDirectSellPost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public searchEverything(body: string, options?: any) {
        return DefaultApiFp(this.configuration).searchEverything(body, options)(this.fetch, this.basePath);
    }

}
