import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { exploreNftList, filterNfts } from 'modules/explorer';
import { NftCard } from 'modules/nft';
import { Filter } from 'shared/ui/organisms/filter';

const ITEMS_PER_PAGE = 28;

export const ExploreNftList = observer(() => {
  const intl = useIntl();

  useEffect(() => {
    exploreNftList.isEmpty &&
      exploreNftList.getList({}, { limit: ITEMS_PER_PAGE });

    return () => {
      filterNfts.clear();
      exploreNftList.reset();
    };
  }, []);

  const {
    data: { items, totalCount },
  } = exploreNftList;

  const handleLoadMore = () => {
    exploreNftList.getList(filterNfts?.filter ?? {}, {
      limit: ITEMS_PER_PAGE,
      offset: items.length,
    });
  };

  return (
    <>
      <Filter
        onClearFilter={() => filterNfts.clear({ refetch: true })}
        filterSaleTypeStore={filterNfts.filterSaleTypeStore}
        filterCollectionsStore={filterNfts.filterCollectionsStore}
      />
      {exploreNftList.isLoading && <div className="loader"></div>}
      <div className="responsive-grid mb-5 ">
        {items.map((nft, index) => (
          <NftCard nft={nft} key={index} />
        ))}
      </div>
      {items.length < totalCount && (
        <button
          className={`btn  explore-link ${
            exploreNftList.isLoading && 'loading'
          }`}
          disabled={exploreNftList.isLoading}
          onClick={handleLoadMore}
        >
          {intl.formatMessage({
            id: 'button.load_more_nfts',
            defaultMessage: 'Load more NFTs',
          })}
        </button>
      )}
    </>
  );
});
