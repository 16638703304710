import { notificationStore } from 'shared/ui';
import { auctionRepository } from 'shared/repository';
import { notificationMapper } from 'mappers';
import { tokenService } from 'services/token-service';

export async function finishAuctionUseCase({
  auctionAddress,
  sendGasTo,
}: {
  sendGasTo: string;
  auctionAddress: string;
}) {
  try {
    const { notificationData } = await auctionRepository.finishAuction({
      sendGasTo,
      auctionAddress,
    });

    if (notificationData.event === 'auctionComplete') {
      const { nft, amount, paymentToken, userAddress } =
        notificationData.response;
      const token = await tokenService.getToken(paymentToken.toString());

      const data = notificationMapper({
        amount,
        nftAddress: nft.toString(),
        token,
        userAddress: userAddress.toString(),
      });
      notificationStore.enqueueNotify({
        type: 'AuctionComplete',
        amount: data?.amount ?? '',
        nftAddress: data?.nftAddress ?? '',
        token: data?.token ?? '',
        userAddress: data?.userAddress ?? '',
      });
      return;
    }

    const { nft, amount, paymentToken, userAddress } =
      notificationData.response;
    const token = await tokenService.getToken(paymentToken.toString());
    const data = notificationMapper({
      amount,
      nftAddress: nft.toString(),
      token,
      userAddress: userAddress.toString(),
    });
    notificationStore.enqueueNotify({
      type: 'AuctionCancelled',
      amount: data?.amount ?? '',
      nftAddress: data?.nftAddress ?? '',
      token: data?.token ?? '',
      userAddress: data?.userAddress ?? '',
    });
  } catch (error) {
    console.error(error);
  }
}
