import { useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { OfferBox } from 'shared/ui';
import { offersStore } from '../../model';
import { DirectBuyStatus } from 'api';

export const Offers = observer(() => {
  const params = useParams<'nftAddress'>();
  useEffect(() => {
    if (params.nftAddress) {
      offersStore.getOffers(
        { nft: params.nftAddress, status: [DirectBuyStatus.Active] },
        { limit: 20 },
      );
    }
  }, []);

  if (offersStore.isLoading) {
    return <div className="loader"></div>;
  }

  if (offersStore.offers.items.length <= 0) {
    return <EmptyOffers />;
  }

  return (
    <div>
      {offersStore.offers.items.map(
        (
          {
            buyer,
            createdAt,
            nftAddress,
            price,
            expiredAt,
            finishedAt,
            offerAddress,
            nftOwner,
            status,
            saleType,
            collectionName,
            isCollectionVerified,
            nftImage,
            nftName,
            nftType,
          },
          index,
        ) => {
          return (
            <OfferBox
              direction="in"
              withLabel
              key={index}
              offerAddress={offerAddress}
              buyer={buyer}
              createdAt={createdAt}
              nftAddress={nftAddress}
              price={price}
              expiredAt={expiredAt}
              finishedAt={finishedAt}
              onAccept={() => offersStore.acceptOffer(offerAddress, nftAddress)}
              onCancel={() => offersStore.cancelOffer(offerAddress, nftAddress)}
              nftOwner={nftOwner}
              isLoading={Boolean(offersStore.offersLoading.get(offerAddress))}
              status={status}
              saleType={saleType}
              collectionName={collectionName}
              isCollectionVerified={isCollectionVerified}
              nftImage={nftImage}
              nftName={nftName}
              nftType={nftType}
            />
          );
        },
      )}
    </div>
  );
});

const EmptyOffers = () => {
  const intl = useIntl();
  return (
    <div className="title text-center py-5">
      {intl.formatMessage({
        id: 'nft.empty_offers',
        defaultMessage: 'No offers yet',
      })}
    </div>
  );
};
