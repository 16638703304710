import { notificationStore } from 'shared/ui';
import { auctionRepository, AuctionBuilderParams } from 'shared/repository';
import { tokenService } from 'services/token-service';

import { notificationMapper } from 'mappers';

export async function createAuctionUseCase(params: AuctionBuilderParams) {
  try {
    const { notificationData } = await auctionRepository.createAuction(params);

    if (notificationData.event === 'auctionTip3DeployedCallback') {
      const { paymentToken, price, nft, startDate, auctionDuration } =
        notificationData.response;
      const token = await tokenService.getToken(paymentToken);
      const data = notificationMapper({
        amount: price,
        nftAddress: nft.toString(),
        token,
        startDate,
        endDate: Number(auctionDuration) + startDate,
      });

      notificationStore.enqueueNotify({
        type: 'AuctionDeployed',
        amount: data?.amount ?? '',
        expirationDate: data?.expirationDate ?? '',
        nftAddress: data?.nftAddress ?? '',
        startDate: data?.startDate ?? '',
        token: data?.token ?? '',
      });
      return;
    }

    notificationStore.enqueueNotify({
      type: 'AuctionDeclined',
      nftAddress: notificationData.response.nft.toString(),
    });
  } catch (error) {
    console.error(error);
  }
}
