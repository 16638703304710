import { sliceAddress } from '@broxus/js-utils';
import { Link } from 'react-router-dom';
import { AccountIcon, AccountIconProps } from '../atom';
import { CopyToClipboard } from './copy-to-clipboard';

type AccountWithAddressProps = {
  href?: string;
  canCopy?: boolean;
  asText?: boolean;
  hideIcon?: boolean;
} & AccountIconProps;

export const AccountWithAddress = ({
  size,
  address,
  href,
  canCopy = false,
  asText,
  hideIcon,
}: AccountWithAddressProps) => {
  return (
    <div className="grid grid-flow-col justify-start items-center gap-x-2">
      <div className="grid grid-flow-col justify-start items-center">
        {!hideIcon && <AccountIcon size={size} address={address} />}
        {asText ? (
          <div className="ml-1 select-none">{sliceAddress(address)}</div>
        ) : (
          <Link
            className="ml-1 text-blue-400 select-none"
            to={href ?? `/profile/${address}`}
          >
            {sliceAddress(address)}
          </Link>
        )}
      </div>

      {canCopy && <CopyToClipboard text={address} />}
    </div>
  );
};
