import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Layout } from 'widgets/layout';
import { NftList, CollectionMeta, collection } from 'modules/collection';
import Sidebar from 'widgets/layout/sidebar';
import { NotFound } from 'widgets/layout/not-found';

export const CollectionPage = observer(() => {
  const params = useParams<{ collectionAddress: string }>();

  useEffect(() => {
    if (params.collectionAddress) {
      const collectionAddress = params.collectionAddress;
      collection.getCollection(collectionAddress);
    }

    return () => collection.reset();
  }, []);
  const [sidebarHidden, setSidebarHidden] = useState(true);

  if (!collection.data && !collection.isLoading) {
    return <NotFound />;
  }
  return (
    <>
      <Layout onMenu={() => setSidebarHidden(false)}>
        <div className="container">
          <CollectionMeta />
          <div>
            <NftList />
          </div>
        </div>
      </Layout>
      <Sidebar
        mobile={true}
        hidden={sidebarHidden}
        onHide={() => setSidebarHidden(true)}
      />
    </>
  );
});
