import { Address } from 'api';
import { notificationMapper } from 'mappers';
import { tokenService } from 'services/token-service';
import { directSellRepository } from 'shared/repository/direct-sell-repository';
import { notificationStore } from 'shared/ui';

export async function buyUseCase({
  paymentToken,
  price,
  directSellAddress,
}: {
  paymentToken: Address;
  price: string;
  directSellAddress: Address;
}) {
  try {
    const { notificationData } = await directSellRepository.buy({
      paymentToken,
      price,
      directSellAddress,
    });

    if (notificationData.event === 'directSellSuccess') {
      const { nft, newOwner, paymentToken, amount } = notificationData.response;
      const token = tokenService.getToken(paymentToken.toString());
      const data = notificationMapper({
        amount,
        nftAddress: nft.toString(),
        userAddress: newOwner.toString(),
        token,
      });
      notificationStore.enqueueNotify({
        type: 'DirectSellSuccess',
        amount: data?.amount ?? '',
        nftAddress: data?.nftAddress ?? '',
        userAddress: data?.userAddress ?? '',
        token: data?.token ?? '',
      });
      return;
    }
  } catch (error) {
    console.error(error);
  }
}
