import { Link, useNavigate } from 'react-router-dom';
import { AccountIcon, ModalContext } from 'shared/ui';
import { PropsWithChildren, useContext } from 'react';
import { ReactComponent as MenuIcon } from 'assets/icons/mobile-menu.svg';
import { ReactComponent as Logo } from 'assets/icons/logo.svg';
import { wallet } from 'shared/lib/wallet';
import { observer } from 'mobx-react-lite';
import { sliceAddress } from '@broxus/js-utils';
import { Menu } from '@headlessui/react';
import { Footer } from 'widgets/layout/footer';
import { Language } from './language';
import { useIntl } from 'react-intl';
import { EVER_DECIMALS, ModalIdx } from 'shared/config';
import { formattedTokenAmount } from 'shared/lib/formatters';
import BigNumber from 'bignumber.js';
import { Search } from 'features/search/ui';

type Props = PropsWithChildren<{
  isLoading?: boolean;
  onMenu?: () => void;
  className?: string;
}>;

export const Layout = observer(
  ({ children, isLoading, onMenu, className }: Props) => {
    const intl = useIntl();
    const { handleModal } = useContext(ModalContext);
    const navigate = useNavigate();

    return (
      <div className={`${className}`}>
        <header className="bg-color-[rgba(255, 255, 255, 0.9)] py-4 px-4 mb-5 ">
          <div className="grid grid-cols-12 gap-x-5 items-center justify-between ">
            <div className="col-span-6 md:col-span-6 lg:col-span-2 order-1">
              <Link to="/">
                <div
                  className="text-blue-400 text-2xl flex items-center"
                  style={{ fontFamily: 'Faktum-SemiBold' }}
                >
                  <Logo className="w-[24px] h-auto mr-2" />
                  <span className="hidden sm:inline">Tokstock</span>
                  <span className="sm:hidden">T</span>
                </div>
              </Link>
            </div>
            <div className="col-span-12 md:col-span-12 lg:col-span-5 order-last lg:order-2">
              <Search />
              {/*<Input*/}
              {/*  placeholder="Search by collection, NFT or user"*/}
              {/*  after={<SearchIcon className="fill-grey-750 h-5 w-5" />}*/}
              {/*/>*/}
            </div>
            <div className="col-span-6 md:col-span-6 lg:col-span-5 order-3 grid grid-flow-col gap-x-5 justify-end items-center">
              <div className="hidden lg:block">
                <Link to="/">
                  {intl.formatMessage({
                    id: 'header.explore',
                    defaultMessage: 'Explore',
                  })}
                </Link>
              </div>
              <div className="hidden lg:block">
                <Link to="/guide" target="_blank">
                  {intl.formatMessage({
                    id: 'header.guide',
                    defaultMessage: 'Guide',
                  })}
                </Link>
              </div>
              {/* <div>
              <div>
                {intl.formatMessage({
                  id: 'header.stats',
                  defaultMessage: 'Stats',
                })}
              </div>
            </div> */}
              <div className="hidden lg:block">
                <Language />
              </div>
              <div className="p-2">
                {wallet.isConnected ? (
                  <div className="select-none relative">
                    <Menu>
                      <Menu.Button>
                        <div className="grid grid-flow-col items-center ">
                          <AccountIcon
                            size={'small'}
                            address={wallet.address!.toString()}
                          />
                          <div className="ml-2 text-left">
                            <div className="text-black">
                              {sliceAddress(wallet.address)}
                            </div>
                            <div>
                              {`${formattedTokenAmount(
                                wallet.walletData.balance,
                                -EVER_DECIMALS,
                                {
                                  decimalPlaces: 2,
                                  roundMode: BigNumber.ROUND_DOWN,
                                },
                              )} EVER`}
                            </div>
                          </div>
                        </div>
                      </Menu.Button>
                      <Menu.Items as="ul" className="dropdown-list  inset-x-0">
                        <Menu.Item
                          className="dropdown-item"
                          as="li"
                          onClick={() => navigate(`/profile/${wallet.address}`)}
                        >
                          {intl.formatMessage({
                            id: 'header.wallet_dropdown.profile',
                            defaultMessage: 'Profile',
                          })}
                        </Menu.Item>
                        {/* <Menu.Item className="dropdown-item" as="li">
                        {intl.formatMessage({
                          id: 'header.wallet_dropdown.my_collections',
                          defaultMessage: 'My collections',
                        })}
                      </Menu.Item> */}
                        {/* <Menu.Item className="dropdown-item" as="li">
                        {intl.formatMessage({
                          id: 'header.wallet_dropdown.settings',
                          defaultMessage: 'Settings',
                        })}
                      </Menu.Item> */}
                        <Menu.Item
                          className="dropdown-item"
                          as="li"
                          onClick={() => wallet.disconnect()}
                        >
                          {intl.formatMessage({
                            id: 'header.wallet_dropdown.log_out',
                            defaultMessage: 'Log Out',
                          })}
                        </Menu.Item>
                      </Menu.Items>
                    </Menu>
                  </div>
                ) : (
                  <button
                    className="btn secondary text-sm min-h-[36px]"
                    data-color="blue"
                    onClick={() =>
                      handleModal({ id: ModalIdx.CONNECT_WALLET_MODAL })
                    }
                  >
                    {intl.formatMessage({
                      id: 'button.connect_wallet',
                      defaultMessage: 'Connect wallet',
                    })}
                  </button>
                )}
              </div>
              <button className="block lg:hidden" onClick={onMenu}>
                <MenuIcon />
              </button>
            </div>
          </div>
        </header>
        {/* TODO: temp bottom margin */}
        {isLoading ? (
          <div className="loader"></div>
        ) : (
          <main className="mb-8">
            <div className="container">{children}</div>
          </main>
        )}

        <Footer />
      </div>
    );
  },
);
