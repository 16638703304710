import { useMemo } from 'react';
import { makeAutoObservable } from 'mobx';
import { toast, ToastContentProps, cssTransition } from 'react-toastify';
import { EventTypeUnion, NotificationOnType } from 'shared/types';
import 'react-toastify/dist/ReactToastify.css';
import 'animate.css';

import ErrorIcon from 'assets/icons/error-icon.svg';
import InfoIcon from 'assets/icons/info-icon.svg';
import WarnIcon from 'assets/icons/warn-icon.svg';
import SuccessIcon from 'assets/icons/success-icon.svg';
import CrossIcon from 'assets/icons/cross-icon.svg';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { sliceAddress } from '@broxus/js-utils';
import { Link } from 'react-router-dom';
type ToastType = 'info' | 'success' | 'error';
type ToastAction = {
  variant: 'primary' | 'secondary' | 'tertiary' | 'ghost';
  text: string;
  color: 'blue' | 'red' | 'yellow' | 'green';
};

type ToastContent = {
  title: string;
  body: JSX.Element | string;
  icon?: string;
};

type TToast = {
  actions?: ToastAction[];
  type: ToastType;
};
const statusIcons = {
  success: SuccessIcon,
  error: ErrorIcon,
  info: InfoIcon,
  warn: WarnIcon,
};
const t = cssTransition({
  enter: 'animate__animated animate__slideInRight',
  exit: 'animate__animated animate__slideOutRight ',
});
export class NotificationStore {
  notifications = [];

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  enqueueNotify(variables: NotificationOnType) {
    const toastType = getToastType(variables.type);

    toast(
      (props) => (
        <Toast
          type={toastType}
          closeToast={props.closeToast}
          variables={variables}
        />
      ),
      {
        transition: t,
      },
    );
  }
}

export const notificationStore = new NotificationStore();

function getToastType(eventType: EventTypeUnion): ToastType {
  if (
    [
      'AuctionDeployed',
      'AuctionCreated',
      'AuctionActive',
      'AuctionComplete',
      'AuctionBidPlaced',
      'DirectBuyDeployed',
      'DirectBuyApproved',
      'DirectBuyStateChanged',
      'DirectSellDeployed',
    ].includes(eventType)
  ) {
    return 'success';
  }
  if (
    [
      'AuctionDeclined',
      'AuctionBidDeclined',
      'DirectBuyDeclined',
      'DirectSellDeclined',
    ].includes(eventType)
  ) {
    return 'error';
  }

  return 'info';
}

function Toast({
  type,
  actions,
  closeToast,
  variables,
}: TToast & {
  closeToast: ToastContentProps<unknown>['closeToast'];
  variables: Partial<Omit<NotificationOnType, 'type'>>;
}) {
  const intl = useIntl();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    closeToast?.();
  };

  const content = useMemo(
    () => getNotificationContent(intl, variables),
    [intl, variables],
  );

  return (
    <div className="base-toast" data-variant={type ?? 'info'}>
      <div className="toast-icon">
        <img src={statusIcons[type]} />
      </div>
      <div>
        <div className="title">{content.title}</div>
        <div className="message">{content.body}</div>
        {actions && actions.length > 0 && (
          <div className="actions">
            {actions.map(({ text, color, variant }) => (
              <button
                className={`btn ${variant} min-w-[80px]`}
                data-color={color}
              >
                {text}
              </button>
            ))}
          </div>
        )}
      </div>
      <button className="absolute right-4 top-4" onClick={handleClick}>
        <img src={CrossIcon} className="w-4 h-4" />
      </button>
    </div>
  );
}

function getNotificationContent(
  intl: IntlShape,
  variables: Partial<NotificationOnType>,
): ToastContent {
  console.log(variables);
  switch (variables.type) {
    case 'AuctionBidDeclined':
      return {
        title: intl.formatMessage({
          id: 'notification.auction.bid.declined.title',
          defaultMessage: "Bid hasn't been placed",
        }),
        body: (
          <>
            <div>
              <FormattedMessage
                id="notification.auction.bid.declined.body.part1"
                defaultMessage={'An error occurred while placing the bid.'}
              />
            </div>
            <div>
              <FormattedMessage
                id="notification.auction.bid.declined.body.part2"
                values={{
                  nftAddress: (
                    <Link
                      className="text-blue-400"
                      to={`/nft/${variables.nftAddress}`}
                    >
                      {sliceAddress(variables.nftAddress)}
                    </Link>
                  ),
                }}
                defaultMessage={'NFT {nftAddress}.'}
              />
            </div>
          </>
        ),
      };

    case 'AuctionBidPlaced':
      return {
        title: intl.formatMessage({
          id: 'notification.auction.bid.placed.title',
          defaultMessage: 'Bid has been successfully placed',
        }),
        body: (
          <>
            <div>
              <FormattedMessage
                id="notification.auction.bid.placed.body.part1"
                defaultMessage={'Your bid {amount} {token}.'}
                values={{
                  amount: variables.amount,
                  token: variables.token,
                }}
              />
            </div>
            <div>
              <FormattedMessage
                id="notification.auction.bid.placed.body.part2"
                values={{
                  nftAddress: (
                    <Link
                      className="text-blue-400"
                      to={`/nft/${variables.nftAddress}`}
                    >
                      {sliceAddress(variables.nftAddress)}
                    </Link>
                  ),
                }}
                defaultMessage={'NFT {nftAddress}.'}
              />
            </div>
          </>
        ),
      };

    case 'AuctionCancelled':
      return {
        title: intl.formatMessage({
          id: 'notification.auction.cancelled.title',
          defaultMessage: 'Auction finished',
        }),
        body: (
          <>
            <div>
              <FormattedMessage
                id="notification.auction.cancelled.body.part1"
                defaultMessage={'NFT {nftAddress}.'}
                values={{
                  nftAddress: (
                    <Link
                      className="text-blue-400"
                      to={`/nft/${variables.nftAddress}`}
                    >
                      {sliceAddress(variables.nftAddress)}
                    </Link>
                  ),
                }}
              />
            </div>
            <div>
              <FormattedMessage
                id="notification.auction.cancelled.body.part2"
                defaultMessage={'Finished favour in {userAddress}.'}
                values={{
                  userAddress: (
                    <Link
                      className="text-blue-400"
                      to={`/nft/${variables.userAddress}`}
                    >
                      {sliceAddress(variables.userAddress)}
                    </Link>
                  ),
                }}
              />
            </div>
          </>
        ),
      };

    case 'AuctionComplete':
      return {
        title: intl.formatMessage({
          id: 'notification.auction.complete.title',
          defaultMessage: 'Auction finished',
        }),
        body: (
          <>
            <div>
              <FormattedMessage
                id="notification.auction.complete.body.part1"
                defaultMessage={'NFT {nftAddress}.'}
                values={{
                  nftAddress: (
                    <Link
                      className="text-blue-400"
                      to={`/nft/${variables.nftAddress}`}
                    >
                      {sliceAddress(variables.nftAddress)}
                    </Link>
                  ),
                }}
              />
            </div>
            <div>
              <FormattedMessage
                id="notification.auction.complete.body.part2"
                defaultMessage={'Finished favour in {userAddress}.'}
                values={{
                  userAddress: (
                    <Link
                      className="text-blue-400"
                      to={`/nft/${variables.userAddress}`}
                    >
                      {sliceAddress(variables.userAddress)}
                    </Link>
                  ),
                }}
              />
            </div>
            <div>
              <FormattedMessage
                id="notification.auction.complete.body.part3"
                defaultMessage={'Maximum bid {amount} {token}.'}
                values={{
                  amount: variables.amount,
                  token: variables.token,
                }}
              />
            </div>
          </>
        ),
      };

    case 'AuctionDeclined':
      return {
        title: intl.formatMessage({
          id: 'notification.auction.declined.title',
          defaultMessage: 'Auction not created',
        }),
        body: (
          <div>
            <FormattedMessage
              id="notification.auction.declined.body.part1"
              defaultMessage={'NFT {nftAddress}.'}
              values={{
                nftAddress: (
                  <Link
                    className="text-blue-400"
                    to={`/nft/${variables.nftAddress}`}
                  >
                    {sliceAddress(variables.nftAddress)}
                  </Link>
                ),
              }}
            />
          </div>
        ),
      };

    case 'AuctionDeployed':
      return {
        title: intl.formatMessage({
          id: 'notification.auction.deployed.title',
          defaultMessage: 'Auction created successfully',
        }),
        body: (
          <>
            <div>
              <FormattedMessage
                id="notification.auction.deployed.body.part1"
                defaultMessage={'NFT {nftAddress}.'}
                values={{
                  nftAddress: (
                    <Link
                      className="text-blue-400"
                      to={`/nft/${variables.nftAddress}`}
                    >
                      {sliceAddress(variables.nftAddress)}
                    </Link>
                  ),
                }}
              />
            </div>
            <div>
              <FormattedMessage
                id="notification.auction.deployed.body.part2"
                defaultMessage={'Minimum bid {amount} {token}.'}
                values={{
                  amount: variables.amount,
                  token: variables.token,
                }}
              />
            </div>
            <div>
              <FormattedMessage
                id="notification.auction.deployed.body.part3"
                defaultMessage={'Start date {startDate}.'}
                values={{
                  startDate: variables.startDate,
                }}
              />
            </div>
            <div>
              <FormattedMessage
                id="notification.auction.deployed.body.part4"
                defaultMessage={'Auction expiration {expirationDate}.'}
                values={{
                  expirationDate: variables.expirationDate,
                }}
              />
            </div>
          </>
        ),
      };

    case 'DirectBuyApproved':
      return {
        title: intl.formatMessage({
          id: 'notification.direct.buy.approved.title',
          defaultMessage: 'Offer approved',
        }),
        body: (
          <>
            <div>
              <FormattedMessage
                id="notification.direct.buy.approved.body.part1"
                defaultMessage={'NFT {nftAddress}.'}
                values={{
                  nftAddress: (
                    <Link
                      className="text-blue-400"
                      to={`/nft/${variables.nftAddress}`}
                    >
                      {sliceAddress(variables.nftAddress)}
                    </Link>
                  ),
                }}
              />
            </div>
            <div>
              <FormattedMessage
                id="notification.direct.buy.approved.body.part2"
                defaultMessage={'New owner nft {userAddress}.'}
                values={{
                  userAddress: (
                    <Link
                      className="text-blue-400"
                      to={`/nft/${variables.userAddress}`}
                    >
                      {sliceAddress(variables.userAddress)}
                    </Link>
                  ),
                }}
              />
            </div>
            <div>
              <FormattedMessage
                id="notification.direct.buy.approved.body.part3"
                defaultMessage={'Price {amount} {token}.'}
                values={{
                  amount: variables.amount,
                  token: variables.token,
                }}
              />
            </div>
          </>
        ),
      };

    case 'DirectBuyCancelled':
      return {
        title: intl.formatMessage({
          id: 'notification.direct.buy.cancelled.title',
          defaultMessage: 'Offer cancelled',
        }),
        body: (
          <>
            <div>
              <FormattedMessage
                id="notification.direct.buy.cancelled.body.part1"
                defaultMessage={'NFT {nftAddress}.'}
                values={{
                  nftAddress: (
                    <Link
                      className="text-blue-400"
                      to={`/nft/${variables.nftAddress}`}
                    >
                      {sliceAddress(variables.nftAddress)}
                    </Link>
                  ),
                }}
              />
            </div>
            <div>
              <FormattedMessage
                id="notification.direct.buy.cancelled.body.part2"
                defaultMessage={'Refund amount {amount} {token}.'}
                values={{
                  amount: variables.amount,
                  token: variables.token,
                }}
              />
            </div>
            <div>
              <FormattedMessage
                id="notification.direct.buy.cancelled.body.part3"
                defaultMessage={'Recipient {userAddress}.'}
                values={{
                  userAddress: (
                    <Link
                      className="text-blue-400"
                      to={`/nft/${variables.userAddress}`}
                    >
                      {sliceAddress(variables.userAddress)}
                    </Link>
                  ),
                }}
              />
            </div>
          </>
        ),
      };

    case 'DirectBuyDeclined':
      return {
        title: intl.formatMessage({
          id: 'notification.direct.buy.declined.title',
          defaultMessage: "Offer hasn't been placed",
        }),
        body: (
          <div>
            <FormattedMessage
              id="notification.direct.buy.declined.body.part1"
              defaultMessage={'NFT {nftAddress}.'}
              values={{
                nftAddress: (
                  <Link
                    className="text-blue-400"
                    to={`/nft/${variables.nftAddress}`}
                  >
                    {sliceAddress(variables.nftAddress)}
                  </Link>
                ),
              }}
            />
          </div>
        ),
      };

    case 'DirectBuyDeployed':
      return {
        title: intl.formatMessage({
          id: 'notification.direct.buy.deployed.title',
          defaultMessage: 'Offer successfully created',
        }),
        body: (
          <>
            <div>
              <FormattedMessage
                id="notification.direct.buy.deployed.body.part1"
                defaultMessage={'Your offer {amount} {token}.'}
                values={{
                  amount: variables.amount,
                  token: variables.token,
                }}
              />
            </div>
            <div>
              <FormattedMessage
                id="notification.direct.buy.deployed.body.part2"
                defaultMessage={'NFT {nftAddress}.'}
                values={{
                  nftAddress: (
                    <Link
                      className="text-blue-400"
                      to={`/nft/${variables.nftAddress}`}
                    >
                      {sliceAddress(variables.nftAddress)}
                    </Link>
                  ),
                }}
              />
            </div>
          </>
        ),
      };

    case 'DirectSellSuccess':
      return {
        title: intl.formatMessage({
          id: 'notification.direct.sell.success.title',
          defaultMessage: 'Sale ended',
        }),
        body: (
          <>
            <div>
              <FormattedMessage
                id="notification.direct.sell.success.body.part1"
                defaultMessage={'NFT {nftAddress}.'}
                values={{
                  nftAddress: (
                    <Link
                      className="text-blue-400"
                      to={`/nft/${variables.nftAddress}`}
                    >
                      {sliceAddress(variables.nftAddress)}
                    </Link>
                  ),
                }}
              />
            </div>
            <div>
              <FormattedMessage
                id="notification.direct.sell.success.body.part2"
                defaultMessage={'New owner nft {userAddress}.'}
                values={{
                  userAddress: (
                    <Link
                      className="text-blue-400"
                      to={`/nft/${variables.userAddress}`}
                    >
                      {sliceAddress(variables.userAddress)}
                    </Link>
                  ),
                }}
              />
            </div>
            <div>
              <FormattedMessage
                id="notification.direct.sell.success.body.part3"
                defaultMessage={'Price {amount} {token}.'}
                values={{
                  amount: variables.amount,
                  token: variables.token,
                }}
              />
            </div>
          </>
        ),
      };

    case 'DirectSellCancelled':
      return {
        title: intl.formatMessage({
          id: 'notification.direct.sell.cancelled.title',
          defaultMessage: 'Sale cancelled',
        }),
        body: (
          <div>
            <FormattedMessage
              id="notification.direct.sell.cancelled.body.part1"
              defaultMessage={'NFT {nftAddress}.'}
              values={{
                nftAddress: (
                  <Link
                    className="text-blue-400"
                    to={`/nft/${variables.nftAddress}`}
                  >
                    {sliceAddress(variables.nftAddress)}
                  </Link>
                ),
              }}
            />
          </div>
        ),
      };

    case 'DirectSellDeclined':
      return {
        title: intl.formatMessage({
          id: 'notification.direct.sell.declined.title',
          defaultMessage: 'Put on sale failed',
        }),
        body: intl.formatMessage(
          {
            id: 'notification.direct.sell.declined.body',
            defaultMessage: 'An error occurred while putting on sale',
          },
          variables,
        ),
      };

    case 'DirectSellDeployed':
      return {
        title: intl.formatMessage({
          id: 'notification.direct.sell.deployed.title',
          defaultMessage: 'Put on sale successfully',
        }),
        body: (
          <>
            <div>
              <FormattedMessage
                id="notification.direct.sell.deployed.body.part1"
                defaultMessage={'NFT {nftAddress}.'}
                values={{
                  nftAddress: (
                    <Link
                      className="text-blue-400"
                      to={`/nft/${variables.nftAddress}`}
                    >
                      {sliceAddress(variables.nftAddress)}
                    </Link>
                  ),
                }}
              />
            </div>
            <div>
              <FormattedMessage
                id="notification.direct.sell.deployed.body.part2"
                defaultMessage={'Price {amount} {token}.'}
                values={{
                  amount: variables.amount,
                  token: variables.token,
                }}
              />
            </div>
            <div>
              <FormattedMessage
                id="notification.direct.sell.deployed.body.part3"
                defaultMessage={'Start date {startDate}.'}
                values={{
                  startDate: variables.startDate,
                }}
              />
            </div>

            {Number(variables.expirationDate) > 1 && (
              <div>
                <FormattedMessage
                  id="notification.direct.sell.deployed.body.part4"
                  defaultMessage={'Sale expiration {expirationDate}.'}
                  values={{
                    expirationDate: variables.expirationDate,
                  }}
                />
              </div>
            )}
          </>
        ),
      };

    default:
      return {
        title: '',
        body: '',
      };
  }
}
