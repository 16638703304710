import BigNumber from 'bignumber.js';
import { millisecondsToSeconds } from 'date-fns';
import { Address } from 'everscale-inpage-provider';

export const INDEX_BASIS_CODE_HASH =
  '51D2E5E5718525436E8179CD5450038C2F1DCF6E4FD8A2C1526D114AAD0E0F84' as const;
export const tokenListUrl =
  'https://raw.githubusercontent.com/broxus/ton-assets/master/manifest.json';

// root auction tip 3
// '0:9b10e4ab6be3ad33ce621d2c7aec866bdf81983e7e2ce660423fae9b29f0ca65';
export const WEVER_ADDRESS =
  '0:a49cd4e158a9a15555e624759e2e4e766d22600b7800d891e46f9291f044a93d';
export const EVER_DECIMALS = 9;
const NETWORK = process.env.REACT_APP_NETWORK;

interface TContractAddressConfig {
  readonly AuctionRootTip3: Address;
  readonly FactoryDirectBuy: Address;
  readonly FactoryDirectSell: Address;
}

const AuctionRootTip3 = process.env
  .REACT_APP_AUCTION_ROOT_TIP3 as unknown as string;
const FactoryDirectBuy = process.env
  .REACT_APP_FACTORY_DIRECT_BUY as unknown as string;
const FactoryDirectSell = process.env
  .REACT_APP_FACTORY_DIRECT_SELL as unknown as string;

function getContractAddressConfig(): TContractAddressConfig {
  switch (NETWORK) {
    case 'mainnet':
      return {
        AuctionRootTip3: new Address(AuctionRootTip3),
        FactoryDirectBuy: new Address(FactoryDirectBuy),
        FactoryDirectSell: new Address(FactoryDirectSell),
      };

    default:
      return {
        AuctionRootTip3: new Address(AuctionRootTip3),
        FactoryDirectBuy: new Address(FactoryDirectBuy),
        FactoryDirectSell: new Address(FactoryDirectSell),
      };
  }
}

export function getConnectionConfig() {
  switch (NETWORK) {
    case 'mainnet':
      return {
        networkId: 1,
        networkName: 'mainnet',
        networkEndpoint:
          'https://mainnet.evercloud.dev/68ce240f54c34ca3b8dcb813198b519b/graphql',
      };
    case 'testnet':
    default:
      return {
        networkId: 2,
        networkName: 'testnet',
        networkEndpoint:
          'https://devnet.evercloud.dev/68ce240f54c34ca3b8dcb813198b519b/graphql',
      };
  }
}

export const contractAddressConfig = getContractAddressConfig();

export const reservedDates = [
  {
    label: '1 day',
    value: '1',
  },
  {
    label: '3 days',
    value: '3',
  },
  {
    label: '7 days',
    value: '7',
  },
  {
    label: '1 month',
    value: '1-month',
  },
  {
    label: 'Custom date',
    value: 'custom-date',
  },
];

export const CHANGE_MANAGER_AMOUNT = new BigNumber(6).shiftedBy(9).toFixed();
export const CHANGE_MANAGER_VALUE = new BigNumber(5).shiftedBy(9).toFixed();

export const TOTAL_COUNT = 100;

export const defaultDateFormat = 'dd.MM.yyyy, k:mm';

//TODO: fake notification data

export const fakeNotificationData = {
  amount: '10',
  nftAddress:
    '0:b7c55b3f9f82a68e7f6121b49ee58709737f1e38e96288b2531e9a5dc61822e0',
  nftOwner:
    '0:b7c55b3f9f82a68e7f6121b49ee58709737f1e38e96288b2531e9a5dc61822e0',
  startDate: millisecondsToSeconds(Date.now()),
  duration: millisecondsToSeconds(Date.now()),
  token: {
    name: 'Wrapped EVER',
    chainId: 1,
    symbol: 'WEVER',
    decimals: 9,
    address:
      '0:a49cd4e158a9a15555e624759e2e4e766d22600b7800d891e46f9291f044a93d',
    logoURI:
      'https://raw.githubusercontent.com/broxus/ton-assets/master/icons/WEVER/logo.svg',
    version: 5,
    verified: true,
    vendor: 'broxus',
  },
  userAddress:
    '0:a49cd4e158a9a15555e624759e2e4e766d22600b7800d891e46f9291f044a93d',
};

export const BUY_NOW_MODAL = 'buy-now-modal';
export const START_SALE_MODAL = 'start-sale-modal';
export const START_AUCTION_MODAL = 'start-auction-modal';
export const MAKE_OFFER_MODAL = 'make-offer-modal';
export const MAKE_BID_MODAL = 'make-bid-modal';
export const CANCEL_SALE_MODAL = 'cancel-sale-modal';
export const CONNECT_WALLET_MODAL = 'connect-wallet-modal';
export const SETTINGS_MODAL = 'settings-modal';

export enum ModalIdx {
  BUY_NOW_MODAL = 'buy-now-modal',
  START_SALE_MODAL = 'start-sale-modal',
  START_AUCTION_MODAL = 'start-auction-modal',
  MAKE_OFFER_MODAL = 'make-offer-modal',
  MAKE_BID_MODAL = 'make-bid-modal',
  CANCEL_SALE_MODAL = 'cancel-sale-modal',
  CONNECT_WALLET_MODAL = 'connect-wallet-modal',
  SETTINGS_MODAL = 'settings-modal',
  FILTER_BY_COLLECTION_MODAL = 'filter-by-collection-modal',
}
