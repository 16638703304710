import { makeAutoObservable, runInAction } from 'mobx';

import { getTime, millisecondsToSeconds } from 'date-fns';
import BigNumber from 'bignumber.js';
import { z } from 'zod';
import { tokenWithBalanceValidation } from 'shared/lib/validation-utils';
import { makeOfferUseCase } from 'application/make-offer';
import { tokenService } from 'services/token-service';
import { DirectBuyStatus } from 'api';
import { nftStore, offersStore, OFFERS_LIMIT } from 'modules/nft';

export const makeOfferFormSchema = z.object({
  price: tokenWithBalanceValidation,
  endTime: z.union([z.date(), z.null()]).optional(),
  selectDuration: z.string().optional(),
});

export type MakeOfferFormSchema = z.infer<typeof makeOfferFormSchema>;

class MakeOfferForm {
  isLoading = false;
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  async makeOffer(
    { price: incomePrice, endTime }: MakeOfferFormSchema,
    nftAddress: string,
  ) {
    this.isLoading = true;

    try {
      const token = tokenService.getToken(incomePrice.paymentToken);

      if (!token) {
        return;
      }
      const price = new BigNumber(incomePrice.value)
        .shiftedBy(token.decimals)
        .dp(token.decimals, BigNumber.ROUND_UP)
        .toFixed();

      const startTime = millisecondsToSeconds(Date.now());
      const end = endTime && millisecondsToSeconds(getTime(endTime));
      // const t = millisecondsToSeconds(addMinutes(Date.now(), 1).getTime());
      // const s = millisecondsToSeconds(addMinutes(t, 2).getTime());
      await makeOfferUseCase({
        tokenRoot: token.address,
        startTime,
        endTime: end ? end - startTime : 0,
        nft: nftAddress,
        amount: price,
      });
      nftStore.fetchNftDetails(nftAddress);
      offersStore.getOffers(
        { nft: nftAddress, status: [DirectBuyStatus.Active] },
        { limit: OFFERS_LIMIT },
      );
    } catch (error) {
      console.error(error);
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }
}

export const makeOfferForm = new MakeOfferForm();
