import { Address } from 'api';
import { notificationMapper } from 'mappers';
import { tokenService } from 'services/token-service';
import { offerRepository } from 'shared/repository';
import { notificationStore } from 'shared/ui';

export async function cancelOfferUseCase(nftAddress: Address) {
  try {
    const { notificationData } = await offerRepository.closeBuy(nftAddress);

    if (notificationData.event === 'directBuyClose') {
      const {
        nft,
        oldOwner,
        amount,
        token: paymentToken,
      } = notificationData.response;

      const token = tokenService.getToken(paymentToken.toString());
      const data = notificationMapper({
        nftAddress: nft.toString(),
        userAddress: oldOwner.toString(),
        token,
        amount,
      });

      notificationStore.enqueueNotify({
        type: 'DirectBuyCancelled',
        amount: data?.amount ?? '',
        nftAddress: data?.nftAddress ?? '',
        token: data?.token ?? '',
        userAddress: data?.userAddress ?? '',
      });
      return;
    }
  } catch (error) {
    console.error(error);
  }
}
