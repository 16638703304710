import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { Address } from 'api';
import { acceptOfferUseCase } from 'application/accept-offer';
import { cancelOfferUseCase } from 'application/cancel-offer';
import { Filter, OfferBox, Pagination } from 'shared/ui';
import {
  profileOffersIn,
  profileOffersOut,
  filterOffersIn,
  filterOffersOut,
} from '../model/offers';

const OFFERS_LIMIT = 2;

export const OffersIn = observer(() => {
  const intl = useIntl();
  const { profileAddress } = useParams<'profileAddress'>();
  const [page, setPage] = useState(1);

  const handleChange = (page: number) => {
    profileOffersIn.getList(
      { ...filterOffersIn.filter, owner: profileAddress },
      {
        limit: OFFERS_LIMIT,
        offset: (page - 1) * OFFERS_LIMIT,
      },
    );
    setPage(page);
  };

  return (
    <>
      <div className="title mb-5">
        {intl.formatMessage({
          id: 'profile.offers_received',
          defaultMessage: 'Offers received',
        })}
      </div>

      <div className="mb-5">
        <Filter
          onClearFilter={filterOffersIn.clear}
          filterCollectionsStore={filterOffersIn.filterCollectionsStore}
        />
      </div>

      <div className="mb-5">
        <OfferInList />
      </div>
      {!profileOffersIn.isEmpty && (
        <div className="grid justify-end">
          <Pagination
            page={page}
            total={Math.ceil(profileOffersIn.data.totalCount / OFFERS_LIMIT)}
            onChange={handleChange}
          />
        </div>
      )}
    </>
  );
});

const OfferInList = observer(() => {
  const { profileAddress } = useParams<'profileAddress'>();

  useEffect(() => {
    profileOffersIn.getList(
      { owner: profileAddress },
      {
        limit: OFFERS_LIMIT,
      },
    );

    return () => filterOffersIn.clear();
  }, [profileAddress]);
  if (profileOffersIn.isLoading) {
    return <div className="loader"></div>;
  }

  if (profileOffersIn.data.items.length <= 0) {
    return <EmptyOffersIn />;
  }

  const handleAcceptOffer = async (
    offerAddress: Address,
    nftAddress: Address,
  ) => {
    profileOffersIn.loaders.set(offerAddress, true);
    await acceptOfferUseCase(offerAddress, nftAddress);
    profileOffersIn.loaders.delete(offerAddress);
    profileOffersIn.getList(
      { owner: profileAddress },
      {
        limit: OFFERS_LIMIT,
      },
    );
  };
  const handleCancelOffer = async (offerAddress: Address) => {
    profileOffersIn.loaders.set(offerAddress, true);
    await cancelOfferUseCase(offerAddress);
    profileOffersIn.loaders.delete(offerAddress);
    profileOffersIn.getList(
      {
        owner: profileAddress,
      },
      {
        limit: OFFERS_LIMIT,
      },
    );
  };

  return (
    <>
      {profileOffersIn.data.items.map(
        (
          {
            collectionName,
            createdAt,
            expiredAt,
            isCollectionVerified,
            nftImage,
            nftName,
            buyer,
            price,
            offerAddress,
            nftAddress,
            nftOwner,
            status,
            saleType,
            nftType,
            finishedAt,
          },
          index,
        ) => (
          <OfferBox
            isLoading={!!profileOffersIn.loaders.get(offerAddress)}
            nftOwner={nftOwner}
            key={index}
            nftAddress={nftAddress}
            collectionName={collectionName}
            nftImage={nftImage}
            nftName={nftName}
            price={price}
            isCollectionVerified={isCollectionVerified}
            buyer={buyer}
            createdAt={createdAt}
            expiredAt={expiredAt}
            onAccept={() => handleAcceptOffer(offerAddress, nftAddress)}
            onCancel={() => handleCancelOffer(offerAddress)}
            status={status}
            saleType={saleType}
            offerAddress={offerAddress}
            nftType={nftType}
            finishedAt={finishedAt}
          />
        ),
      )}
    </>
  );
});

export const OffersOut = observer(() => {
  const intl = useIntl();
  const { profileAddress } = useParams<'profileAddress'>();

  const [page, setPage] = useState(1);

  const handleChange = (page: number) => {
    profileOffersOut.getList(
      { ...filterOffersOut.filter, owner: profileAddress },
      {
        limit: OFFERS_LIMIT,
        offset: (page - 1) * OFFERS_LIMIT,
      },
    );
    setPage(page);
  };

  return (
    <>
      <div className="title mb-5">
        {intl.formatMessage({
          id: 'profile.offers_made',
          defaultMessage: 'Offers made',
        })}
      </div>

      <div className="mb-5">
        <Filter
          onClearFilter={filterOffersOut.clear}
          filterCollectionsStore={filterOffersOut.filterCollectionsStore}
        />
      </div>

      <div className="mb-5">
        <OfferOutList />
      </div>
      {!profileOffersOut.isEmpty && (
        <div className="grid justify-end">
          <Pagination
            page={page}
            total={Math.ceil(profileOffersOut.data.totalCount / OFFERS_LIMIT)}
            onChange={handleChange}
          />
        </div>
      )}
    </>
  );
});

const OfferOutList = observer(() => {
  const { profileAddress } = useParams<'profileAddress'>();

  useEffect(() => {
    profileOffersOut.getList(
      {
        owner: profileAddress,
      },
      {
        limit: OFFERS_LIMIT,
      },
    );

    return () => filterOffersOut.clear();
  }, [profileAddress]);
  if (profileOffersOut.isLoading) {
    return <div className="loader"></div>;
  }

  if (profileOffersOut.isEmpty) {
    return <EmptyOffersOut />;
  }

  const handleCancelOffer = async (offerAddress: Address) => {
    profileOffersOut.loaders.set(offerAddress, true);
    await cancelOfferUseCase(offerAddress);
    profileOffersOut.loaders.delete(offerAddress);
    profileOffersOut.getList(
      {
        owner: profileAddress,
      },
      {
        limit: OFFERS_LIMIT,
      },
    );
  };

  return (
    <>
      {profileOffersOut.data.items.map(
        (
          {
            collectionName,
            createdAt,
            expiredAt,
            isCollectionVerified,
            nftImage,
            nftName,
            buyer,
            price,
            offerAddress,
            nftAddress,
            nftOwner,
            status,
            saleType,
            nftType,
            finishedAt,
          },
          index,
        ) => (
          <OfferBox
            isLoading={!!profileOffersOut.loaders.get(offerAddress)}
            nftOwner={nftOwner}
            nftAddress={nftAddress}
            key={index}
            collectionName={collectionName}
            nftImage={nftImage}
            nftName={nftName}
            price={price}
            isCollectionVerified={isCollectionVerified}
            buyer={buyer}
            createdAt={createdAt}
            expiredAt={expiredAt}
            direction="out"
            onCancel={() => handleCancelOffer(offerAddress)}
            status={status}
            saleType={saleType}
            nftType={nftType}
            finishedAt={finishedAt}
            offerAddress={offerAddress}
          />
        ),
      )}
    </>
  );
});

const EmptyOffersIn = () => {
  const intl = useIntl();
  return (
    <div className="title text-center py-5">
      {intl.formatMessage({
        id: 'profile.empty_offers_in',
        defaultMessage: 'No offers received',
      })}
    </div>
  );
};
const EmptyOffersOut = () => {
  const intl = useIntl();
  return (
    <div className="title text-center py-5">
      {intl.formatMessage({
        id: 'profile.empty_offers_out',
        defaultMessage: "You haven't made any offers yet",
      })}
    </div>
  );
};
