export const GET_TYPE_CONTRACT = {
  'ABI version': 2,
  version: '2.2',
  header: ['pubkey', 'time', 'expire'],
  functions: [
    {
      name: 'getTypeContract',
      inputs: [],
      outputs: [{ name: 'value0', type: 'string' }],
    },
  ],
  data: [ ],
  events: [],
  fields: [],
} as const;
