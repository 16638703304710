import { BigNumber } from 'bignumber.js';
import {
  callbacksContract,
  CHANGE_MANAGER_AMOUNT,
  CHANGE_MANAGER_VALUE,
  client,
  contractAddressConfig,
  directSellContract,
  factoryDirectSellContract,
  nftContract,
  walletProvider,
} from 'shared/config';
import { toAddress } from 'shared/lib/utils';
import { wallet } from 'shared/lib/wallet';
import { Address, DirectSell } from 'api';
import { getID } from '../lib/id-generator';
import { makeAutoObservable, when } from 'mobx';
import { Contract } from 'everscale-inpage-provider';
import { CALLBACK_ABI } from 'abi';
import { TSellParams } from '../types';
import { isTokenWalletDeployed } from 'shared/lib/wallet';

const CLOSE_SELL_AMOUNT = new BigNumber(2).shiftedBy(9).toFixed();
const DEPLOY_WALLET_VALUE = new BigNumber(0.2).shiftedBy(9).toString();
const ACCEPT_SELL_AMOUNT = new BigNumber(5).shiftedBy(9).toFixed();

class DirectSellRepository {
  contract = factoryDirectSellContract(contractAddressConfig.FactoryDirectSell);
  directSales = new Map<string, DirectSell>();
  callbacksList: Contract<typeof CALLBACK_ABI> = callbacksContract(
    toAddress(wallet.address!),
    walletProvider,
  );

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });

    when(
      () => wallet.isConnected,
      () => {
        this.callbacksList = callbacksContract(
          toAddress(wallet.address!),
          walletProvider,
        );
      },
    );
  }

  async startSell({
    paymentToken,
    price,
    duration,
    startTime,
    nftAddress,
  }: TSellParams) {
    const subscriber = new client.Subscriber();
    const managerChangedSubscriber = new client.Subscriber();
    const walletAddress = toAddress(wallet.address!);
    const nft = nftContract(toAddress(nftAddress), walletProvider);

    const callbackId = getID();

    try {
      const successStream = await subscriber
        .transactions(toAddress(wallet.address!))
        .flatMap((item) => item.transactions)
        .filterMap(async (transaction) => {
          const result = await this.callbacksList.decodeTransaction({
            transaction,
            methods: ['directSellDeployed', 'directSellDeclined'],
          });

          if (
            result?.method === 'directSellDeployed' &&
            result.input.callbackId
          ) {
            return {
              event: result.method,
              response: {
                ...result.input,
                duration,
                startTime,
              },
            };
          }

          if (
            result?.method === 'directSellDeclined' &&
            result.input.callbackId
          ) {
            return {
              event: result.method,
              response: {
                ...result.input,
              },
            };
          }

          return;
        })
        .delayed((s) => s.first());

      const { value0: payload } = await this.contract.methods
        .buildDirectSellCreationPayload({
          callbackId,
          _nftAddress: toAddress(nftAddress),
          _price: price,
          _paymentToken: toAddress(paymentToken),
          _startTime: startTime,
          durationTime: duration,
        })
        .call();

      const managerChanged = nft.waitForEvent({
        subscriber: managerChangedSubscriber,
        filter: ({ event, data }) => {
          console.log('DEPLOY', { event, data });
          return (
            event === 'ManagerChanged' &&
            data.oldManager.equals(contractAddressConfig.FactoryDirectSell) &&
            !data.newManager.equals(wallet.address!)
          );
        },
      });

      await nft.methods
        .changeManager({
          sendGasTo: walletAddress,
          newManager: this.contract.address,
          callbacks: [
            [this.contract.address, { value: CHANGE_MANAGER_VALUE, payload }],
          ],
        })
        .send({
          from: walletAddress,
          amount: CHANGE_MANAGER_AMOUNT,
        });

      await managerChanged;
      const notificationData = await successStream();

      console.log('notification data', notificationData);

      return {
        notificationData,
      };
    } finally {
      await subscriber.unsubscribe();
    }
  }

  async buy({
    paymentToken,
    price,
    directSellAddress,
  }: {
    paymentToken: Address;
    price: string;
    directSellAddress: Address;
  }) {
    const subscriber = new client.Subscriber();

    try {
      const tokenWallet = await wallet.getTokenWallet(paymentToken);
      const walletAddress = toAddress(wallet.address!);
      const needToDeploy = !(await isTokenWalletDeployed(
        paymentToken,
        directSellAddress,
      ));
      const successStream = await subscriber
        .transactions(toAddress(wallet.address!))
        .flatMap((item) => item.transactions)
        .filterMap(async (transaction) => {
          const result = await this.callbacksList.decodeTransaction({
            transaction,
            methods: ['directSellSuccess', 'directSellNotSuccess'],
          });

          console.log('direct selll', result);
          if (
            result?.method === 'directSellSuccess' &&
            result.input.callbackId
          ) {
            return {
              event: result.method,
              response: {
                ...result.input,
                paymentToken,
                amount: price,
              },
            };
          }

          if (
            result?.method === 'directSellNotSuccess' &&
            result.input.callbackId
          ) {
            return {
              event: result.method,
              response: {
                ...result.input,
              },
            };
          }

          return;
        })
        .delayed((s) => s.first());

      await tokenWallet!.methods
        .transfer({
          payload: '',
          amount: price,
          recipient: toAddress(directSellAddress),
          remainingGasTo: walletAddress,
          deployWalletValue: needToDeploy ? DEPLOY_WALLET_VALUE : 0,
          notify: true,
        })
        .send({
          from: walletAddress,
          amount: ACCEPT_SELL_AMOUNT,
        });

      const notificationData = await successStream();

      return { notificationData };
    } finally {
      await subscriber.unsubscribe();
    }
  }

  async closeSell(directSellAddress: string) {
    const subscriber = new client.Subscriber();
    const closeSellSubscriber = new client.Subscriber();
    const directSell = directSellContract(
      toAddress(directSellAddress),
      walletProvider,
    );

    const { value0: directSellInfo } = await directSell.methods
      .getInfo()
      .call();
    const nft = nftContract(directSellInfo.nft);

    const callbackId = getID();
    try {
      const successStream = await subscriber
        .transactions(toAddress(wallet.address!))
        .flatMap((item) => item.transactions)
        .filterMap(async (transaction) => {
          const result = await this.callbacksList.decodeTransaction({
            transaction,
            methods: ['directSellClose'],
          });

          if (result?.method === 'directSellClose' && result.input.callbackId) {
            return {
              event: result.method,
              response: {
                ...result.input,
              },
            };
          }

          return;
        })
        .delayed((s) => s.first());

      await directSell.methods
        .closeSell({
          callbackId,
        })
        .send({
          from: toAddress(wallet.address!),
          amount: CLOSE_SELL_AMOUNT,
        });

      const notificationData = await successStream();
      console.log('direct selll', notificationData);

      console.log('DirectSell closed');
      return { notificationData };
    } finally {
      await subscriber.unsubscribe();
    }
  }
}

export const directSellRepository = new DirectSellRepository();
