import { Collection, AuctionBid, Nft } from 'api';
import { NftMedia, Price, Bid } from 'shared/types';

export function bidMapper({
  createdAt,
  nft,
  collectionName,
  isCollectionVerified,
  nftImage,
  nftType,
  nftName,
  saleType,
  auction,
  from,
  active,
  bid,
}: Omit<AuctionBid, 'price' | 'usdPrice'> & {
  collectionName: Collection['name'];
  isCollectionVerified: Collection['verified'];
  nftImage: NftMedia;
  nftName: Nft['name'];
  nftType: Nft['type'];
  saleType: 'forsale' | 'auction' | '';
  bid: Price;
}): Bid {
  return {
    createdAt,
    collectionName,
    isCollectionVerified: isCollectionVerified ?? false,
    nftImage,
    nftName,
    nftType: nftType ?? '',
    bid,
    active: active ?? false,
    from,
    saleType: saleType ?? '',
    nftAddress: nft,
    auctionAddress: auction,
  };
}
