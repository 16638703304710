import { ImgHTMLAttributes } from 'react';
import ImageFallbackIcon from 'assets/icons/img-fallback-icon.svg';

const pixelatedThresholdMultiplier = 2;

export interface IImage extends ImgHTMLAttributes<HTMLImageElement> {
  fallback?: string;
  mimetype?: string;
}

export const Image = ({ fallback, mimetype, ...props }: IImage) => {
  const isVideo = /video.*/g.test(mimetype ?? '');
  const isImage = /image.*/g.test(mimetype ?? '');
  const isAudio = /audio.*/g.test(mimetype ?? '');

  const handleOnErrorImage = (
    event: React.SyntheticEvent<HTMLImageElement, Event>,
  ) => {
    if (!event) {
      return;
    }

    const img = event.currentTarget;

    img.src = ImageFallbackIcon;

    props.onError?.(event);
  };

  const handleLoadImage = (
    event: React.SyntheticEvent<HTMLImageElement, Event>,
  ) => {
    const img = event.target as HTMLImageElement;

    if (
      img.naturalWidth * pixelatedThresholdMultiplier < img.width ||
      img.naturalHeight * pixelatedThresholdMultiplier < img.height
    ) {
      img.classList.add('img-pixelated');
    }
  };

  if (isVideo) {
    //@ts-expect-error
    return <video {...props} playsInline autoPlay loop controls muted />;
  }
  if (isAudio) {
    //@ts-expect-error
    return <audio {...props} playsInline loop controls />;
  }

  return (
    <img {...props} onError={handleOnErrorImage} onLoad={handleLoadImage} />
  );
};
