import { Link, NavLink } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { AccountIcon } from 'shared/ui';
import { Language } from 'widgets/layout/language';
import { wallet } from 'shared/lib/wallet';
import { sliceAddress } from '@broxus/js-utils';
import { formattedTokenAmount } from 'shared/lib/formatters';
import { EVER_DECIMALS } from 'shared/config';
import BigNumber from 'bignumber.js';
import { Button } from '@broxus/react-uikit';
import { ReactComponent as MenuIcon } from 'assets/icons/mobile-menu.svg';
import { ReactComponent as LogOut } from 'assets/icons/log-out.svg';
import { ReactComponent as Close } from 'assets/icons/cross-icon-blue.svg';

interface ISidebarProps {
  mobile?: boolean;
  hidden?: boolean;
  onHide?: () => void;
}

const Sidebar = observer((props: ISidebarProps) => {
  const intl = useIntl();

  return (
    <div className="block lg:hidden justify-between">
      {props.mobile === true && props.hidden !== true && (
        <div className="layout_sidebar_overlay" onClick={props.onHide}></div>
      )}

      <div
        className={
          'bg-[#F7F7F9] flex flex-col fixed z-1000 justify-between inset-0' +
          (props.mobile === true ? ' mobile' : '') +
          (props.hidden === true ? ' hidden' : '')
        }
      >
        <div>
          <div className="grid grid-cols-12 gap-x-5 items-center justify-between p-4 border-b border-gray-200">
            <div className="col-span-6 my-2">
              <Link to="/">
                <div
                  className="text-blue-400 text-2xl"
                  style={{ fontFamily: 'Faktum-SemiBold' }}
                >
                  <span className="hidden sm:inline">Tokstock</span>
                  <span className="sm:hidden">T</span>
                </div>
              </Link>
            </div>
            <div className="col-span-6 grid grid-flow-col gap-x-5 justify-end items-center my-2">
              {wallet.isConnected ? (
                <div className="select-none relative">
                  <div className="grid grid-flow-col items-center px-2">
                    <AccountIcon
                      size={'small'}
                      address={wallet.address!.toString()}
                    />
                    <div className="ml-2 text-left">
                      <div className="text-black">
                        {sliceAddress(wallet.address)}
                      </div>
                      <div>
                        {`${formattedTokenAmount(
                          wallet.walletData.balance,
                          -EVER_DECIMALS,
                          {
                            decimalPlaces: 2,
                            roundMode: BigNumber.ROUND_DOWN,
                          },
                        )} EVER`}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}
              <div className=" grid grid-flow-col gap-x-5 justify-end items-center">
                <Button className="block lg:hidden" onClick={props.onHide}>
                  {props.hidden ? <MenuIcon /> : <Close />}
                </Button>
              </div>
            </div>
            {/*<div className="col-span-12">*/}
            {/*  <Input*/}
            {/*    placeholder="Search by collection, NFT or user"*/}
            {/*    after={<SearchIcon className="fill-grey-750 h-5 w-5" />}*/}
            {/*  />*/}
            {/*</div>*/}
          </div>
          <div className="flex flex-col divide-gray-200 divide-y pt-2 p-4">
            <NavLink to="/" className="py-4 px-2" onClick={props.onHide}>
              {intl.formatMessage({
                id: 'header.explore',
                defaultMessage: 'Explore',
              })}
            </NavLink>
            <NavLink to="/guide" className="py-4 px-2" onClick={props.onHide}>
              {intl.formatMessage({
                id: 'header.guide',
                defaultMessage: 'Guide',
              })}
            </NavLink>
            <NavLink
              to={`/profile/${wallet.address}`}
              className="d-flex align-items-center py-4 px-2"
              onClick={props.onHide}
            >
              {intl.formatMessage({
                id: 'header.wallet_dropdown.profile',
                defaultMessage: 'Profile',
              })}
            </NavLink>
            <NavLink
              to={`/profile/${wallet.address}`}
              className="d-flex align-items-center py-4 px-2"
              onClick={props.onHide}
            >
              {intl.formatMessage({
                id: 'footer.collected',
                defaultMessage: 'Collected',
              })}
            </NavLink>
            <div className="py-2">
              <Language />
            </div>
          </div>
        </div>
        <div className="h-16 p-3">
          {wallet.isConnected ? (
            <button
              className="bg-blue-900 w-full h-full py-2 text-blue-400 flex justify-center items-center"
              onClick={() => wallet.disconnect()}
            >
              <LogOut />
              <span className="pl-2">
                {intl.formatMessage({
                  id: 'header.wallet_dropdown.log_out',
                  defaultMessage: 'Log Out',
                })}
              </span>
            </button>
          ) : (
            <button
              className="bg-blue-400 w-full h-full text-white"
              onClick={() => {}}
              // connectWalletModal.open({ type: CONNECT_WALLET_MODAL })
            >
              {intl.formatMessage({
                id: 'button.connect_wallet',
                defaultMessage: 'Connect wallet',
              })}
            </button>
          )}
        </div>
      </div>
    </div>
  );
});

export default Sidebar;
