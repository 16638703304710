import {
  NftCardSmall,
  Select,
  SelectOption,
  DatePicker,
  ModalContext,
} from 'shared/ui';
import { observer } from 'mobx-react-lite';
import {
  makeOfferForm,
  MakeOfferFormSchema,
  makeOfferFormSchema,
} from '../model';
import { useContext, useEffect } from 'react';
import { reservedDates, WEVER_ADDRESS } from 'shared/config';
import { tokenService } from 'services/token-service';
import { tokenSelectMapper } from 'shared/lib/select-mappers';
import { useForm, useWatch } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { addDateUnit } from 'shared/lib/add-date-unit';
import { InputN } from 'shared/ui/molecules/input';
import { endOfDay, isToday, startOfDay } from 'date-fns';
import { useIntl } from 'react-intl';
import { TNft } from 'shared/types';

export const MakeOfferModalContent = observer(
  ({ content }: { content: TNft }) => {
    const intl = useIntl();
    const { handleModal } = useContext(ModalContext);

    const {
      control,
      watch,
      getValues,
      setValue,
      reset,
      getFieldState,
      resetField,
      handleSubmit,
      trigger,
      formState: { isDirty, isValid, errors, isValidating, isSubmitSuccessful },
    } = useForm<MakeOfferFormSchema>({
      mode: 'onChange',
      reValidateMode: 'onChange',
      resolver: zodResolver(makeOfferFormSchema),
      defaultValues: {
        price: {
          paymentToken: WEVER_ADDRESS,
          value: '',
        },
        endTime: null,
        selectDuration: '',
      },
    });

    const selectDurationField = useWatch({
      control,
      name: 'selectDuration',
    });

    const selectPaymentToken = useWatch({
      control,
      name: 'price.paymentToken',
    });
    const endTime = useWatch({
      control,
      name: 'endTime',
    });
    useEffect(() => {
      reset();
    }, [isSubmitSuccessful, reset]);
    useEffect(() => {
      resetField('price.value');
    }, [selectPaymentToken, resetField]);
    useEffect(() => {
      if (!selectDurationField || selectDurationField === 'custom-date') {
        resetField('endTime');

        return;
      }

      setValue(
        'endTime',
        addDateUnit({
          date: new Date(),
          value: selectDurationField,
        }),
      );
    }, [selectDurationField, setValue]);

    if (!content) {
      return null;
    }

    // console.log(errors);
    const {
      address,
      image,
      name,
      mimetype,
      collection: { name: collectionName },
    } = content;

    return (
      <div className="max-w-[417px]">
        <div className="modal-title title">
          {intl.formatMessage({
            id: 'modal.make_an_offer_title',
            defaultMessage: 'Make an offer',
          })}
        </div>
        <div className="modal-description">
          {intl.formatMessage({
            id: 'make_offer.description',
            defaultMessage:
              'You intend to make offer, please check token and cost information before proceeding',
          })}
        </div>
        <div className="mb-9">
          <NftCardSmall
            media={{ src: image, mimetype }}
            collection={collectionName}
            name={name}
          />
        </div>
        <div className="mb-4">
          <label className="text-black mb-1 inline-block">
            {intl.formatMessage({
              id: 'text.amount',
              defaultMessage: 'Amount',
            })}
          </label>
          <div className=" grid grid-cols-[minmax(125px,_135px)_1fr] items-start gap-x-2">
            <Select
              controller={{
                name: 'price.paymentToken',
                control,
              }}
              options={tokenSelectMapper(tokenService.tokens)}
              disableInnerError
            >
              {tokenService.tokens.map((token, index) => (
                <SelectOption
                  key={index}
                  value={token.address}
                  className="grid grid-flow-col items-center justify-start gap-x-2"
                >
                  <img src={token.logoURI} className="w-5 h-5" />
                  {token.symbol}
                </SelectOption>
              ))}
            </Select>

            <InputN
              disableInnerError
              disabled={Boolean(getFieldState('price.paymentToken').error)}
              controller={{
                name: 'price.value',
                control,
              }}
              number
            />
          </div>
          {errors.price && (
            <div className="helper-text" data-invalid={Boolean(errors.price)}>
              {errors.price?.paymentToken?.message ??
                errors.price?.value?.message}
            </div>
          )}
        </div>
        <div className="mb-9">
          <label className="text-black mb-1 inline-block">
            {intl.formatMessage({
              id: 'text.offer_expiration',
              defaultMessage: 'Offer expiration',
            })}
          </label>
          <div className=" grid grid-cols-[minmax(125px,_135px)_1fr]  items-start gap-x-2">
            <Select
              controller={{
                name: 'selectDuration',
                control,
              }}
              options={reservedDates}
            >
              <SelectOption value="">none</SelectOption>
              {reservedDates.map((item, index) => (
                <SelectOption key={index} value={item.value}>
                  {item.label}
                </SelectOption>
              ))}
            </Select>

            <DatePicker
              controller={{ name: 'endTime', control }}
              readOnly={selectDurationField !== 'custom-date'}
              disabled={selectDurationField !== 'custom-date'}
              selectsEnd
              showTimeSelect
              minDate={new Date()}
              minTime={
                isToday(endTime || new Date())
                  ? new Date()
                  : startOfDay(endTime || new Date())
              }
              maxTime={endOfDay(new Date())}
              {...(!selectDurationField && {
                endDate: getValues('endTime'),
              })}
            />
          </div>
        </div>
        <div className="modal-actions">
          <button
            className="btn secondary"
            data-color="blue"
            onClick={() => handleModal()}
          >
            {intl.formatMessage({
              id: 'button.close',
              defaultMessage: 'Close',
            })}
          </button>
          <button
            className={`btn primary ${isValidating && 'loading'} `}
            data-color="blue"
            onClick={handleSubmit((data) =>
              makeOfferForm.makeOffer(data, address),
            )}
            disabled={!isDirty || !isValid || isValidating}
          >
            {intl.formatMessage({
              id: 'button.make_an_offer',
              defaultMessage: 'Make an offer',
            })}
          </button>
        </div>
      </div>
    );
  },
);
