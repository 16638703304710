import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import CrossIcon from 'assets/icons/cross-icon.svg';
import ReactDOM from 'react-dom';
import { ModalIdx } from 'shared/config';
import { TNft } from 'shared/types';
import {
  CancelSellModalContent,
  PurchaseModalContent,
  StartSellModalContent,
} from 'features/direct-sell';
import { ConnectWalletModal, OptCollectionContent } from '../organisms';
import { MakeBidModalContent } from 'features/auction';
import { MakeOfferModalContent } from 'features/direct-buy/ui';
import { SettingsModalContent } from 'features/profile';
import { StartAuctionModalContent } from 'features/auction/ui/start-auction-modal';

export const Modal = ({ children }: { children: ReactNode }) => {
  const { isOpen, handleModal } = useContext(ModalContext);
  const ref = useRef(null);
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      //@ts-expect-error
      if (ref.current && !ref.current.contains(event.target)) {
        handleModal();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  return isOpen ? (
    <Portal>
      <div className={`modal modal-open`}>
        <div ref={ref} className="modal-content relative">
          <div
            className="top-6 right-6 absolute hover:cursor-pointer"
            onClick={() => handleModal()}
          >
            <img src={CrossIcon} />
          </div>
          {children}
        </div>
      </div>
    </Portal>
  ) : null;
};

function Portal({ children }: { children: JSX.Element }) {
  const el = useMemo(() => document.createElement('div'), []);

  useEffect(() => {
    const target = document.body;

    target.appendChild(el);

    return () => {
      target.removeChild(el);
    };
  }, [el]);

  return ReactDOM.createPortal(children, el);
}

type Modals =
  | { id: ModalIdx.BUY_NOW_MODAL; content: TNft }
  | { id: ModalIdx.CANCEL_SALE_MODAL; content: TNft }
  | { id: ModalIdx.CONNECT_WALLET_MODAL }
  | { id: ModalIdx.MAKE_BID_MODAL; content: TNft }
  | { id: ModalIdx.MAKE_OFFER_MODAL; content: TNft }
  | { id: ModalIdx.SETTINGS_MODAL; content: TNft }
  | { id: ModalIdx.START_AUCTION_MODAL; content: TNft }
  | { id: ModalIdx.START_SALE_MODAL; content: TNft }
  | { id: ModalIdx.FILTER_BY_COLLECTION_MODAL; content: any };

export function useModal() {
  const [isOpen, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState<Modals | null>(null);

  const handleModal = (modal?: Modals | null) => {
    setOpen(!isOpen);

    if (modal) {
      setModalContent(modal);
    }
  };

  return { isOpen, handleModal, modalContent };
}
export const ModalContext = createContext<ReturnType<typeof useModal>>({
  handleModal: () => {},
  isOpen: false,
  modalContent: null,
});

function getModal<Modal extends Modals>(modal: Modal) {
  switch (modal.id) {
    case ModalIdx.BUY_NOW_MODAL:
      return (
        <Modal>
          <PurchaseModalContent content={modal.content} />
        </Modal>
      );
    case ModalIdx.CANCEL_SALE_MODAL:
      return (
        <Modal>
          <CancelSellModalContent content={modal.content} />
        </Modal>
      );
    case ModalIdx.CONNECT_WALLET_MODAL:
      return (
        <Modal>
          <ConnectWalletModal />
        </Modal>
      );
    case ModalIdx.MAKE_BID_MODAL:
      return (
        <Modal>
          <MakeBidModalContent content={modal.content} />
        </Modal>
      );
    case ModalIdx.MAKE_OFFER_MODAL:
      return (
        <Modal>
          <MakeOfferModalContent content={modal.content} />
        </Modal>
      );
    case ModalIdx.SETTINGS_MODAL:
      return (
        <Modal>
          <SettingsModalContent content={modal.content} />
        </Modal>
      );
    case ModalIdx.START_AUCTION_MODAL:
      return (
        <Modal>
          <StartAuctionModalContent content={modal.content} />
        </Modal>
      );
    case ModalIdx.START_SALE_MODAL:
      return (
        <Modal>
          <StartSellModalContent content={modal.content} />
        </Modal>
      );
    case ModalIdx.FILTER_BY_COLLECTION_MODAL:
      return (
        <Modal>
          <OptCollectionContent content={modal.content} />
        </Modal>
      );

    default:
      return null;
  }
}

export const ModalProvider = ({ children }: { children: ReactNode }) => {
  const modal = useModal();

  return (
    <ModalContext.Provider value={modal}>
      {modal.modalContent && getModal(modal.modalContent)}
      {children}
    </ModalContext.Provider>
  );
};
