import BigNumber from 'bignumber.js';
import { makeAutoObservable, runInAction } from 'mobx';
import { Address } from 'api';
import { buyUseCase } from 'application/buy';
import { closeSellUseCase } from 'application/close-sell';
import { nftStore } from 'modules/nft';
import { tokenService } from 'services/token-service';
import { wallet } from 'shared/lib/wallet';
import { formattedTokenAmount } from '@broxus/js-utils';

import { Price } from 'shared/types';

class PurchaseForm {
  isLoading = false;
  isPositive = false;
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  async checkBalance({ price }: { price: Price }) {
    const { address, decimals } = price.priceToken;

    try {
      const rawBalance = await wallet.getBalance(address);

      const balance = Number(
        formattedTokenAmount(rawBalance, decimals, { preserve: true }),
      );

      runInAction(() => {
        this.isPositive = balance > Number(price.price);
      });
    } catch (error) {
      runInAction(() => {
        this.isPositive = false;
      });
    }
  }

  async buy({
    price,
    directSellAddress,
  }: {
    price: Price;
    directSellAddress: Address;
  }) {
    this.isLoading = true;
    try {
      const token = tokenService.getToken(price.priceToken.address);

      if (!token) {
        return;
      }

      const validPrice = new BigNumber(price.price)
        .shiftedBy(token.decimals)
        .toFixed(0, BigNumber.ROUND_UP);

      console.log({
        directSellAddress,
        paymentToken: token.address,
        price: validPrice,
      });
      await buyUseCase({
        directSellAddress,
        paymentToken: token.address,
        price: validPrice,
      });
      nftStore.fetchNftDetails(nftStore.data?.address!);
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }
  async cancelSale(directSellAddress: string) {
    this.isLoading = true;

    try {
      await closeSellUseCase(directSellAddress);
      nftStore.fetchNftDetails(nftStore.data?.address!);
    } catch (error) {
      console.error(error);
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }
}

export const purchaseForm = new PurchaseForm();
