import { hasEverscaleProvider } from 'everscale-inpage-provider';
import { walletProvider } from 'shared/config';

export async function connectToWallet(): Promise<void> {
  const hasProvider = await hasEverscaleProvider();

  if (hasProvider) {
    await walletProvider.ensureInitialized();
    await walletProvider.requestPermissions({
      permissions: ['basic', 'accountInteraction'],
    });
  }
}
