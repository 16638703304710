import { ModalContext } from 'shared/ui';
import { observer } from 'mobx-react-lite';
import { purchaseForm } from '../model';
import { useIntl } from 'react-intl';
import { TNft } from 'shared/types';
import { useContext } from 'react';

export const CancelSellModalContent = observer(
  ({ content }: { content: TNft }) => {
    const intl = useIntl();
    const { handleModal } = useContext(ModalContext);
    if (content.sellingShape?.sellingMode !== 'DIRECT_SELL') {
      return null;
    }

    const {
      name,
      manager,
      collection: { name: collectionName },
    } = content;

    return (
      <div className="max-w-[417px]">
        <div className="modal-title title">
          {intl.formatMessage({
            id: 'text.cancel_sale',
            defaultMessage: 'Cancel sale',
          })}
        </div>
        <div className="modal-description mb-9">
          {intl.formatMessage(
            {
              id: 'text.cancel_sale.confirmation',
              defaultMessage:
                'Do you want to cancel the sale of {name} of the {collectionName}?',
            },
            { name: name, collectionName: collectionName },
          )}
        </div>
        <div className="modal-actions">
          <button
            className="btn secondary"
            data-color="blue"
            onClick={() => handleModal()}
          >
            {intl.formatMessage({
              id: 'button.close',
              defaultMessage: 'Close',
            })}
          </button>
          <button
            className={`btn primary ${purchaseForm.isLoading && 'loading'}`}
            disabled={purchaseForm.isLoading}
            data-color="blue"
            onClick={() => manager && purchaseForm.cancelSale(manager)}
          >
            {intl.formatMessage({
              id: 'button.confirm',
              defaultMessage: 'Confirm',
            })}
          </button>
        </div>
      </div>
    );
  },
);
