import { observer } from 'mobx-react-lite';
import { useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { makeOfferForm } from 'features/direct-buy';
import { purchaseForm } from 'features/direct-sell';
import { ModalIdx } from 'shared/config';
import { isOwner } from 'shared/lib/is-owner';
import { wallet } from 'shared/lib/wallet';
import { TNft } from 'shared/types';
import { ModalContext, OfferCard } from 'shared/ui';
import { PriceRenderer } from '../atoms';
import { SellingTimer } from '../molecules';
import { getLastOfferByUser } from 'shared/lib/offers';
import { DirectBuyStatus } from 'api';

export const DirectSellForm = observer(({ nft }: { nft: TNft }) => {
  const intl = useIntl();
  const { handleModal } = useContext(ModalContext);
  if (nft.sellingShape?.sellingMode !== 'DIRECT_SELL') {
    return null;
  }

  const {
    address,
    name,
    owner,

    sellingShape: {
      timer,
      price,
      topOffer,

      createdAt,
      finishedAt,
      expiredAt,
      offers,
    },
  } = nft;

  const myOffers = useMemo(
    () =>
      offers.items.filter(
        (item) =>
          item.buyer === wallet.address &&
          item.status === DirectBuyStatus.Active,
      ),
    [wallet.isReady, offers],
  );

  const lastUserOffer = useMemo(() => getLastOfferByUser(myOffers), [myOffers]);

  return (
    <>
      <div className="grid grid-flow-col justify-between item-center gap-x-3 mb-4">
        <div>
          {intl.formatMessage({
            id: 'text.current_price',
            defaultMessage: 'Current price',
          })}
        </div>
      </div>
      <div className="mb-4">
        <PriceRenderer
          altPrice={price?.usdPrice}
          price={price?.price}
          token={price.priceToken}
        />
      </div>

      {wallet.isReady && lastUserOffer && (
        <div
          className={`grid ${
            lastUserOffer && 'grid-cols-2'
          } items-center gap-x-2 mb-4`}
        >
          <OfferCard
            price={lastUserOffer.price}
            label={intl.formatMessage({
              id: 'text.my_offer',
              defaultMessage: 'My offer',
            })}
            myOffers={myOffers.length - 1}
          />
        </div>
      )}

      <div className="mb-4">
        {timer?.isActive && timer.isSellActive && timer.endTime > 0 && (
          <div className="p-3 bg-grey-950 grid grid-flow-col gap-x-3 items-center justify-between">
            <div>
              {intl.formatMessage({
                id: 'text.sale_ends_in',
                defaultMessage: 'Sale ends in',
              })}
            </div>
            <SellingTimer
              timer={timer}
              startTime={createdAt}
              endTime={expiredAt ?? 0}
            />
          </div>
        )}
        {timer?.isActive && !timer.isSellActive && (
          <div className="p-3 bg-grey-950 grid grid-flow-col gap-x-3 items-center justify-between">
            <div>
              {intl.formatMessage({
                id: 'text.sale_starts_in',
                defaultMessage: 'Sale starts in',
              })}
            </div>
            <SellingTimer
              timer={timer}
              startTime={createdAt}
              endTime={expiredAt ?? 0}
            />
          </div>
        )}

        {expiredAt && !timer?.isSellActive && Date.now() > expiredAt ? (
          <div className="p-3 bg-grey-950">
            {intl.formatMessage({
              id: 'text.sale_ended',
              defaultMessage: 'Sale ended',
            })}
          </div>
        ) : null}
      </div>
      {wallet.isConnected && isOwner(owner) ? (
        <div className="grid grid-cols-2 gap-x-2">
          <button className="btn primary" data-color="blue" disabled>
            {intl.formatMessage({
              id: 'button.start_auction',
              defaultMessage: 'Start auction',
            })}
          </button>
          <button
            className={`btn secondary ${purchaseForm.isLoading && 'loading'}`}
            disabled={purchaseForm.isLoading}
            data-color="blue"
            onClick={() =>
              handleModal({ id: ModalIdx.CANCEL_SALE_MODAL, content: nft })
            }
          >
            {intl.formatMessage({
              id: 'button.cancel_sale',
              defaultMessage: 'Cancel sell',
            })}
          </button>
        </div>
      ) : wallet.isConnected && !isOwner(owner) ? (
        <div className="grid grid-cols-2 gap-x-2">
          <button
            className={`btn primary ${purchaseForm.isLoading && 'loading'}`}
            data-color="blue"
            disabled={
              !timer?.isSellActive ||
              purchaseForm.isLoading ||
              makeOfferForm.isLoading
            }
            onClick={() =>
              handleModal({ id: ModalIdx.BUY_NOW_MODAL, content: nft })
            }
          >
            {intl.formatMessage({
              id: 'button.buy_now',
              defaultMessage: 'Buy now',
            })}
          </button>
          <button
            className={`btn secondary ${makeOfferForm.isLoading && 'loading'}`}
            data-color="blue"
            onClick={() =>
              handleModal({ id: ModalIdx.MAKE_OFFER_MODAL, content: nft })
            }
            disabled={purchaseForm.isLoading || makeOfferForm.isLoading}
          >
            {intl.formatMessage({
              id: 'button.make_an_offer',
              defaultMessage: 'Make an offer',
            })}
          </button>
        </div>
      ) : (
        <div className="grid grid-cols-2 gap-x-2">
          <button
            className="btn primary"
            data-color="blue"
            onClick={() => handleModal({ id: ModalIdx.CONNECT_WALLET_MODAL })}
          >
            {intl.formatMessage({
              id: 'button.connect_wallet',
              defaultMessage: 'Connect wallet',
            })}
          </button>
          {/* <button className="btn secondary" data-color="blue">
            Share
          </button> */}
        </div>
      )}
    </>
  );
});
