import { apiClient, InlineResponse2003 } from 'api';
import { NftListFilter } from 'shared/types';

export interface NftListOptions {
  limit?: number;
  exclude?: string[];
  offset?: number;
}
export type QueryOptions = {
  refetchInterval?: (response: InlineResponse2003) => number | void;
};
class NftRepository {
  async fetchNft(address: string) {
    try {
      const res = await apiClient.nftDetailsPost({ nft: address });
      return res;
    } catch (error) {
      return null;
    }
  }

  async fetchNftList({
    filter = {},
    options = {},
  }: {
    filter?: NftListFilter;
    options?: NftListOptions;
  }) {
    const { exclude, ...restOptions } = options;

    const list = await apiClient.listNFT({
      ...filter,
      ...restOptions,
    });

    return {
      ...list,
      items: list.items.filter(
        (nft) =>
          !(exclude && exclude.length > 0 && exclude.includes(nft.address)),
      ),
    };
  }

  async reload(address: string) {
    // const nft = await apiClient.nftAddressReloadMetaPost(address.toString());
    const nft = await this.fetchNft(address);

    return nft;
  }
}

export const nftRepository = new NftRepository();
