import { secondsToMilliseconds } from 'date-fns';
import {
  DirectSell as DirectSellApi,
  Auction as AuctionApi,
  DirectBuy as DirectBuyApi,
  Nft,
  Address,
  DirectSellStatus,
  Collection,
} from 'api';

import { getTopOffer } from 'shared/lib/offers';
import { Timer } from 'shared/lib/timer';

import { Auction, SellingShape, DirectSell } from 'shared/types';
import { tokenRepository } from 'shared/repository/token-repository';
import { formattedTokenAmount } from 'shared/lib/formatters';
import { getBidsUseCase } from 'application/get-bids';
import { offerMapper } from 'mappers';

async function createAuctionData(auction: AuctionApi): Promise<Auction> {
  const bids = await getBidsUseCase({ auction: auction.address });

  const token = await tokenRepository.getToken(auction.bidToken);
  const startTime = secondsToMilliseconds(auction.startTime!);
  const finishTime = secondsToMilliseconds(auction.finishTime!);

  const timer = new Timer({
    startTime,
    endTime: finishTime,
  });

  return {
    sellingMode: 'AUCTION',
    bids,
    auctionAddress: auction.address,
    nftAddress: auction.nft,
    createdAt: secondsToMilliseconds(auction.startTime!),
    finishedAt: secondsToMilliseconds(auction.finishTime!),
    bidToken: token,
    startBid: auction.startBid
      ? formattedTokenAmount(auction.startBid, -token.decimals)
      : '',
    minBid: auction.minBid
      ? formattedTokenAmount(auction.minBid, -token.decimals)
      : '',
    maxBid: auction.maxBid
      ? formattedTokenAmount(auction.maxBid, -token.decimals)
      : '',
    timer,
    lastBidFrom: auction?.lastBidFrom ?? '',
  };
}

async function createDirectSaleData(
  directSell: DirectSellApi,
): Promise<DirectSell> {
  const token = await tokenRepository.getToken(directSell.price.priceToken);

  const startTime = secondsToMilliseconds(directSell.createdAt);
  const finishTime = secondsToMilliseconds(directSell.expiredAt ?? 0);

  const timer = new Timer({
    startTime,
    endTime: finishTime,
  });

  return {
    sellingMode: 'DIRECT_SELL',
    createdAt: startTime,
    nftAddress: directSell.address,
    seller: directSell.seller,
    buyer: directSell?.buyer ?? '',
    finishedAt: directSell.finishedAt
      ? secondsToMilliseconds(directSell.finishedAt)
      : 0,
    expiredAt: finishTime,
    status: directSell?.status ?? DirectSellStatus.Cancelled,
    price: {
      ...directSell.price,
      price: formattedTokenAmount(directSell.price.price, -token.decimals),
      priceToken: token,
    },
    timer,
  };
}

export async function nftCreator(data: {
  nft: Nft;
  auction: Record<Address, AuctionApi> | null;
  directBuy: DirectBuyApi[] | null;
  directSell: Record<Address, DirectSellApi> | null;
  domainControls?: boolean;
  collection: Collection;
}): Promise<SellingShape> {
  const { nft, auction, directBuy, directSell, domainControls, collection } =
    data;

  let offers;
  if (directBuy && Object.keys(directBuy).length > 0) {
    const items = directBuy.map((offer) => {
      const token = tokenRepository.tokens.get(offer.price.priceToken)!;
      const tempNft = nft;
      const tempCollection = collection;
      return offerMapper({
        buyer: offer.buyer,
        status: offer.status,
        address: offer.address,
        nft: offer.nft,
        nftOwner: tempNft.owner,
        collectionName: tempCollection.name,
        isCollectionVerified: tempCollection.verified,
        nftImage: { src: tempNft.image, mimetype: tempNft.mimetype },
        nftName: tempNft.name,
        nftType: tempNft.type,
        price: {
          ...offer.price,
          price: formattedTokenAmount(offer.price.price, -token.decimals),
          priceToken: token,
        },
        saleType: tempNft.auction
          ? 'auction'
          : tempNft.forsale
          ? 'forsale'
          : '',
        createdAt: secondsToMilliseconds(offer.createdAt),
        expiredAt: secondsToMilliseconds(offer.expiredAt ?? 0),
        finishedAt: secondsToMilliseconds(offer.finishedAt ?? 0),
      });
    });
    offers = { items, totalCount: 0 };
  } else {
    offers = { items: [], totalCount: 0 };
  }

  const topOffer = getTopOffer(offers.items);

  if (nft?.auction && auction && Object.keys(auction).length > 0) {
    const sellingShape = await createAuctionData(auction[nft.auction]);

    if (sellingShape) {
      return { ...sellingShape, offers, domainControls };
    }
  }

  if (nft?.forsale && directSell && Object.keys(directSell).length > 0) {
    const sellingShape = await createDirectSaleData(directSell[nft.forsale]);

    if (sellingShape) {
      return {
        ...sellingShape,
        offers,
        topOffer,

        domainControls,
      };
    }
  }

  return {
    sellingMode: 'NOT_FOR_SALE',
    offers,
    topOffer,

    domainControls,
  };
}
