import {
  Contract,
  DecodedEventWithTransaction,
} from 'everscale-inpage-provider';
import { makeAutoObservable, when } from 'mobx';
import { CALLBACK_ABI, FACTORY_DIRECT_BUY } from 'abi';
import {
  Address,
  apiClient,
  DirectBuy,
  DirectBuyBody,
  DirectBuyBody1,
  DirectBuyStatus,
} from 'api';
import {
  callbacksContract,
  CHANGE_MANAGER_AMOUNT,
  CHANGE_MANAGER_VALUE,
  client,
  contractAddressConfig,
  directBuyContract,
  factoryDirectBuyContract,
  nftContract,
  walletProvider,
} from 'shared/config';
import { toAddress, toEver } from 'shared/lib/utils';

import { getID } from '../lib/id-generator';
import { wallet, isTokenWalletDeployed } from '../lib/wallet';
import { PaginationOptions } from '../types';

type TOfferParams = {
  nft: string;
  amount: string | number;
  tokenRoot: string;
  startTime: string | number;
  endTime: string | number;
};
// TODO: add to api

interface OfferListFilter {
  direction: 'in' | 'out';
  collections?: string[];
  active?: boolean;
}

type TDirectBuyDeployedEvent = DecodedEventWithTransaction<
  typeof FACTORY_DIRECT_BUY,
  'DirectBuyDeployed'
>;

const FACTORY_AMOUNT = toEver(5);
const DEPLOY_WALLET_VALUE = toEver(0.2);
const CLOSE_BUY_AMOUNT = toEver(2);

class OfferRepository {
  factoryDirectBuy = factoryDirectBuyContract(
    contractAddressConfig.FactoryDirectBuy,
  );

  callbacksList: Contract<typeof CALLBACK_ABI> = callbacksContract(
    toAddress(wallet.address!),
    walletProvider,
  );

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });

    when(
      () => wallet.isConnected,
      () => {
        this.callbacksList = callbacksContract(
          toAddress(wallet.address!),
          walletProvider,
        );
      },
    );
  }

  offers = new Map<string, DirectBuy>();

  async getOffersByNft(params: DirectBuyBody, options?: PaginationOptions) {
    const offers = await apiClient.getNftDirectBuy({
      ...params,
      ...options,
    });

    return offers;
  }

  async getOffersByOwnerIn(
    params: DirectBuyBody1,
    options?: PaginationOptions,
  ) {
    const offers = await apiClient.ownerDirectBuyInPost({
      ...params,
      ...options,
      status: params.status ?? [DirectBuyStatus.Active],
    });

    return offers;
  }
  async getOffersByOwnerOut(
    params: DirectBuyBody1,
    options?: PaginationOptions,
  ) {
    const offers = await apiClient.ownerDirectBuyPost({
      ...params,
      ...options,
      status: params.status ?? [
        DirectBuyStatus.Active,
        DirectBuyStatus.Expired,
      ],
    });

    return offers;
  }

  async makeOffer(params: TOfferParams) {
    const subscriber = new client.Subscriber();
    const directBuyFactoryContract = factoryDirectBuyContract(
      contractAddressConfig.FactoryDirectBuy,
      walletProvider,
    );

    try {
      const tokenWallet = await wallet.getTokenWallet(params.tokenRoot);
      const walletAddress = toAddress(wallet.address!);

      const callbackId = getID();

      const successStream = await subscriber
        .transactions(toAddress(wallet.address!))
        .flatMap((item) => item.transactions)
        .filterMap(async (transaction) => {
          const result = await this.callbacksList.decodeTransaction({
            transaction,
            methods: ['directBuyDeployed', 'directBuyDeployedDeclined'],
          });

          if (
            result?.method === 'directBuyDeployed' &&
            result.input.callbackId &&
            result.input.sender.equals(walletAddress)
          ) {
            return {
              event: result.method,
              response: result.input,
            };
          }

          if (
            result?.method === 'directBuyDeployedDeclined' &&
            result.input.callbackId
          ) {
            return {
              event: result.method,
              response: result.input,
            };
          }

          return;
        })
        .delayed((s) => s.first());

      const { value0: payload } = await directBuyFactoryContract.methods
        .buildDirectBuyCreationPayload({
          callbackId: callbackId,
          nft: toAddress(params.nft),
          startTime: params.startTime,
          durationTime: params.endTime,
        })
        .call();

      const needToDeploy = !(await isTokenWalletDeployed(
        params.tokenRoot,
        directBuyFactoryContract.address,
      ));

      await tokenWallet!.methods
        .transfer({
          payload,
          amount: params.amount,
          recipient: directBuyFactoryContract.address,
          remainingGasTo: walletAddress,
          deployWalletValue: needToDeploy ? DEPLOY_WALLET_VALUE : 0,
          notify: true,
        })
        .send({
          from: walletAddress,
          amount: FACTORY_AMOUNT,
        });

      const notificationData = await successStream();
      return { notificationData };
    } finally {
      await subscriber.unsubscribe();
    }
  }
  async acceptOffer(offerAddress: Address, nftAddress: Address) {
    const subscriber = new client.Subscriber();
    const nft = nftContract(toAddress(nftAddress), walletProvider);
    const directBuy = directBuyContract(toAddress(offerAddress));
    const { value0: directBuyInfo } = await directBuy.methods.getInfo().call();

    const callbackId = getID();
    try {
      const successStream = await subscriber
        .transactions(toAddress(wallet.address!))
        .flatMap((item) => item.transactions)
        .filterMap(async (transaction) => {
          const result = await this.callbacksList.decodeTransaction({
            transaction,
            methods: ['directBuySuccess', 'directBuyNotSuccess'],
          });

          console.log(result);

          if (result?.method === 'directBuySuccess') {
            return {
              event: result.method,
              response: {
                ...result.input,
                token: directBuyInfo.spentToken,
                amount: directBuyInfo._price,
              },
            };
          }

          if (result?.method === 'directBuyNotSuccess') {
            return {
              event: result.method,
              response: result.input,
            };
          }

          return;
        })
        .delayed((s) => s.first());

      await nft.methods
        .changeManager({
          sendGasTo: toAddress(wallet.address!),
          newManager: toAddress(offerAddress),
          callbacks: [
            [
              toAddress(offerAddress),
              { value: CHANGE_MANAGER_VALUE, payload: '' },
            ],
          ],
        })
        .send({
          amount: CHANGE_MANAGER_AMOUNT,
          from: toAddress(wallet.address!),
        });

      const notificationData = await successStream();

      return { notificationData };
    } finally {
      await subscriber.unsubscribe();
    }
  }
  async closeBuy(offerAddress: Address) {
    const subscriber = new client.Subscriber();

    const contract = directBuyContract(toAddress(offerAddress), walletProvider);
    const { value0: directBuyInfo } = await contract.methods.getInfo().call();

    const callbackId = getID();
    try {
      const successStream = await subscriber
        .transactions(toAddress(wallet.address!))
        .flatMap((item) => item.transactions)
        .filterMap(async (transaction) => {
          const result = await this.callbacksList.decodeTransaction({
            transaction,
            methods: ['directBuyClose'],
          });

          if (result?.method === 'directBuyClose' && result.input.callbackId) {
            return {
              event: result.method,
              response: {
                ...result.input,
                token: directBuyInfo.spentToken,
                amount: directBuyInfo._price,
                oldOwner: directBuyInfo.creator,
              },
            };
          }

          return;
        })
        .delayed((s) => s.first());

      await contract.methods
        .closeBuy({
          callbackId,
        })
        .send({
          from: toAddress(wallet.address!),
          amount: CLOSE_BUY_AMOUNT,
        });

      const notificationData = await successStream();
      console.log('DirectBuy closed');

      return { notificationData };
    } finally {
      await subscriber.unsubscribe();
    }
  }
}

export const offerRepository = new OfferRepository();
