import { Contract } from 'everscale-inpage-provider';
import { CALLBACK_ABI } from 'abi';
import {
  Address,
  apiClient,
  AuctionBidsBody,
  Auction,
  OwnerBidsinBody,
  OwnerBidsoutBody,
} from 'api';
import {
  auctionRootTip3,
  auctionTip3,
  callbacksContract,
  CHANGE_MANAGER_AMOUNT,
  CHANGE_MANAGER_VALUE,
  client,
  contractAddressConfig,
  nftContract,
  tokenRootContract,
  walletContract,
  walletProvider,
} from 'shared/config';
import { toAddress } from 'shared/lib/utils';
import { wallet } from 'shared/lib/wallet';
import { PaginationOptions } from 'shared/types';

import { makeAutoObservable, when } from 'mobx';
import { getID } from '../lib/id-generator';

export type AuctionBuilderParams = {
  nftAddress: string;
  price: string | number;
  paymentToken: string;
  startTime: number;
  durationTime: number;
};

class AuctionRepository {
  auctions = new Map<string, Auction>();
  contract = auctionRootTip3(contractAddressConfig.AuctionRootTip3);

  callbacksList: Contract<typeof CALLBACK_ABI> = callbacksContract(
    toAddress(wallet.address!),
    walletProvider,
  );

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    when(
      () => wallet.isConnected,
      () => {
        this.callbacksList = callbacksContract(
          toAddress(wallet.address!),
          walletProvider,
        );
      },
    );
  }

  async getBidsByOwnerIn(
    params: Omit<OwnerBidsinBody, 'limit' | 'offset'>,
    options?: PaginationOptions,
  ) {
    const bids = apiClient.ownerBidsInPost({
      ...params,
      ...options,
      active: true,
    });
    return bids;
  }
  async getBidsByOwnerOut(
    params: Omit<OwnerBidsoutBody, 'limit' | 'offset'>,
    options?: PaginationOptions,
  ) {
    const bids = apiClient.ownerBidsOutPost({ ...params, ...options });
    return bids;
  }
  async getAuction(auctionAddress: string): Promise<Auction | undefined> {
    if (this.auctions.has(auctionAddress)) {
      return this.auctions.get(auctionAddress);
    }
    const auction = await this.fetchAuction(auctionAddress);

    this.addAuction(auction);
    return this.getAuction(auction.address);
  }
  addAuction(auction: Auction) {
    const auctionAddress = auction.address;
    if (!this.auctions.has(auctionAddress)) {
      this.auctions.set(auctionAddress, auction);
    }
  }
  removeAuction(auctionAddress: string) {
    if (this.auctions.has(auctionAddress)) {
      this.auctions.delete(auctionAddress);
    }
  }
  async fetchAuction(address: Address): Promise<Auction> {
    const { auction } = await apiClient.getAuction({ auction: address });

    return {
      address: auction.address,
      nft: auction.nft,
      finishTime: Number(auction.finishTime),
      startTime: Number(auction.startTime),
      bidToken: auction.bidToken,
      minBid: auction.minBid,
      maxBid: auction.maxBid,
      startBid: auction.startBid,
      walletForBids: auction.walletForBids,
    };
  }

  async createAuction({
    durationTime,
    nftAddress,
    paymentToken,
    price,
    startTime,
  }: AuctionBuilderParams) {
    const nft = nftContract(toAddress(nftAddress), walletProvider);
    const rootAuction = auctionRootTip3(
      contractAddressConfig.AuctionRootTip3,
      walletProvider,
    );

    const walletAddress = toAddress(wallet.address!);

    console.log({
      auctionDuration: durationTime,
      auctionStartTime: startTime,
      paymentToken: toAddress(paymentToken),
      price: price,
      answerId: 0,
    });

    const subscriber = new client.Subscriber();
    try {
      const callbackId = getID();

      const successStream = await subscriber
        .transactions(toAddress(wallet.address!))
        .flatMap((item) => item.transactions)
        .filterMap(async (transaction) => {
          const result = await this.callbacksList.decodeTransaction({
            transaction,
            methods: [
              'auctionTip3DeployedCallback',
              'auctionTip3DeployedDeclined',
            ],
          });

          if (
            result?.method === 'auctionTip3DeployedCallback' &&
            result.input.callbackId
          ) {
            return {
              event: result.method,
              response: {
                ...result.input.offerInfo,
                paymentToken,
                startDate: startTime,
              },
            };
          }

          if (
            result?.method === 'auctionTip3DeployedDeclined' &&
            result.input.callbackId
          ) {
            return {
              event: result.method,
              response: { ...result.input, nft: nft.address },
            };
          }

          return;
        })
        .delayed((s) => s.first());

      const { value0: payload } = await rootAuction.methods
        .buildAuctionCreationPayload({
          callbackId,
          auctionDuration: durationTime,
          auctionStartTime: startTime,
          paymentToken: toAddress(paymentToken),
          price: price,
          answerId: 0,
        })
        .call();

      await nft.methods
        .changeManager({
          sendGasTo: walletAddress,
          newManager: rootAuction.address,
          callbacks: [
            [
              rootAuction.address,
              {
                value: CHANGE_MANAGER_VALUE,
                payload,
              },
            ],
          ],
        })
        .send({
          from: walletAddress,
          amount: CHANGE_MANAGER_AMOUNT,
        });

      const notificationData = await successStream();

      return {
        notificationData,
      };
    } finally {
      subscriber.unsubscribe();
    }
  }

  async getBids(params: AuctionBidsBody, options?: PaginationOptions) {
    const bids = await apiClient.listAuctionBids({
      ...params,
      ...options,
    });

    return bids;
  }

  async finishAuction({
    sendGasTo,
    auctionAddress,
  }: {
    sendGasTo: string;
    auctionAddress: string;
  }) {
    const subscriber = new client.Subscriber();
    const auction = auctionTip3(toAddress(auctionAddress), walletProvider);
    const { value0: auctionInfo } = await auction.methods.getInfo().call();
    const { currentBid } = await auction.methods.currentBid().call();

    console.log({ sendGasTo, auctionAddress });
    try {
      const callbackId = getID();

      const successStream = await subscriber
        .transactions(toAddress(wallet.address!))
        .flatMap((item) => item.transactions)
        .filterMap(async (transaction) => {
          const result = await this.callbacksList.decodeTransaction({
            transaction,
            methods: ['auctionCancelled', 'auctionComplete'],
          });

          console.log('finishAuction', result);
          if (
            result?.method === 'auctionCancelled' &&
            result.input.callbackId
          ) {
            return {
              event: result.method,
              response: {
                ...result.input,
                paymentToken: auctionInfo._paymentToken,
                amount: auctionInfo._price,
                userAddress: auctionInfo.subjectOwner,
              },
            };
          }

          if (result?.method === 'auctionComplete' && result.input.callbackId) {
            return {
              event: result.method,
              response: {
                ...result.input,
                paymentToken: auctionInfo._paymentToken,
                amount: currentBid.value,
                userAddress: currentBid.addr,
              },
            };
          }

          return;
        })
        .delayed((s) => s.first());

      await auction.methods
        .finishAuction({
          callbackId,
          sendGasTo: toAddress(sendGasTo),
        })
        .send({
          from: toAddress(sendGasTo),
          amount: CHANGE_MANAGER_AMOUNT,
        });

      const notificationData = await successStream();

      return {
        notificationData,
      };
    } finally {
      await subscriber.unsubscribe();
    }
  }

  async placeBid({
    amount,
    paymentToken,
    auctionAddress,
  }: {
    amount: string;
    auctionAddress: string;
    paymentToken: string;
  }) {
    const subscriber = new client.Subscriber();

    const auction = auctionTip3(toAddress(auctionAddress), walletProvider);
    const buyerAddress = wallet.address!;

    console.log({
      amount,
      paymentToken,
      auctionAddress,
    });
    try {
      const callbackId = getID();

      const successStream = await subscriber
        .transactions(toAddress(wallet.address!))
        .flatMap((item) => item.transactions)
        .filterMap(async (transaction) => {
          const result = await this.callbacksList.decodeTransaction({
            transaction,
            methods: ['bidPlacedCallback', 'bidNotPlacedCallback'],
          });

          if (
            result?.method === 'bidPlacedCallback' &&
            result.input.callbackId
          ) {
            return {
              event: result.method,
              response: {
                amount,
                paymentToken,
                nft: result.input.nft,
              },
            };
          }

          if (
            result?.method === 'bidNotPlacedCallback' &&
            result.input.callbackId
          ) {
            return {
              event: result.method,
              response: { ...result.input },
            };
          }

          return;
        })
        .delayed((s) => s.first());

      const { value0: bidPayload } = await auction.methods
        .buildPlaceBidPayload({
          callbackId: callbackId,
          buyer: toAddress(buyerAddress),
          answerId: 0,
        })
        .call({ responsible: true });

      const rootInstance = tokenRootContract(toAddress(paymentToken));
      const { value0: tokenOfUser } = await rootInstance.methods
        .walletOf({
          walletOwner: toAddress(buyerAddress),
          answerId: 0,
        })
        .call({ responsible: true });

      const walletInstance = walletContract(tokenOfUser, walletProvider);

      await walletInstance.methods
        .transfer({
          amount,
          recipient: toAddress(auction.address),
          payload: bidPayload,
          notify: true,
          deployWalletValue: 0,
          remainingGasTo: toAddress(buyerAddress),
        })
        .send({
          from: toAddress(buyerAddress),
          amount: CHANGE_MANAGER_AMOUNT,
        });

      const notificationData = await successStream();

      return { notificationData };
    } finally {
      await subscriber.unsubscribe();
    }
  }
}

export const auctionRepository = new AuctionRepository();
