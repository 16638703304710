import { observer } from 'mobx-react-lite';
import { NftCard } from 'modules/nft';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Filter } from 'shared/ui/organisms/filter';
import { filterNfts, collectionNfts } from '../model';

const ITEMS_PER_PAGE = 28;

export const NftList = observer(() => {
  const intl = useIntl();
  const params = useParams<{ collectionAddress: string }>();

  useEffect(() => {
    if (params.collectionAddress) {
      const collectionAddress = params.collectionAddress;
      collectionNfts.getList(
        { collections: [collectionAddress] },
        { limit: ITEMS_PER_PAGE },
      );
    }

    return () => collectionNfts.reset();
  }, []);

  const {
    data: { items, totalCount },
  } = collectionNfts;

  const handleLoadMore = () => {
    const collectionAddress = params.collectionAddress;

    if (collectionAddress) {
      collectionNfts.getList(
        {
          ...filterNfts.filter,
          collections: [collectionAddress],
          verified: false,
        },
        { limit: ITEMS_PER_PAGE, offset: items.length },
      );
    }
  };

  return (
    <>
      <Filter
        onClearFilter={() => filterNfts.clear({ refetch: true })}
        filterSaleTypeStore={filterNfts.filterSaleTypeStore}
      />
      {collectionNfts.isLoading && <div className="loader"></div>}
      <div className="responsive-grid mb-5">
        {items.map((nft, index) => (
          <NftCard nft={nft} key={index} />
        ))}
      </div>
      {items.length < totalCount && (
        <button
          className={`btn  explore-link ${
            collectionNfts.isLoading && 'loading'
          }`}
          disabled={collectionNfts.isLoading}
          onClick={handleLoadMore}
        >
          {intl.formatMessage({
            id: 'button.load_more_nfts',
            defaultMessage: 'Load more NFTs',
          })}
        </button>
      )}
    </>
  );
});
