import { makeAutoObservable, runInAction } from 'mobx';

import { PaginationOptions, PaginationItems, Bid } from 'shared/types';

import { AuctionBidsBody } from 'api';
import { getBidsUseCase } from 'application/get-bids';

export const BIDS_LIMIT = 20;

class NftDetailsBidsStore {
  bids: PaginationItems<Bid> = {
    items: [],
    totalCount: 0,
  };
  isLoading: boolean = false;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  async getBids(params: AuctionBidsBody, options?: PaginationOptions) {
    this.isLoading = true;
    const bids = await getBidsUseCase(params, options);

    runInAction(() => {
      this.bids = bids;
      this.isLoading = false;
    });
  }
}

export const bidsStore = new NftDetailsBidsStore();
