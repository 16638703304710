import {
  ChangeEvent,
  forwardRef,
  InputHTMLAttributes,
  PropsWithoutRef,
  ReactElement,
  ReactNode,
  Ref,
} from 'react';
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
  UseFormTrigger,
} from 'react-hook-form';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  invalid?: boolean;
  validationText?: string;
  after?: ReactNode;
  before?: ReactNode;
  number?: boolean;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      placeholder,
      disabled,
      id,
      required,
      onChange,
      invalid,
      validationText,
      readOnly,
      before,
      after,
      onFocus,
      onBlur,
      number,
      onClick,
      value,
    },
    ref,
  ) => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      if (number && !/^[0-9]*\.?[0-9]*$/g.test(event.currentTarget.value)) {
        event.preventDefault();
        return;
      }

      //@ts-expect-error
      onChange(event);
    };
    return (
      <>
        <div className="text-field-wrapper">
          {before && <div className="input-before">{before}</div>}
          <input
            value={value}
            onClick={onClick}
            ref={ref}
            id={id}
            className={`text-field ${className}`}
            placeholder={placeholder}
            onChange={handleChange}
            disabled={disabled}
            required={required}
            data-invalid={invalid}
            readOnly={readOnly}
            data-before={Boolean(before)}
            data-after={Boolean(after)}
            onFocus={onFocus}
            onBlur={onBlur}
          />
          {after && <div className="input-after">{after}</div>}
        </div>
        {invalid && (
          <div className="helper-text" data-invalid={invalid}>
            {validationText}
          </div>
        )}
      </>
    );
  },
);

const InputDefinition = <T extends InputProps, Fields extends FieldValues>(
  {
    controller,
    className,
    validationText,
    before,
    after,
    invalid,
    number,
    readOnly,
    placeholder,
    disabled,
    required,
    id,
    onClick,
    trigger,
    triggerList,
    disableInnerError,
    onChange,
    onFocus,
    onBlur,
  }: T & {
    controller: UseControllerProps<Fields>;
    trigger?: UseFormTrigger<Fields>;
    triggerList?: FieldPath<Fields>[];
    disableInnerError?: boolean;
  },
  ref: Ref<HTMLInputElement>,
) => {
  const { field, fieldState } = useController(controller);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;

    if (number && !/^[0-9]*\.?[0-9]*$/g.test(value)) {
      event.preventDefault();
      return;
    }
    if (trigger && triggerList) {
      trigger(triggerList);
    }
    field.onChange(event.target.value);
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    field.onBlur();

    onBlur && onBlur(event);
  };

  return (
    <>
      <div className="text-field-wrapper">
        {before && <div className="input-before">{before}</div>}
        <input
          {...field}
          onFocus={onFocus}
          onBlur={handleBlur}
          value={field.value}
          onChange={handleChange}
          id={id}
          className={`text-field ${className}`}
          placeholder={placeholder}
          disabled={disabled}
          data-invalid={invalid}
          readOnly={readOnly}
          data-before={Boolean(before)}
          data-after={Boolean(after)}
          onClick={onClick}
          ref={ref}
        />
        {after && <div className="input-after">{after}</div>}
      </div>
      {!disableInnerError && fieldState.isTouched && fieldState.error ? (
        <div className="helper-text" data-invalid={Boolean(fieldState.error)}>
          {fieldState.error.message}
        </div>
      ) : null}
    </>
  );
};

export const InputN = forwardRef(InputDefinition) as <
  T extends InputProps,
  Fields extends FieldValues,
>(
  props: PropsWithoutRef<T> & {
    controller: UseControllerProps<Fields>;
    trigger?: UseFormTrigger<Fields>;
    triggerList?: FieldPath<Fields>[];
  },
  ref?: Ref<HTMLInputElement>,
) => ReactElement;
