import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import TermsOfUsePdf from 'assets/files/terms_of_use.pdf';
import { wallet } from 'shared/lib/wallet';

export const Footer = observer((): JSX.Element => {
  const intl = useIntl();
  const year = new Date().getFullYear();

  return (
    <footer className="px-4 py-5 flex flex-col flex-wrap gap-10 mt-20 md:flex-row md:justify-between lg:container lg:px-0">
      <div className="lg:order-1">
        <Link to="/">
          <div className="text-blue-400 text-2xl">Tokstock</div>
        </Link>
      </div>

      <div className="flex flex-col gap-3 md:flex-row md:items-start lg:order-3">
        <a
          className="btn tertiary"
          data-color="blue"
          href="https://l1.broxus.com/everscale/wallet"
          target="_blank"
          rel="noopener noreferrer"
        >
          {intl.formatMessage({
            id: 'footer.install_ever_wallet',
            defaultMessage: 'Install EVER Wallet',
          })}
        </a>
        {/* <a
          className="btn tertiary"
          data-color="blue"
          href="#"
          target="_blank"
          rel="noopener noreferrer"
        >
          {intl.formatMessage({ id: 'footer.source_code_on_github' })}
        </a> */}
      </div>

      <div className="w-full flex flex-col gap-8 md:grow md:flex-row md:gap-0 md:justify-between lg:w-auto lg:max-w-[600px] lg:order-2">
        <div className="grow">
          <h3 className="footer-list-header">
            Tokstock
            {/* {intl.formatMessage({
              id: 'footer.marketplace',
              defaultMessage: 'Marketplace',
            })} */}
          </h3>
          <ul className="mt-3">
            <li className="mt-3">
              <Link className="footer-link" to="/?tab=items">
                {intl.formatMessage({
                  id: 'footer.explore_nfts',
                  defaultMessage: 'Explore NFTS',
                })}
              </Link>
            </li>
            <li className="mt-3">
              <Link className="footer-link" to="/?tab=collections">
                {intl.formatMessage({
                  id: 'footer.explore_collections',
                  defaultMessage: 'Explore Collections',
                })}
              </Link>
            </li>
            {/* <li className="mt-3">
              <Link className="footer-link" to="/stats">
                {intl.formatMessage({ id: 'footer.stats' })}
              </Link>
            </li> */}
          </ul>
        </div>

        {wallet.isConnected && (
          <div className="grow">
            <h3 className="footer-list-header">
              {intl.formatMessage({
                id: 'footer.my_account',
                defaultMessage: 'My account',
              })}
            </h3>
            <ul className="mt-3">
              <li className="mt-3">
                <Link className="footer-link" to={`/profile/${wallet.address}`}>
                  {intl.formatMessage({
                    id: 'footer.profile',
                    defaultMessage: 'Profile',
                  })}
                </Link>
              </li>
              <li className="mt-3">
                <Link className="footer-link" to={`/profile/${wallet.address}`}>
                  {intl.formatMessage({
                    id: 'footer.collected',
                    defaultMessage: 'Collected',
                  })}
                </Link>
              </li>
              <li className="mt-3">
                <Link
                  className="footer-link"
                  to={`/profile/${wallet.address}?tab=offers`}
                >
                  {intl.formatMessage({
                    id: 'footer.offers',
                    defaultMessage: 'Offers',
                  })}
                </Link>
              </li>
              <li className="mt-3">
                <Link
                  className="footer-link"
                  to={`/profile/${wallet.address}?tab=bids`}
                >
                  {intl.formatMessage({
                    id: 'footer.bids',
                    defaultMessage: 'Bids',
                  })}
                </Link>
              </li>
              {/* <li className="mt-3">
              <Link
                className="footer-link"
                to={`/profile/${wallet.address}?tab=created`}
              >
                {intl.formatMessage({
                  id: 'footer.created',
                  defaultMessage: 'Created',
                })}
              </Link>
            </li> */}
              {/* <li className="mt-3">
              <Link
                className="footer-link"
                to={`/profile/${wallet.address}?settings=1`}
              >
                {intl.formatMessage({
                  id: 'footer.settings',
                  defaultMessage: 'Settings',
                })}
              </Link>
            </li> */}
            </ul>
          </div>
        )}

        <div className="grow">
          <h3 className="footer-list-header">
            {intl.formatMessage({
              id: 'footer.our_services',
              defaultMessage: 'Our services',
            })}
          </h3>
          <ul className="mt-3">
            <li className="mt-3">
              <a
                className="footer-link"
                href="https://octusbridge.io/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {intl.formatMessage({
                  id: 'footer.octus_bridge',
                  defaultMessage: 'Octus Bridge',
                })}
              </a>
            </li>
            <li className="mt-3">
              <a
                className="footer-link"
                href="https://everwallet.net/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {intl.formatMessage({
                  id: 'footer.ever_wallet',
                  defaultMessage: 'EVER Wallet',
                })}
              </a>
            </li>
            <li className="mt-3">
              <a
                className="footer-link"
                href="https://flatqube.io/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {intl.formatMessage({
                  id: 'footer.flatqube',
                  defaultMessage: 'FlatQube',
                })}
              </a>
            </li>
            <li className="mt-3">
              <a
                className="footer-link"
                href="https://wrappedever.io/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {intl.formatMessage({
                  id: 'footer.wever',
                  defaultMessage: 'WEVER',
                })}
              </a>
            </li>
            <li className="mt-3">
              <a
                className="footer-link"
                href="https://everpools.io/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {intl.formatMessage({
                  id: 'footer.ever_pools',
                  defaultMessage: 'EVER Pools',
                })}
              </a>
            </li>
            <li className="mt-3">
              <a
                className="footer-link"
                href="https://everscan.io/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {intl.formatMessage({
                  id: 'footer.ever_scan',
                  defaultMessage: 'EVER Scan',
                })}
              </a>
            </li>
          </ul>
        </div>
        <div className="grow">
          <h3 className="footer-list-header">
            {intl.formatMessage({
              id: 'footer.faq',
              defaultMessage: 'FAQ',
            })}
          </h3>
          <ul className="mt-3">
            <li className="mt-3">
              <Link className="footer-link" to="/guide" target="_blank">
                {intl.formatMessage({
                  id: 'footer.guide',
                  defaultMessage: 'Guide'
                })}
              </Link>
            </li>
            <li className="mt-3">
              <a
                className="footer-link"
                href="https://docs.everwallet.net/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {intl.formatMessage({
                  id: 'footer.ever_wallet_manual',
                  defaultMessage: 'EVER Wallet manual',
                })}
              </a>
            </li>
            <li className="mt-3">
              <a
                className="footer-link"
                href="https://docs.everwallet.net/concepts/ever-and-wever"
                target="_blank"
                rel="noopener noreferrer"
              >
                {intl.formatMessage({
                  id: 'footer.what_is_wever',
                  defaultMessage: 'What is WEVER?',
                })}
              </a>
            </li>
            <li className="mt-3">
              <Link className="footer-link" to="/faq">
                {intl.formatMessage({
                  id: 'footer.what_is_tokstock',
                  defaultMessage: 'What is Tokstock?',
                })}
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="w-full border-t border-grey-850 pt-5 lg:flex lg:items-center lg:justify-between lg:order-4">
        {/* <div className="mb-3 lg:mb-0 lg:order-1">
          <ul className="flex gap-2.5">
            <li>
              <a
                className="footer-icon-link"
                href="#"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={TelegramIcon} alt="" />
              </a>
            </li>
            <li>
              <a
                className="footer-icon-link"
                href="#"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={FacebookIcon} alt="" />
              </a>
            </li>
            <li>
              <a
                className="footer-icon-link"
                href="#"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={TwitterIcon} alt="" />
              </a>
            </li>
            <li>
              <a
                className="footer-icon-link"
                href="#"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={GithubIcon} alt="" />
              </a>
            </li>
            <li>
              <a
                className="footer-icon-link"
                href="#"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={RedditIcon} alt="" />
              </a>
            </li>
            <li>
              <a
                className="footer-icon-link"
                href="#"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={MediumIcon} alt="" />
              </a>
            </li>
          </ul>
        </div> */}

        <div className="">
          <ul className="flex flex-wrap gap-x-6 gap-y-2 md:gap-x-8">
            <li className="w-full text-black text-sm md:w-auto">
              ©{' '}
              <a
                href="https://bf.rocks/"
                target="_blank"
                rel="noopener noreferrer"
              >
                blockchainfamily
              </a>
              , {year}
            </li>
             <li>
              <a
                className="footer-link"
                href={TermsOfUsePdf}
                target="_blank"
                rel="noopener noreferrer"
              >
                {intl.formatMessage({
                  id: 'footer.terms_of_use',
                  defaultMessage: 'Terms of use',
                })}
              </a>
            </li>
            {/* <li>
              <a
                className="footer-link"
                href="https://broxus.com/wp-content/uploads/2021/08/privacy_policy.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                {intl.formatMessage({
                  id: 'footer.privacy_policy',
                  defaultMessage: 'Privacy policy',
                })}
              </a>
            </li> */}
            {/* <li>
              <a
                className="footer-link"
                href="https://broxus.com/wp-content/uploads/2021/08/cookie_policy.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                {intl.formatMessage({
                  id: 'footer.cookies',
                  defaultMessage: 'Cookies',
                })}
              </a>
            </li> */}
          </ul>
        </div>
      </div>
    </footer>
  );
});
