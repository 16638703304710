import {
  makeAutoObservable,
  onBecomeObserved,
  onBecomeUnobserved,
  runInAction,
} from 'mobx';

export class Timer {
  time: number;
  isActive: boolean = false;
  endTime: number;
  startTime: number;
  isSellActive: boolean = false;
  private timerId: any;
  constructor({
    startTime = Date.now(),
    endTime,
  }: {
    startTime?: number;
    endTime: number;
  }) {
    this.endTime = endTime;
    this.startTime = startTime;
    let end = Date.now() < this.startTime ? this.startTime : this.endTime;
    this.time = end - Date.now();
    this.isSellActive = endTime === 0;

    makeAutoObservable(this, {}, { autoBind: true });

    runInAction(() => this.start());
    if (!this.isActive) {
      onBecomeObserved(this, 'time', this.subscribe);
      onBecomeUnobserved(this, 'time', this.unsubscribe);
    }
  }

  start() {
    if (this.endTime > 0 && Date.now() >= this.endTime) {
      this.stop();
      return;
    }
    this.isActive = true;

    this.timerId = setInterval(() => {
      let end = Date.now() < this.startTime ? this.startTime : this.endTime;
      if (this.endTime > 0 && Date.now() >= this.endTime) {
        runInAction(() => {
          this.stop();
        });

        return;
      }
      if (
        Date.now() > this.startTime &&
        this.isSellActive &&
        this.endTime === 0
      ) {
        clearInterval(this.timerId);
      }

      runInAction(() => {
        this.time = end - Date.now();
        this.isSellActive = this.isActive && Date.now() >= this.startTime;
      });
    }, 800);
  }
  stop() {
    clearInterval(this.timerId);
    this.isActive = false;
    this.isSellActive = false;
  }

  private subscribe() {
    this.start();
  }
  private unsubscribe() {
    this.stop();
  }
}
