import { createRoot } from 'react-dom/client';
import { App } from './app';
import './css/index.css';
import './css/fonts.css';
import { tokenService } from './services/token-service';

const root = createRoot(document.getElementById('root')!);
async function main() {
  await tokenService.getTokens();

  root.render(<App />);
}

main();
