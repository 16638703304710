import { Layout } from 'widgets/layout';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import {
  nftStore,
  AuctionForm,
  DirectSellForm,
  NotForSellForm,
  Bids,
  Offers,
} from 'modules/nft';
import { AccountWithAddress, Image } from 'shared/ui';
import { Tab } from '@headlessui/react';

import VerifiedIcon from 'assets/icons/verified-icon.svg';

import { TNft } from 'shared/types';
import { useIntl } from 'react-intl';
import Sidebar from 'widgets/layout/sidebar';
import { NotFound } from 'widgets/layout/not-found';

export const NftPage = observer(() => {
  const intl = useIntl();
  const [sidebarHidden, setSidebarHidden] = useState(true);
  const params = useParams();
  const location = useLocation();
  useEffect(() => {
    if (params.nftAddress) {
      nftStore.fetchNftDetails(params.nftAddress);
    }

    return () => nftStore.reset();

    //TODO: reduce requests
  }, [location]);

  if (!nftStore.isLoading && !nftStore.data) {
    return <NotFound />;
  }

  if (nftStore.isLoading || !nftStore.data) {
    return <Layout isLoading={!nftStore.data}></Layout>;
  }

  const {
    address,
    sellingShape,
    image,
    mimetype,
    collection: { name: collectionName, verified, address: collectionAddress },
    manager,
    owner,

    name,
    description,
  } = nftStore.data;

  // console.log(toJS(nftStore));

  return (
    <>
      <Layout onMenu={() => setSidebarHidden(false)}>
        <div className="container">
          <div className="grid grid-cols-1 lg:grid-cols-2 justify-between gap-x-5 items-start mb-4 mx-2 lg:mx-0">
            <div className="nft-image col-span-1">
              <Image
                className="w-full"
                src={image ?? 'http://'}
                mimetype={mimetype}
              />
            </div>
            <div className="border border-grey-850 col-span-1 mt-5 lg:mt-0">
              <div className="p-4 lg:p-8 border-b border-grey-850">
                <div className="grid grid-flow-col justify-between gap-x-3 mb-3">
                  <div className="grid grid-cols-2 items-center gap-x-1 justify-left">
                    <div className="font-medium col-span-1">
                      <Link to={`/collections/${collectionAddress}`}>
                        {collectionName}
                      </Link>
                    </div>

                    {verified && (
                      <img
                        src={VerifiedIcon}
                        className="w-[14px] h-[14px] col-span-1"
                      />
                    )}
                  </div>
                  {/* TODO will be next version */}
                  {/* <div className="grid grid-flow-col gap-x-6">
                  <div>share</div>
                  <div>like</div>
                  <div>more</div>
                </div> */}
                </div>
                <div className="title mb-4 ">{name}</div>
                <div className="grid grid-flow-col items-center justify-start gap-x-2">
                  <span className="text-grey-400 font-medium">
                    {intl.formatMessage({
                      id: 'text.owner',
                      defaultMessage: 'Owner',
                    })}
                    :
                  </span>
                  <div className="grid grid-flow-col items-center justify-start gap-x-2">
                    <AccountWithAddress
                      size={'xsmall'}
                      address={owner ?? ''}
                      href={`/profile/${owner}`}
                      canCopy
                    />
                  </div>
                </div>
              </div>
              <div className="p-4 lg:p-8">
                <SellingViewer nft={nftStore.data} />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 justify-between items-start mx-2 lg:mx-0 mb-20">
            <div>
              <Tab.Group>
                <Tab.List className="grid grid-flow-col">
                  {[
                    intl.formatMessage({
                      id: 'tabs.details',
                      defaultMessage: 'Details',
                    }),
                    intl.formatMessage({
                      id: 'tabs.offers',
                      defaultMessage: 'Offers',
                    }),
                    intl.formatMessage({
                      id: 'tabs.bids',
                      defaultMessage: 'Bids',
                    }),
                    // intl.formatMessage({
                    //   id: 'tabs.activity',
                    //   defaultMessage: 'Activity',
                    // }),
                  ].map((category, index) => {
                    if (
                      sellingShape?.sellingMode !== 'AUCTION' &&
                      index === 2
                    ) {
                      return null;
                    }

                    return (
                      <Tab
                        className={({ selected }) =>
                          `tab-btn ${selected ? 'active' : ''}`
                        }
                        key={category}
                      >
                        {category}
                      </Tab>
                    );
                  })}
                </Tab.List>
                <Tab.Panels className="mt-2">
                  <Tab.Panel>{description}</Tab.Panel>
                  <Tab.Panel>
                    <Offers />
                  </Tab.Panel>
                  <Tab.Panel>
                    <Bids />
                  </Tab.Panel>
                  {/* <Tab.Panel>
                  {nftStore.bids.items.map(
                    (
                      { active, createdBy, maxBid, timestamp, token, type },
                      index,
                    ) => {
                      return (
                        <ActivityBid
                          key={index}
                          type={type}
                          active={active}
                          maxBid={maxBid}
                          token={token}
                          timestamp={timestamp}
                          createdBy={createdBy}
                        />
                      );
                    },
                  )}
                </Tab.Panel> */}
                </Tab.Panels>
              </Tab.Group>
            </div>
          </div>
          {/* <SameCollection /> */}
        </div>
      </Layout>
      <Sidebar
        mobile={true}
        hidden={sidebarHidden}
        onHide={() => setSidebarHidden(true)}
      />
    </>
  );
});

const SellingViewer = observer(({ nft }: { nft: TNft }) => {
  if (nft.sellingShape?.sellingMode === 'AUCTION') {
    return <AuctionForm nft={nft} />;
  }
  if (nft.sellingShape?.sellingMode === 'DIRECT_SELL') {
    return <DirectSellForm nft={nft} />;
  }
  return <NotForSellForm nft={nft} />;
});

// const SameCollection = observer(() => {
//   useEffect(() => {
//     if (nftStore.data) {
//       nftCollection.getNftCollection({
//         address: nftStore.data.info.collection,
//         exclude: [nftStore.data.address],
//       });
//     }

//     return () => nftCollection.reset();
//   }, []);

//   if (!nftStore?.data || nftCollection.collection.length <= 1) {
//     return null;
//   }

//   return (
//     <div>
//       <div className="title mb-5">More from this collection</div>
//       <div className="grid grid-cols-4 gap-4 mb-5">
//         {nftCollection.collection.map(({ data, info, address }) => (
//           <NftCard
//             key={info.id}
//             image={data.preview.source}
//             address={address}
//             name={data.name}
//             owner={info.owner}
//             collectionName={'will be relevant name'}
//           />
//         ))}
//       </div>
//       <div className="grid ">
//         <Link
//           to={`/collections/${nftStore.data.info.collection}`}
//           className="explore-link"
//         >
//           Explore collection
//         </Link>
//       </div>
//     </div>
//   );
// });
