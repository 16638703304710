import { Collection, DirectBuy, DirectBuyStatus, Nft } from 'api';
import { NftMedia, Price, Offer } from 'shared/types';
export function offerMapper({
  address,
  buyer,
  createdAt,
  nft,
  price,
  expiredAt,
  finishedAt,
  status,
  collectionName,
  nftOwner,
  isCollectionVerified,
  nftImage,
  nftType,
  nftName,
  saleType,
}: Omit<DirectBuy, 'price'> & {
  collectionName: Collection['name'];
  nftOwner: Nft['owner'];
  isCollectionVerified: Collection['verified'];
  nftImage: NftMedia;
  nftName: Nft['name'];
  nftType: Nft['type'];
  saleType: 'forsale' | 'auction' | '';
  price: Price;
}): Offer {
  return {
    offerAddress: address,
    buyer,
    createdAt,
    expiredAt,
    finishedAt,
    status: status ?? DirectBuyStatus.Filled,
    nftOwner,
    collectionName,
    isCollectionVerified: isCollectionVerified ?? false,
    nftImage,
    nftName,
    nftType: nftType ?? '',
    price,
    saleType: saleType ?? '',
    nftAddress: nft,
  };
}
