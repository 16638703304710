import { observer } from 'mobx-react-lite';
import { wallet } from 'shared/lib/wallet';
import { PriceRenderer } from '../atoms';
import { TNft } from 'shared/types';
import { SellingTimer } from '../molecules';
import { makeBidForm } from 'features/auction';
import { finishAuctionUseCase } from 'application/finish-auction';
import { isOwner } from 'shared/lib/is-owner';
import { makeAutoObservable, runInAction } from 'mobx';
import { isGoodNumber } from '@broxus/js-utils';
import { ModalIdx } from 'shared/config';
import { useIntl } from 'react-intl';
import { nftStore } from '../../model';
import { useContext } from 'react';
import { ModalContext } from 'shared/ui';

export const AuctionForm = observer(({ nft }: { nft: TNft }) => {
  const intl = useIntl();
  const { handleModal } = useContext(ModalContext);
  if (nft.sellingShape?.sellingMode !== 'AUCTION') {
    return null;
  }

  const {
    sellingShape: {
      auctionAddress,
      bidToken,
      startBid,
      maxBid,
      timer,
      finishedAt,
      createdAt,
      lastBidFrom,
      domainControls,
    },
    type,
    owner,
    auction,
  } = nft;

  const price = maxBid && isGoodNumber(maxBid) ? maxBid : startBid || '';

  return (
    <>
      <div className="grid grid-flow-col justify-between item-center gap-x-3 mb-4">
        <div>{maxBid ? 'Current price' : 'Starting price'}</div>
      </div>
      <div className=" mb-4">
        <PriceRenderer price={price} token={bidToken} />
      </div>
      <div className="mb-4">
        {timer.isActive && (
          <div className="p-3 bg-grey-950 grid grid-flow-col gap-x-3 items-center justify-between">
            <div>
              {timer.isSellActive
                ? intl.formatMessage({
                    id: 'text.auction_ends_in',
                    defaultMessage: 'Auction ends in',
                  })
                : intl.formatMessage({
                    id: 'text.auction_starts_in',
                    defaultMessage: 'Auction starts in',
                  })}
            </div>
            <SellingTimer
              timer={timer}
              startTime={createdAt}
              endTime={finishedAt}
            />
          </div>
        )}

        {wallet.isConnected &&
          !timer.isSellActive &&
          Date.now() > timer.endTime && (
            <div className="p-3 bg-grey-950">
              {intl.formatMessage({
                id: 'text.auction_finished',
                defaultMessage: 'Auction finished',
              })}
            </div>
          )}
      </div>
      {wallet.isConnected &&
      (isOwner(owner) || wallet.address === lastBidFrom) &&
      !timer.isSellActive &&
      !timer.isActive ? (
        <div className="grid">
          <button
            className={`btn primary ${
              auctionWidgetModel.isLoading && 'loading'
            }`}
            disabled={auctionWidgetModel.isLoading}
            data-color="blue"
            onClick={() =>
              auctionWidgetModel.finishAuction({
                sendGasTo: wallet.address!,
                auctionAddress,
              })
            }
          >
            {intl.formatMessage({
              id: 'text.finish_auction',
              defaultMessage: 'Finish auction',
            })}
          </button>
        </div>
      ) : wallet.isConnected && isOwner(owner) ? (
        <div className="grid">
          {/* <button className="btn secondary" data-color="blue">
            Share
          </button> */}
        </div>
      ) : wallet.isConnected ? (
        <div className={` grid ${timer.isSellActive && 'grid-cols-2 gap-x-2'}`}>
          {timer.isSellActive && (
            <button
              disabled={makeBidForm.isLoading}
              className={`btn primary ${makeBidForm.isLoading && 'loading'}`}
              data-color="blue"
              onClick={() =>
                handleModal({ id: ModalIdx.MAKE_BID_MODAL, content: nft })
              }
            >
              {intl.formatMessage({
                id: 'button.make_a_bid',
                defaultMessage: 'Place a bid',
              })}
            </button>
          )}
          {/* <button className="btn secondary" data-color="blue">
            Share
          </button> */}
        </div>
      ) : (
        <div className="grid grid-cols-2 gap-x-2">
          <button
            className="btn primary"
            data-color="blue"
            onClick={() => wallet.connect()}
          >
            {intl.formatMessage({
              id: 'button.connect_wallet',
              defaultMessage: 'Connect wallet',
            })}
          </button>
          {/* <button className="btn secondary" data-color="blue">
            Share
          </button> */}
        </div>
      )}
    </>
  );
});

class AuctionWidgetModel {
  isLoading = false;
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }
  async finishAuction({
    sendGasTo,
    auctionAddress,
  }: {
    sendGasTo: string;
    auctionAddress: string;
  }) {
    this.isLoading = true;
    try {
      await finishAuctionUseCase({ sendGasTo, auctionAddress });
      nftStore.fetchNftDetails(nftStore.data?.address!);
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }
}

const auctionWidgetModel = new AuctionWidgetModel();
