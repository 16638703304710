import { observer } from 'mobx-react-lite';
import { SearchIcon } from '@heroicons/react/solid';
import { AccountIcon } from 'shared/ui';
import { apiClient, InlineResponse200Items } from 'api';
import { useEffect, useRef, useState } from 'react';
import { makeAutoObservable, runInAction } from 'mobx';
import { InputN } from 'shared/ui/molecules/input';
import { useForm, useWatch } from 'react-hook-form';
import { useDebounce } from 'shared/lib/use-debounce';
import { Link } from 'react-router-dom';
import { useOnClickOutside } from 'shared/lib/use-click-outside';
import { sliceAddress } from '@broxus/js-utils';
import { useIntl } from 'react-intl';

const getPathByContractType = (
  type: InlineResponse200Items.ContractTypeEnum,
) => {
  switch (type) {
    case InlineResponse200Items.ContractTypeEnum.Collection:
      return 'collections';

    case InlineResponse200Items.ContractTypeEnum.Nft:
      return 'nft';

    default:
      return '';
  }
};
class SearchBarStore {
  items: InlineResponse200Items[] = [];
  isLoading = false;
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  reset() {
    this.getSearch('');
  }
  async getSearch(value: string) {
    this.isLoading = true;
    const { items } = await apiClient.searchEverything(value);

    runInAction(() => {
      this.items = items ?? [];
      this.isLoading = false;
    });
  }
}
const searchBarStore = new SearchBarStore();
export const Search = observer(() => {
  const intl = useIntl();
  const [isOpen, setOpen] = useState(false);

  const { control, getValues, watch } = useForm({
    defaultValues: {
      search: '',
    },
  });

  const searchValue = useWatch({
    control,
    name: 'search',
  });

  const debouncedValue = useDebounce(searchValue, 1000);

  useEffect(() => {
    if (debouncedValue) {
      searchBarStore.getSearch(searchValue);

      return;
    }
    searchBarStore.getSearch('');
  }, [debouncedValue]);
  const ref = useRef() as React.RefObject<HTMLDivElement>;

  useOnClickOutside(ref, () => setOpen(false));
  return (
    <div className="relative">
      <InputN
        onFocus={() => setOpen(true)}
        controller={{ control, name: 'search' }}
        placeholder={intl.formatMessage({
          id: 'placeholder.global_search',
          defaultMessage: 'Search by collection, NFT or user',
        })}
        after={<SearchIcon className="fill-grey-750 h-5 w-5" />}
      />
      {isOpen && (
        <div
          ref={ref}
          className="absolute top-[40px] left-0 right-0 min-h-[50px] max-h-[200px] bg-white border border-grey-750"
        >
          {searchBarStore.isLoading && <div className="loader"></div>}
          {searchBarStore.items.length <= 0 && (
            <div className="text-center pt-2">No data</div>
          )}

          {searchBarStore.items.map(({ address, image, contractType }) => (
            <div key={address}>
              <Link
                to={`/${getPathByContractType(contractType)}/${address}`}
                className="px-3 py-2 block hover:bg-grey-950"
              >
                <div className="grid grid-flow-col gap-x-2 justify-start">
                  <AccountIcon address={address} size={'xsmall'} />
                  <span>{sliceAddress(address)}</span>

                  <span className="capitalize">{contractType}</span>
                </div>
              </Link>
            </div>
          ))}
        </div>
      )}
    </div>
  );
});
