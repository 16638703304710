import { BigNumber } from 'bignumber.js';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { CollectionPage } from 'pages/collection';
import { Explorer } from 'pages/explorer';
import { NftPage } from 'pages/nft';
import { ProfilePage } from 'pages/profile';
import { StatsPage } from 'pages/stats';
import { GuidePage } from 'pages/guide';
import { FaqPage } from 'pages/faq';
import { ModalProvider } from 'shared/ui';
import { ToastContainer } from 'react-toastify';
import { ProvideIntl } from './providers/intl-provider';
import { ScrollToTop } from './shared/lib/scroll-top';
import { observer } from 'mobx-react-lite';
import { NotFound } from 'widgets/layout/not-found';

BigNumber.config({ EXPONENTIAL_AT: 257 });

export const App = observer(() => {
  return (
    <ProvideIntl>
      <BrowserRouter>
        <ModalProvider>
          <ScrollToTop />

          <Routes>
            <Route path="/" element={<Explorer />} />
            <Route
              path="collections/:collectionAddress"
              element={<CollectionPage />}
            />
            <Route path={'nft/:nftAddress'} element={<NftPage />} />
            <Route path="profile/:profileAddress" element={<ProfilePage />} />
            <Route path="stats" element={<StatsPage />} />
            <Route path="guide" element={<GuidePage />} />
            <Route path="faq" element={<FaqPage />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <ToastContainer
            icon={false}
            position="bottom-right"
            closeOnClick={false}
            pauseOnHover={true}
            limit={5}
            hideProgressBar
            draggable={false}
            closeButton={false}
          />
        </ModalProvider>
      </BrowserRouter>
    </ProvideIntl>
  );
});
