import { InputHTMLAttributes } from 'react';
import CheckboxIcon from 'assets/icons/checkbox-icon.svg';

export const Checkbox = (props: InputHTMLAttributes<HTMLInputElement>) => {
  return (
    <label className="base-checkbox">
      <input type="checkbox" {...props} />
      <div>
        <img className="w-3" src={CheckboxIcon} />
      </div>
    </label>
  );
};
