import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { wallet } from 'shared/lib/wallet';
import EverwalletLogo from 'assets/icons/everwallet-logo.svg';
import { useIntl } from 'react-intl';

export const ConnectWalletModal = observer((): JSX.Element | null => {
  const intl = useIntl();
  const handleEverwalletConnect = useCallback(async () => {
    try {
      await wallet.connect();
    } catch (e) {
      console.debug(e);
    }
  }, []);

  return (
    <>
      <div className="h-full md:block md:h-auto md:w-[360px]">
        <h3 className="modal-title title">Connect wallet</h3>
        <hr className="-mx-4 md:-mx-6" />
        <div className="mt-5">
          {wallet.isInitialized ? (
            <button
              className={`btn wallet-btn ${wallet.isConnecting && 'loading'}`}
              onClick={handleEverwalletConnect}
            >
              <img className="mr-3" src={EverwalletLogo} alt="everwallet" />
              <span className="text-black text-base font-medium">
                {intl.formatMessage({
                  id: 'button.connect.ever_wallet',
                  defaultMessage: 'EVER Wallet',
                })}
              </span>
            </button>
          ) : (
            <a
              href="https://chrome.google.com/webstore/detail/ever-wallet/cgeeodpfagjceefieflmdfphplkenlfk"
              rel="nofollow noopener noreferrer"
              className="btn wallet-btn justify-center text-black"
              target="_blank"
            >
              <img className="mr-3" src={EverwalletLogo} alt="everwallet" />
              <span className="text-black text-base font-medium">
                {intl.formatMessage({
                  id: 'button.install.ever_wallet',
                  defaultMessage: 'Install EVER Wallet',
                })}
              </span>
            </a>
          )}
        </div>

        {/* <button className="btn secondary w-full hidden mb-2" data-color="blue">
          Show more
          <img className="-rotate-90" src={ArrowIcon} alt="show more" />
        </button> */}

        {/* TODO: links */}
        {/* <p className="text-gray-400 text-xs">
          We do not own your private keys and cannot access your funds without
          your confirmation.By connecting your wallet, you agree to our{' '}
          <a className="text-blue-400" href="#">
            Terms of Service
          </a>{' '}
          and our&nbsp;
          <a className="text-blue-400" href="#">
            Privacy Policy
          </a>
          .
        </p> */}
      </div>

      {/* {wallet.isConnecting && (
        <div className="absolute inset-0 backdrop-blur-sm flex items-center justify-center">
          <div className="loader" />
        </div>
      )} */}
    </>
  );
});
