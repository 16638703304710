import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Layout } from 'widgets/layout';
import Sidebar from 'widgets/layout/sidebar';

export const FaqPage = observer((): JSX.Element => {
  const [sidebarHidden, setSidebarHidden] = useState(true);
  const intl = useIntl();


  return (
    <>
      <Layout onMenu={() => setSidebarHidden(false)}>
        <div className="px-4 lg:px-0">
          <h2 className="title whitespace-normal mt-4">
            {intl.formatMessage({
              id: 'faq.what_is_tokstock',
              defaultMessage: 'What is Tokstock?'
            })}
          </h2>
          <p
            className="mt-2"
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: 'faq.what_is_tokstock.text',
                defaultMessage: 'Tokstock is an NFT marketplace from Blockchain Family, launched on the Everscale blockchain. Our task is to make an exemplary, open source NFT marketplace so that anyone can easily launch a similar marketplace for their own purposes.'
              })
            }}
          />

          <h2 className="title whitespace-normal mt-4">
            {intl.formatMessage({
              id: 'faq.what_is_bf',
              defaultMessage: 'What is Blockchain Family?'
            })}
          </h2>
          <p
            className="mt-2"
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: 'faq.what_is_bf.text',
                defaultMessage: 'Blockchain Family is one of the developers of the Everscale blockchain, the most scalable blockchain in existence. We have extensive experience with Everscale technology and continue to create products on it.'
              })
            }}
          />

          <h2 className="title whitespace-normal mt-4">
            {intl.formatMessage({
              id: 'faq.what_is_ever_wallet',
              defaultMessage: 'What is EVER Wallet?'
            })}
          </h2>
          <p
            className="mt-2"
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: 'faq.what_is_ever_wallet.text',
                defaultMessage: 'EVER Wallet is the Everscale blockchain wallet required to use our website. You can download the wallet from the official Everscale website. Instructions on using the wallet can be found here.'
              })
            }}
          />

          <h2 className="title whitespace-normal mt-4">
            {intl.formatMessage({
              id: 'faq.what_is_wever',
              defaultMessage: 'What is WEVER?'
            })}
          </h2>
          <p
            className="mt-2"
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: 'faq.what_is_wever.text',
                defaultMessage: 'WEVER is a TIP-3 standard token. 1 WEVER = 1 EVER. We chose WEVER due to how convenient it is to develop apps with this token. You can change EVER to WEVER and back on the wrapperdever.io website and on the <a href="https://flatqube.io" target="_blank">flatqube.io</a> exchange.'
              }, undefined, { ignoreTag: true })
            }}
          />

          <h2 className="title whitespace-normal mt-4">
            {intl.formatMessage({
              id: 'faq.how_to_add',
              defaultMessage: 'How can I add my collection to Tokstock?'
            })}
          </h2>
          <p
            className="mt-2"
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: 'faq.how_to_add.text',
                defaultMessage: 'You can sell NFTs from any user collection, but only the collections that have been verified by the team are displayed in the search function and on the main page. To verify your collection, contact us by writing an email to <a href="mailto:nick@bf.rocks">nick@bf.rocks</a>'
              }, undefined, { ignoreTag: true })
            }}
          />
        </div>
      </Layout>
      <Sidebar
        mobile={true}
        hidden={sidebarHidden}
        onHide={() => setSidebarHidden(true)}
      />
    </>
  );
});
