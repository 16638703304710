import { notificationMapper } from 'mappers';
import { directSellRepository } from 'shared/repository/direct-sell-repository';
import { notificationStore } from 'shared/ui';

export async function closeSellUseCase(directSellAddress: string) {
  try {
    const { notificationData } = await directSellRepository.closeSell(
      directSellAddress,
    );

    if (notificationData.event === 'directSellClose') {
      const { nft } = notificationData.response;
      const data = notificationMapper({
        nftAddress: nft.toString(),
      });

      console.log(data);
      notificationStore.enqueueNotify({
        type: 'DirectSellCancelled',
        nftAddress: data?.nftAddress ?? '',
      });
      return;
    }
  } catch (error) {
    console.log(error);
  }
}
