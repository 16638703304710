import { getNftListInteractor } from 'interactor/get-nft-list';
import { ListConstructor } from 'shared/lib/list-contructor';
import { CreateFilterStore } from 'shared/lib/create-filter';

export const exploreNftList = new ListConstructor({
  request: getNftListInteractor,
});

export const filterNfts = new CreateFilterStore({
  request(params, options) {
    exploreNftList.getList(params, options);
  },
});
