import { forwardRef, InputHTMLAttributes } from 'react';
import CalendarIcon from 'assets/icons/calendar-icon.svg';
import 'react-datepicker/dist/react-datepicker.css';
import { Input } from './input';
import DatePickerReact, { ReactDatePickerProps } from 'react-datepicker';
import {
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

export const InputForDatePicker = forwardRef<
  HTMLInputElement,
  InputHTMLAttributes<HTMLInputElement>
>(({ value, onClick, onChange, ...props }, ref) => (
  <Input
    before={<img src={CalendarIcon} className="w-4 h-4" />}
    onChange={onChange}
    value={value}
    className="text-field"
    onClick={onClick}
    ref={ref}
    {...props}
  />
));

export function DatePicker<Controller extends FieldValues>({
  controller,
  readOnly,
  startDate,
  minDate,
  endDate,
  dateFormat = 'Pp',
  selectsStart,
  disabled,
  showTimeSelect,

  onChange,
  ...props
}: {
  controller: UseControllerProps<Controller>;
  onChange?: ReactDatePickerProps['onChange'];
} & Omit<ReactDatePickerProps, 'onChange'>) {
  const { field } = useController(controller);
  const handleChange = (
    value: Date | null,
    event: React.SyntheticEvent<any, Event> | undefined,
  ) => {
    field.onChange(value);

    if (typeof onChange === 'function') {
      onChange(value, event);
    }
  };
  return (
    <DatePickerReact
      disabled={disabled}
      readOnly={readOnly}
      value={field.value}
      selected={field.value}
      onChange={handleChange}
      customInput={<InputForDatePicker />}
      startDate={startDate}
      selectsStart={selectsStart}
      minDate={minDate}
      showTimeSelect={showTimeSelect}
      dateFormat={dateFormat}
      endDate={endDate}
      {...props}
    />
  );
}
