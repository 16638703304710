import { formattedTokenAmount } from '@broxus/js-utils';
import { z } from 'zod';
import { tokenService } from 'services/token-service';
import { wallet } from './wallet';

export const tokenWithBalanceValidation = z
  .object({
    value: z.string().refine(Number, { message: "Can't be zero or empty" }),
    paymentToken: z.string(),
  })
  .superRefine(async ({ paymentToken, value: rawValue }, ctx) => {
    const value = Number(rawValue);

    try {
      const token = tokenService.getToken(paymentToken);

      if (!token) {
        return;
      }

      const rawBalance = await wallet.getBalance(token.address);

      const balance = Number(
        formattedTokenAmount(rawBalance, token.decimals, { preserve: true }),
      );

      if (balance <= 0 || balance < value) {
        ctx.addIssue({
          code: 'custom',
          message: 'Insufficient balance',
          path: ['value'],
        });
      }
    } catch (error) {
      ctx.addIssue({
        code: 'custom',
        message: 'Insufficient balance',
        path: ['paymentToken'],
      });
    }
  });
export const nonEmptyOrZeroToken = z.object({
  value: z.string().refine(Number, { message: "Can't be zero or empty" }),
  paymentToken: z.string(),
});
export const startDateValidation = z.date().optional();
export const durationDateValidation = z.date().optional();
export const selectDurationValidation = z
  .object({
    value: z.string(),
    label: z.string(),
    type: z.string(),
  })
  .optional();
