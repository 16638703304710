import { notificationStore } from 'shared/ui';
import { offerRepository } from 'shared/repository';
import { tokenService } from 'services/token-service';
import { notificationMapper } from 'mappers';

export async function makeOfferUseCase({
  amount,
  endTime,
  nft,
  startTime,
  tokenRoot,
}: {
  amount: string;
  endTime: number;
  nft: string;
  startTime: number;
  tokenRoot: string;
}) {
  try {
    const { notificationData } = await offerRepository.makeOffer({
      amount,
      endTime,
      nft,
      startTime,
      tokenRoot,
    });

    if (notificationData.event === 'directBuyDeployed') {
      const { amount, nft, token } = notificationData.response;
      const paymentToken = await tokenService.getToken(token.toString());

      const data = notificationMapper({
        amount,
        nftAddress: nft.toString(),
        token: paymentToken,
      });

      notificationStore.enqueueNotify({
        type: 'DirectBuyDeployed',
        amount: data?.amount ?? '',
        nftAddress: data?.nftAddress ?? '',
        token: data?.token ?? '',
      });
      return;
    }

    const data = notificationMapper({
      nftAddress: nft.toString(),
    });

    notificationStore.enqueueNotify({
      type: 'DirectBuyDeclined',
      nftAddress: data?.nftAddress ?? '',
    });
  } catch (error) {
    console.error(error);
  }
}
