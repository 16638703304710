export const AUCTION_ROOT_TIP_3_ABI = {
	"ABI version": 2,
	"version": "2.2",
	"header": ["pubkey", "time", "expire"],
	"functions": [
		{
			"name": "constructor",
			"inputs": [
				{"name":"_codeNft","type":"cell"},
				{"name":"_owner","type":"address"},
				{"name":"_offerCode","type":"cell"},
				{"name":"_deploymentFee","type":"uint128"},
				{"name":"_marketFee","type":"uint8"},
				{"name":"_marketFeeDecimals","type":"uint8"},
				{"name":"_auctionBidDelta","type":"uint16"},
				{"name":"_auctionBidDeltaDecimals","type":"uint16"},
				{"name":"_sendGasTo","type":"address"}
			],
			"outputs": [
			]
		},
		{
			"name": "getTypeContract",
			"inputs": [
			],
			"outputs": [
				{"name":"value0","type":"string"}
			]
		},
		{
			"name": "onNftChangeManager",
			"inputs": [
				{"name":"value0","type":"uint256"},
				{"name":"nftOwner","type":"address"},
				{"name":"value2","type":"address"},
				{"name":"newManager","type":"address"},
				{"name":"collection","type":"address"},
				{"name":"sendGasTo","type":"address"},
				{"name":"payload","type":"cell"}
			],
			"outputs": [
			]
		},
		{
			"name": "buildAuctionCreationPayload",
			"inputs": [
				{"name":"answerId","type":"uint32"},
				{"name":"callbackId","type":"uint32"},
				{"name":"paymentToken","type":"address"},
				{"name":"price","type":"uint128"},
				{"name":"auctionStartTime","type":"uint64"},
				{"name":"auctionDuration","type":"uint64"}
			],
			"outputs": [
				{"name":"value0","type":"cell"}
			]
		},
		{
			"name": "RequestUpgradeAuction",
			"inputs": [
				{"name":"_nft","type":"address"},
				{"name":"_nonce","type":"uint64"},
				{"name":"sendGasTo","type":"address"}
			],
			"outputs": [
			]
		},
		{
			"name": "upgradeOfferCode",
			"inputs": [
				{"name":"newCode","type":"cell"}
			],
			"outputs": [
			]
		},
		{
			"name": "upgrade",
			"inputs": [
				{"name":"newCode","type":"cell"},
				{"name":"newVersion","type":"uint32"},
				{"name":"sendGasTo","type":"address"}
			],
			"outputs": [
			]
		},
		{
			"name": "changeDeploymentFee",
			"inputs": [
				{"name":"_value","type":"uint128"}
			],
			"outputs": [
			]
		},
		{
			"name": "changeMarketFee",
			"inputs": [
				{"name":"_value","type":"uint8"},
				{"name":"_decimals","type":"uint8"}
			],
			"outputs": [
			]
		},
		{
			"name": "owner",
			"inputs": [
			],
			"outputs": [
				{"name":"value0","type":"address"}
			]
		},
		{
			"name": "transferOwnership",
			"inputs": [
				{"name":"newOwner","type":"address"}
			],
			"outputs": [
			]
		},
		{
			"name": "marketFee",
			"inputs": [
			],
			"outputs": [
				{"name":"marketFee","type":"uint8"}
			]
		},
		{
			"name": "marketFeeDecimals",
			"inputs": [
			],
			"outputs": [
				{"name":"marketFeeDecimals","type":"uint8"}
			]
		},
		{
			"name": "deploymentFee",
			"inputs": [
			],
			"outputs": [
				{"name":"deploymentFee","type":"uint128"}
			]
		},
		{
			"name": "auctionBidDelta",
			"inputs": [
			],
			"outputs": [
				{"name":"auctionBidDelta","type":"uint16"}
			]
		},
		{
			"name": "auctionBidDeltaDecimals",
			"inputs": [
			],
			"outputs": [
				{"name":"auctionBidDeltaDecimals","type":"uint16"}
			]
		}
	],
	"data": [
		{"key":1,"name":"nonce_","type":"uint64"}
	],
	"events": [
		{
			"name": "AuctionDeployed",
			"inputs": [
				{"name":"offer","type":"address"},
				{"components":[{"name":"collection","type":"address"},{"name":"nftOwner","type":"address"},{"name":"nft","type":"address"},{"name":"offer","type":"address"},{"name":"price","type":"uint128"},{"name":"auctionDuration","type":"uint128"},{"name":"deployNonce","type":"uint64"}],"name":"offerInfo","type":"tuple"}
			],
			"outputs": [
			]
		},
		{
			"name": "AuctionDeclined",
			"inputs": [
				{"name":"nftOwner","type":"address"},
				{"name":"nft","type":"address"}
			],
			"outputs": [
			]
		},
		{
			"name": "AuctionRootUpgrade",
			"inputs": [
			],
			"outputs": [
			]
		},
		{
			"name": "OwnershipTransferred",
			"inputs": [
				{"name":"oldOwner","type":"address"},
				{"name":"newOwner","type":"address"}
			],
			"outputs": [
			]
		}
	],
	"fields": [
		{"name":"_pubkey","type":"uint256"},
		{"name":"_timestamp","type":"uint64"},
		{"name":"_constructorFlag","type":"bool"},
		{"name":"owner_","type":"address"},
		{"name":"marketFee","type":"uint8"},
		{"name":"marketFeeDecimals","type":"uint8"},
		{"name":"deploymentFee","type":"uint128"},
		{"name":"deploymentFeePart","type":"uint128"},
		{"name":"codeNft","type":"cell"},
		{"name":"offerCode","type":"cell"},
		{"name":"nonce_","type":"uint64"},
		{"name":"auctionBidDelta","type":"uint16"},
		{"name":"auctionBidDeltaDecimals","type":"uint16"},
		{"name":"currentVersion","type":"uint32"},
		{"name":"currentVersionOffer","type":"uint32"}
	]
} as const
