import { makeAutoObservable, runInAction } from 'mobx';
import { getTime, millisecondsToSeconds } from 'date-fns';
import BigNumber from 'bignumber.js';
import { createAuctionUseCase } from 'application/create-auction';
import { z } from 'zod';
import { nonEmptyOrZeroToken } from 'shared/lib/validation-utils';
import { tokenService } from 'services/token-service';
import { nftStore } from 'modules/nft';

export const auctionFormSchema = z.object({
  minBid: nonEmptyOrZeroToken,
  startTime: z.date(),
  endTime: z.union([z.date(), z.number()]).refine((date) => {
    return date > new Date();
  }, 'Invalid date'),
  selectDuration: z.string(),
});

export type AuctionFormSchema = z.infer<typeof auctionFormSchema>;
export class CreateAuctionForm {
  isLoading = false;
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  async startAuction(
    { minBid, startTime, endTime }: AuctionFormSchema,
    nftAddress: string,
  ) {
    this.isLoading = true;

    try {
      const token = tokenService.getToken(minBid.paymentToken);

      if (!token) {
        return;
      }

      const price = new BigNumber(minBid.value)
        .shiftedBy(token.decimals)
        .toFixed(0, BigNumber.ROUND_UP);

      const start = millisecondsToSeconds(getTime(startTime!));
      const end = millisecondsToSeconds(getTime(endTime!));
      // const start = millisecondsToSeconds(addMinutes(Date.now(), 1).getTime());
      // const s = millisecondsToSeconds(addMinutes(t, 2).getTime());
      // let durationTime = 60;

      const params = {
        startTime: start,
        durationTime: end - start,
        nftAddress: nftAddress,
        paymentToken: token.address,
        price,
      };

      await createAuctionUseCase(params);
      nftStore.fetchNftDetails(nftAddress);

      console.log('auction has started');
    } catch (error) {
      console.error(error);
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }
}

export const createAuctionForm = new CreateAuctionForm();
