import { makeAutoObservable, runInAction } from 'mobx';
import { Address } from 'api';

export type ExtractParams<Request> = Request extends (
  params: infer Params,
  options: infer Options,
) => Promise<any>
  ? { params: Params; options?: Options }
  : never;

export class ListConstructor<
  Request extends (params: any, options?: any) => Promise<Data>,
  Params extends ExtractParams<Request>,
  Data extends { items: any[]; totalCount: number },
> {
  data = { items: [], totalCount: 0 } as Awaited<ReturnType<Request>>;
  isLoading = false;
  loaders = new Map<Address, boolean>();
  private request;

  constructor({ request }: { request: Request }) {
    this.request = request;

    makeAutoObservable(this, {}, { autoBind: true });
  }

  get isEmpty() {
    return this.data.items.length <= 0;
  }

  reset() {
    //@ts-expect-error
    this.data = { items: [], totalCount: 0 };
  }

  async getList(params: Params['params'], options?: Params['options']) {
    this.isLoading = true;
    const response = await this.request(params, options);

    runInAction(() => {
      const data = response as Awaited<ReturnType<Request>>;

      if (!options?.offset) {
        this.data = data;
      } else {
        this.data = { ...data, items: [...this.data.items, ...data.items] };
      }

      this.isLoading = false;
    });
  }
}
