import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { ActivityBid, Filter, Pagination } from 'shared/ui';
import {
  filterBidsIn,
  filterBidsOut,
  profileBidsIn,
  profileBidsOut,
} from '../model/bids';

const BIDS_LIMIT = 2;

export const BidsIn = observer(() => {
  const intl = useIntl();
  const { profileAddress } = useParams<'profileAddress'>();
  const [page, setPage] = useState(1);

  const handleChange = (page: number) => {
    profileBidsIn.getList(
      { ...filterBidsIn.filter, owner: profileAddress },
      {
        limit: BIDS_LIMIT,
        offset: (page - 1) * BIDS_LIMIT,
      },
    );
    setPage(page);
  };

  return (
    <>
      <div className="title mb-5">
        {intl.formatMessage({
          id: 'profile.bids_received',
          defaultMessage: 'Bids received',
        })}
      </div>

      <div className="mb-5">
        <Filter
          onClearFilter={() => filterBidsIn.clear({ refetch: true })}
          filterCollectionsStore={filterBidsIn.filterCollectionsStore}
        />
      </div>

      <div className="mb-5">
        <BidsInList />
      </div>

      {!profileBidsIn.isEmpty && (
        <div className="grid justify-end">
          <Pagination
            page={page}
            total={Math.ceil(profileBidsIn.data.totalCount / BIDS_LIMIT)}
            onChange={handleChange}
          />
        </div>
      )}
    </>
  );
});
const BidsInList = observer(() => {
  const { profileAddress } = useParams<'profileAddress'>();

  useEffect(() => {
    profileBidsIn.getList(
      { owner: profileAddress },
      {
        limit: BIDS_LIMIT,
      },
    );

    return () => filterBidsIn.clear();
  }, [profileAddress]);
  if (profileBidsIn.isLoading) {
    return <div className="loader"></div>;
  }
  if (profileBidsIn.isEmpty) {
    return <EmptyBidsIn />;
  }

  return (
    <>
      {profileBidsIn.data.items.map(
        (
          {
            collectionName,
            createdAt,
            isCollectionVerified,
            nftImage,
            nftName,
            from,
            bid,
            active,
            nftAddress,
            auctionAddress,
            nftType,
            saleType,
          },
          index,
        ) => (
          <ActivityBid
            auctionAddress={auctionAddress}
            direction="in"
            nftType={nftType}
            saleType={saleType}
            nftAddress={nftAddress}
            key={index}
            collectionName={collectionName}
            nftImage={nftImage}
            nftName={nftName}
            bid={bid}
            isCollectionVerified={isCollectionVerified}
            from={from}
            createdAt={createdAt}
            active={active}
            onProfile
          />
        ),
      )}
    </>
  );
});
export const BidsOut = observer(() => {
  const intl = useIntl();
  const { profileAddress } = useParams<'profileAddress'>();
  const [page, setPage] = useState(1);

  const handleChange = (page: number) => {
    profileBidsOut.getList(
      { ...filterBidsOut.filter, owner: profileAddress },
      {
        limit: BIDS_LIMIT,
        offset: (page - 1) * BIDS_LIMIT,
      },
    );
    setPage(page);
  };

  return (
    <>
      <div className="title mb-5">
        {intl.formatMessage({
          id: 'profile.bids_made',
          defaultMessage: 'Bids made',
        })}
      </div>

      <div className="mb-5">
        <Filter
          onClearFilter={() => filterBidsOut.clear({ refetch: true })}
          filterCollectionsStore={filterBidsOut.filterCollectionsStore}
        />
      </div>

      <div className="mb-5">
        <BidsOutList />
      </div>

      {!profileBidsOut.isEmpty && (
        <div className="grid justify-end">
          <Pagination
            page={page}
            total={Math.ceil(profileBidsOut.data.totalCount / BIDS_LIMIT)}
            onChange={handleChange}
          />
        </div>
      )}
    </>
  );
});

const BidsOutList = observer(() => {
  const { profileAddress } = useParams<'profileAddress'>();

  useEffect(() => {
    profileBidsOut.getList(
      { owner: profileAddress },
      {
        limit: BIDS_LIMIT,
      },
    );

    return () => filterBidsOut.clear();
  }, [profileAddress]);
  if (profileBidsOut.isLoading) {
    return <div className="loader"></div>;
  }

  if (profileBidsOut.isEmpty) {
    return <EmptyBidsOut />;
  }

  return (
    <>
      {profileBidsOut.data.items.map(
        (
          {
            collectionName,
            createdAt,
            isCollectionVerified,
            nftImage,
            nftName,
            from,
            bid,
            active,
            nftAddress,
            auctionAddress,
            nftType,
            saleType,
          },
          index,
        ) => (
          <ActivityBid
            auctionAddress={auctionAddress}
            nftType={nftType}
            saleType={saleType}
            nftAddress={nftAddress}
            key={index}
            collectionName={collectionName}
            nftImage={nftImage}
            nftName={nftName}
            bid={bid}
            isCollectionVerified={isCollectionVerified}
            from={from}
            createdAt={createdAt}
            direction="out"
            onProfile
            active={active}
          />
        ),
      )}
    </>
  );
});

const EmptyBidsIn = () => {
  const intl = useIntl();

  return (
    <div className="title text-center py-5">
      {intl.formatMessage({
        id: 'profile.empty_bids_in',
        defaultMessage: 'No bids received',
      })}
    </div>
  );
};
const EmptyBidsOut = () => {
  const intl = useIntl();
  return (
    <div className="title text-center py-5">
      {intl.formatMessage({
        id: 'profile.empty_bids_out',
        defaultMessage: 'You are not participating in any auction',
      })}
    </div>
  );
};
