import { formatDigits } from '@broxus/js-utils';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { nftCollections } from 'modules/nft';

import { AccountWithAddress, Image } from 'shared/ui';

const ITEMS_PER_PAGE = 28;

export const ExploreCollectionList = observer(() => {
  const intl = useIntl();

  useEffect(() => {
    nftCollections.collections.items.length <= 0 &&
      nftCollections.getCollections({
        options: { limit: ITEMS_PER_PAGE },
      });

    return () => nftCollections.reset();
  }, []);

  const {
    collections: { items, totalCount },
  } = nftCollections;
  const handleLoadMore = () => {
    nftCollections.getCollections({
      filter: { verified: true },
      options: { limit: ITEMS_PER_PAGE, offset: items.length },
    });
  };

  return (
    <>
      {nftCollections.isLoading && <div className="loader"></div>}
      <div className="responsive-grid mb-5">
        {items.map(
          (
            { name, logo, owner, address, wallpaper, ownersCount, nftCount },
            index,
          ) => {
            return (
              <Link
                className="collection-item"
                key={index}
                to={`/collections/${address}`}
              >
                <div className="relative">
                  <div className="wallpaper">
                    <Image className="img" src={wallpaper ?? logo} />
                  </div>
                  <div className="logo">
                    <Image className="img" src={logo} />
                  </div>
                </div>
                <div className="text-container">
                  <div className="text-black text-lg font-medium">{name}</div>
                  <div className="grid grid-flow-col justify-center gap-x-2">
                    {owner && (
                      <>
                        {intl.formatMessage({
                          id: 'text.by',
                          defaultMessage: 'By',
                        })}
                        <AccountWithAddress
                          address={owner}
                          size="small"
                          href={`/profile/${owner}`}
                          asText
                        />
                      </>
                    )}
                  </div>
                </div>
                <div className="p-3 mt-auto">
                  <div className="info">
                    <div>
                      <div className="info-title">
                        {intl.formatMessage({
                          id: 'table.column.items',
                          defaultMessage: 'Items',
                        })}
                      </div>
                      <div className="info-label">{formatDigits(nftCount)}</div>
                    </div>
                    <div>
                      <div className="info-title">
                        {intl.formatMessage({
                          id: 'table.column.owners',
                          defaultMessage: 'Owners',
                        })}
                      </div>
                      <div className="info-label">
                        {formatDigits(ownersCount)}
                      </div>
                    </div>
                    {/* <div>
                    <div className="info-title">Floor</div>
                    <div className="info-label">{formatDigits('30000')}</div>
                  </div> */}
                  </div>
                </div>
              </Link>
            );
          },
        )}
      </div>
      {items.length < totalCount && (
        <button
          className={`btn explore-link ${
            nftCollections.isLoading && 'loading'
          }`}
          disabled={nftCollections.isLoading}
          onClick={handleLoadMore}
        >
          {intl.formatMessage({
            id: 'button.load_more_collections',
            defaultMessage: 'Load more collections',
          })}
        </button>
      )}
    </>
  );
});
