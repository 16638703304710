import { Layout } from './index';
import { useState } from 'react';
import Sidebar from './sidebar';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

export const NotFound = () => {
  const [sidebarHidden, setSidebarHidden] = useState(true);
  const intl = useIntl();

  return (
    <>
      <Layout
        onMenu={() => setSidebarHidden(false)}
        className={'flex flex-col h-screen justify-between'}
      >
        <div className="d-flex flex-column align-items-center justify-content-center h-1/2 text-center mt-5 px-4">
          <div className="text-5xl " style={{ fontFamily: 'Faktum-SemiBold' }}>
            404
          </div>
          <div
            className="text-5xl mb-5"
            style={{ fontFamily: 'Faktum-SemiBold' }}
          >
            {intl.formatMessage({
              id: 'not.found',
              defaultMessage: 'Page not found',
            })}
          </div>
          <Link to={'/'}>
            <button className="btn primary mx-auto " data-color="blue">
              {intl.formatMessage({
                id: 'button.explore_nfts',
                defaultMessage: 'Explore NFTs',
              })}
            </button>
          </Link>
        </div>
      </Layout>

      <Sidebar
        mobile={true}
        hidden={sidebarHidden}
        onHide={() => setSidebarHidden(true)}
      />
    </>
  );
};
