import { DirectBuy } from 'api';
import { Offer } from '../types';
export function getLastOfferByUser(offers: Offer[]) {
  if (offers.length <= 0) {
    return;
  }

  return offers.reduce((prev, cur) =>
    Number(prev.createdAt) < Number(cur.createdAt) ? prev : cur,
  );
}
export function getTopOffer(offers: Offer[]) {
  if (offers.length <= 0) {
    return;
  }

  return offers.reduce((prev, cur) =>
    Number(prev.price.price) > Number(cur.price.price) ? prev : cur,
  );
}

export function nftPageOfferMapper({
  buyer,
  createdAt,
  nft,
  price,
  expiredAt,
  finishedAt,
  address,
}: DirectBuy) {
  return {
    buyer,
    nftAddress: nft,
    offerAddress: address,
    price,
    createdAt: createdAt!,
    expiredAt,
    finishedAt,
  };
}
