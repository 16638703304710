import { observer } from 'mobx-react-lite';
import { useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { createAuctionForm } from 'features/auction';
import { makeOfferForm } from 'features/direct-buy';
import { directSellForm } from 'features/direct-sell';
import { ModalIdx } from 'shared/config';
import { isDomain } from 'shared/lib/is';
import { isOwner } from 'shared/lib/is-owner';
import { wallet } from 'shared/lib/wallet';
import { TNft } from 'shared/types';
import { ModalContext, OfferCard } from 'shared/ui';
import { getLastOfferByUser } from 'shared/lib/offers';
import { DirectBuyStatus } from 'api';

export const NotForSellForm = observer(({ nft }: { nft: TNft }) => {
  const { handleModal } = useContext(ModalContext);
  const intl = useIntl();

  if (nft.sellingShape?.sellingMode !== 'NOT_FOR_SALE') {
    return null;
  }
  const {
    owner,
    type,
    sellingShape: { topOffer, offers, domainControls },
  } = nft;

  const myOffers = useMemo(
    () =>
      offers.items.filter(
        (item) =>
          item.buyer === wallet.address &&
          item.status === DirectBuyStatus.Active,
      ),
    [offers, wallet.isReady],
  );

  const lastUserOffer = useMemo(() => getLastOfferByUser(myOffers), [myOffers]);
  if (isDomain(type) && !domainControls) {
    return (
      <>
        <div className="mb-4">
          <div className="title">
            {intl.formatMessage({
              id: 'text.not_for_sale',
              defaultMessage: 'Not for sale',
            })}
          </div>
        </div>

        {wallet.isReady && lastUserOffer && (
          <div
            className={`grid ${
              lastUserOffer && 'grid-cols-2'
            } items-center gap-x-2 mb-4`}
          >
            <OfferCard
              price={lastUserOffer.price}
              label={intl.formatMessage({
                id: 'text.my_offer',
                defaultMessage: 'My offer',
              })}
              myOffers={myOffers.length - 1}
            />
          </div>
        )}
      </>
    );
  }

  return (
    <>
      {/* <div className="grid grid-flow-col justify-between item-center gap-x-3 mb-4">
        <div>Current price</div>
      </div> */}
      <div className="mb-4">
        <div className="title">
          {intl.formatMessage({
            id: 'text.not_for_sale',
            defaultMessage: 'Not for sale',
          })}
        </div>
      </div>

      {wallet.isReady && lastUserOffer && (
        <div
          className={`grid ${
            lastUserOffer && 'grid-cols-2'
          } items-center gap-x-2 mb-4`}
        >
          <OfferCard
            price={lastUserOffer.price}
            label={intl.formatMessage({
              id: 'text.my_offer',
              defaultMessage: 'My offer',
            })}
            myOffers={myOffers.length - 1}
          />
        </div>
      )}

      {wallet.isConnected && isOwner(owner) ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-y-2 sm:gap-y-0 sm:gap-x-2">
          <button
            className={`btn primary ${directSellForm.isLoading && 'loading'}`}
            disabled={createAuctionForm.isLoading || directSellForm.isLoading}
            data-color="blue"
            onClick={() =>
              handleModal({ id: ModalIdx.START_SALE_MODAL, content: nft })
            }
          >
            {intl.formatMessage({
              id: 'button.put_on_sale',
              defaultMessage: 'Put on sale',
            })}
          </button>
          <button
            className={`btn secondary ${
              createAuctionForm.isLoading && 'loading'
            }`}
            disabled={createAuctionForm.isLoading || directSellForm.isLoading}
            data-color="blue"
            onClick={() =>
              handleModal({ id: ModalIdx.START_AUCTION_MODAL, content: nft })
            }
          >
            {intl.formatMessage({
              id: 'button.start_auction',
              defaultMessage: 'Start auction',
            })}
          </button>
        </div>
      ) : wallet.isConnected && !isOwner(owner) ? (
        <div className="grid grid-cols-2 gap-x-2">
          <button
            className={`btn primary ${makeOfferForm.isLoading && 'loading'}`}
            disabled={makeOfferForm.isLoading}
            data-color="blue"
            onClick={() =>
              handleModal({ id: ModalIdx.MAKE_OFFER_MODAL, content: nft })
            }
          >
            {intl.formatMessage({
              id: 'button.make_an_offer',
              defaultMessage: 'Make an offer',
            })}
          </button>
          {/* <button className="btn secondary" data-color="blue">
            Share
          </button> */}
        </div>
      ) : (
        <div className="grid grid-cols-2 gap-x-2">
          <button
            className="btn primary"
            data-color="blue"
            onClick={() => handleModal({ id: ModalIdx.CONNECT_WALLET_MODAL })}
          >
            {intl.formatMessage({
              id: 'button.connect_wallet',
              defaultMessage: 'Connect wallet',
            })}
          </button>
          {/* <button className="btn secondary" data-color="blue">
            Share
          </button> */}
        </div>
      )}
    </>
  );
});
