import { useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import CopyIcon from 'assets/icons/copy-icon.svg';

type Props = {
  text: string;
};

export function CopyToClipboard({ text }: Props): JSX.Element | null {
  const intl = useIntl();
  const [success, setSuccess] = useState(false);
  const containerRef = useRef<HTMLSpanElement | null>(null);
  if (!navigator || !navigator.clipboard) {
    return null;
  }

  const copy = async () => {
    await navigator.clipboard.writeText(text);
    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
    }, 2000);
  };

  return (
    <>
      <div
        className="tooltip"
        data-tip={
          success
            ? intl.formatMessage({
                id: 'copy_clipboard_success',
                defaultMessage: 'Copied',
              })
            : intl.formatMessage({
                id: 'copy_clipboard_default',
                defaultMessage: 'Copy',
              })
        }
      >
        <span
          className={`copy-to-clipboard ${success && 'tooltip-open'}`}
          onClick={copy}
          ref={containerRef}
        >
          <img
            src={CopyIcon}
            className="w-4 h-4 hover:cursor-pointer"
            onClick={copy}
          />
        </span>
      </div>
      {/* <button className="btn">Force open</button> */}
      {/* {success && (
        <Tooltip
          forceShow
          target={containerRef}
          alignX="center"
          alignY="top"
          size="small"
        >
          {intl.formatMessage({
            id: 'COPY_CLIPBOARD_SUCCESS',
          })}
        </Tooltip>
      )} */}
    </>
  );
}
