import { tokenListUrl, tokenRootContract } from '../config';
import { toAddress } from '../lib/utils';
import { TToken } from '../types';

class TokenRepository {
  tokens = new Map<string, TToken>();

  async getTokens() {
    if (this.tokens.size <= 0) {
      const tokens = await this.fetchTokens(tokenListUrl);
      this.tokens = new Map(tokens.map((token) => [token.address, token]));
    }
    return this.tokens;
  }
  async getToken(tokenAddress: string): Promise<TToken> {
    if (!this.tokens.has(tokenAddress)) {
      const token = await this.fetchToken(tokenAddress);
      this.tokens.set(tokenAddress, token);
    }

    return this.tokens.get(tokenAddress) as TToken;
  }
  private async fetchTokens(url: string) {
    const tokenListJson = await fetch(url);
    const { tokens }: { tokens: TToken[] } = await tokenListJson.json();

    return tokens;
  }

  private async fetchToken(tokenAddress: string): Promise<TToken> {
    const contract = tokenRootContract(toAddress(tokenAddress));
    const { value0: decimals } = await contract.methods
      .decimals({ answerId: 0 })
      .call();
    const { value0: symbol } = await contract.methods
      .symbol({ answerId: 0 })
      .call();
    const { value0: name } = await contract.methods
      .name({ answerId: 0 })
      .call();

    return {
      logoURI: '',
      address: tokenAddress,
      name,
      symbol,
      decimals: parseInt(decimals, 10),
    };
  }
}

export const tokenRepository = new TokenRepository();
