import { apiClient, EventsBody, Event, Address } from 'api';
import { PaginationOptions } from 'shared/types';

enum OfferStatus {
  Create = 0,
  AwaitTokens = 1,
  Active = 2,
  Filled = 3,
  Cancelled = 4,
  Expired = 5,
}

enum DirectSellStatus {
  Create = 0,
  AwaitNFT = 1,
  Active = 2,
  Filled = 3,
  Cancelled = 4,
  Expired = 5,
}

type ActivityOffer = {
  type: 'DirectBuyStateChanged';
  category: 'DirectBuy';
  nftImage: string;
  nftName: string;
  nftAddress: Address;
  collectionName: string;
  collectionAddress: Address;
  status: keyof typeof OfferStatus;
  token: Address;
  price: string;
  usdPrice: string;
  createdAt: number;
  createdBy: Address;
  acceptedBy: Address;
  cancelledBy: Address;
  timestamp: number;
};

type ActivityAuctionDeployed = {
  type: 'AuctionDeployed';
  category: 'Auction';
  nftImage: string;
  nftName: string;
  nftAddress: Address;
  collectionName: string;
  collectionAddress: Address;
  startBid: string;
  createdBy: Address;
  favorOf: Address;
  token: Address;
  createdAt: Address;
  duration: number;
  timestamp: number;
  usdPrice: string;
};
type ActivityAuctionCancelled = {
  type: 'AuctionCancelled';
  category: 'Auction';
  nftImage: string;
  nftName: string;
  nftAddress: Address;
  collectionName: string;
  collectionAddress: Address;
  startBid: string;
  maxBid: string;
  createdBy: Address;
  favorOf: Address;
  token: Address;
  createdAt: Address;
  duration: number;
  timestamp: number;
  usdPrice: string;
};
type ActivityAuctionComplete = {
  type: 'AuctionComplete';
  category: 'Auction';
  nftImage: string;
  nftName: string;
  nftAddress: Address;
  collectionName: string;
  collectionAddress: Address;
  startBid: string;
  maxBid: string;
  createdBy: Address;
  favorOf: Address;
  token: Address;
  createdAt: Address;
  duration: number;
  timestamp: number;
  usdPrice: string;
};

type ActivityAuctionBid = {
  type: 'AuctionBidPlaced';
  category: 'Auction';
  nftImage: string;
  nftName: string;
  nftAddress: Address;
  collectionName: string;
  collectionAddress: Address;
  startBid: string;
  maxBid: string;
  prevBid: string;
  token: Address;
  usdPrice: string;
  createdBy: Address;
  createdAt: Address;
  timestamp: number;
  active: boolean;
  auctionAddress: Address;
};

type ActivityTransfer = {
  type: 'NftOwnerChanged';
  category: 'Nft';
  nftImage: string;
  nftName: string;
  nftAddress: Address;
  collectionName: string;
  collectionAddress: Address;
  from: Address;
  to: Address;
  timestamp: number;
};

type ActivityMinted = {
  type: 'NftCreated';
  category: 'Collection';
  nftImage: string;
  nftName: string;
  nftAddress: Address;
  collectionName: string;
  collectionAddress: Address;
  createdBy: Address;
  createdFor: Address;
  timestamp: number;
};

type ActivityPurchase = {
  type: 'DirectSellStateChanged';
  category: 'DirectSell';
  nftImage: string;
  nftName: string;
  nftAddress: Address;
  collectionName: string;
  collectionAddress: Address;
  createdBy: Address; // put on sale or cancel sale events
  token: Address;
  price: string;
  usdPrice: string;
  createdAt: number; // put on sale or cancel sale events
  startAt: number;
  expiredAt?: number;
  timestamp: number;
  status: keyof typeof DirectSellStatus;
};

export async function getActivityUseCase(
  params: Omit<EventsBody, keyof PaginationOptions>,
  options?: PaginationOptions,
) {
  try {
    const { items, totalCount } = await apiClient.listEvents({
      ...params,
      ...options,
    });

    return { items, totalCount } as {
      items: Event[];
      totalCount: number;
    };
  } catch (error) {
    console.error(error);

    return { items: [], totalCount: 0 };
  }
}
