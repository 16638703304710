import { collectionRepository } from 'shared/repository';

import { secondsToMilliseconds } from 'date-fns';
import { Collection } from 'api';

export async function getCollectionUseCase(
  collectionAddress: string,
): Promise<Collection | null> {
  try {
    const collectionData = await collectionRepository.fetchCollection(
      collectionAddress,
    );

    return {
      ...collectionData,

      createdAt: secondsToMilliseconds(collectionData.createdAt),
    };
  } catch (error) {
    console.error(error);

    return null;
  }
}
