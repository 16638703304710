import { Tab } from '@headlessui/react';
import { observer } from 'mobx-react-lite';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';

import { ExploreCollectionList, ExploreNftList } from 'modules/explorer';

import { Layout } from 'widgets/layout';
import Sidebar from 'widgets/layout/sidebar';

export const Explorer = observer(() => {
  const intl = useIntl();
  const [sidebarHidden, setSidebarHidden] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams('?tab=collections');
  const tabs = useMemo(
    () => ({
      collections: intl.formatMessage({
        id: 'tab.collections',
        defaultMessage: 'Collections',
      }),
      items: intl.formatMessage({ id: 'tab.items', defaultMessage: 'Items' }),
    }),
    [],
  );
  const selectedIndex = useMemo(
    () => Object.keys(tabs).indexOf(searchParams.get('tab') ?? 'collections'),
    [searchParams.get('tab')],
  );
  const handleTab = (index: number) =>
    setSearchParams(
      {
        tab: Object.keys(tabs)[index],
      },
      { replace: true },
    );

  return (
    <>
      <Layout onMenu={() => setSidebarHidden(false)}>
        <div className="container">
          <div className="text-black font-bold text-4xl mb-10">
            {intl.formatMessage({
              id: 'text.explore_nfts',
              defaultMessage: 'Explore NFTs',
            })}
          </div>

          <Tab.Group onChange={handleTab} selectedIndex={selectedIndex}>
            <Tab.List className="grid grid-flow-col justify-start mb-10">
              {/*TODO: Add Activities in future */}
              {Object.keys(tabs).map((category) => (
                <Tab
                  className={({ selected }) =>
                    `tab-btn ${selected ? 'active' : ''}`
                  }
                  key={category}
                >
                  {tabs[category]}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels className="mt-2">
              <Tab.Panel>
                <ExploreCollectionList />
              </Tab.Panel>
              <Tab.Panel>
                <ExploreNftList />
              </Tab.Panel>
              {/* <Tab.Panel>baz</Tab.Panel> */}
            </Tab.Panels>
          </Tab.Group>
        </div>
      </Layout>
      <Sidebar
        mobile={true}
        hidden={sidebarHidden}
        onHide={() => setSidebarHidden(true)}
      />
    </>
  );
});
