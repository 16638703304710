import { ChangeEvent } from 'react';
import { makeAutoObservable, runInAction } from 'mobx';
import { Collection } from 'api';
import { getCollectionListUseCase } from 'application/get-collection-list';
import { CollectionListFilter, CollectionListOptions } from 'shared/repository';
import { CreateFilterStore } from 'shared/lib/create-filter';

export type SaleType = 'auction' | 'forsale';
export class FilterSaleTypeStore {
  filterStore: CreateFilterStore;
  saleType = new Map<string, boolean>();
  default = { forsale: null, auction: null };
  constructor(filterStore: CreateFilterStore) {
    this.filterStore = filterStore;
    makeAutoObservable(this, {}, { autoBind: true });
  }
  reset(options?: { refetch?: boolean }) {
    this.saleType.clear();
    if (options?.refetch) {
      this.filterStore.filterChange({});
    }
  }
  selectSaleType({ isChecked, type }: { isChecked: boolean; type: SaleType }) {
    if (isChecked) {
      this.saleType.set(type, true);
    } else {
      this.saleType.delete(type);
    }

    this.filterStore.filterChange({
      [type]: this.saleType.get(type),
    });
  }
}
export class FilterCollectionsStore {
  filterStore: CreateFilterStore;
  collections: { items: Collection[]; totalCount: number } = {
    items: [],
    totalCount: 0,
  };
  selectedCollections = new Set<string>();
  input = '';
  isLoading = false;

  constructor(filterStore: CreateFilterStore) {
    this.filterStore = filterStore;
    makeAutoObservable(this, {}, { autoBind: true });
  }

  reset(options?: { refetch?: boolean }) {
    this.clear();

    if (options?.refetch) {
      this.filterStore.filterChange({});
    }
  }
  persistReset() {
    this.collections = { items: [], totalCount: 0 };
    this.input = '';
  }

  clear() {
    this.input = '';
    this.selectedCollections.clear();
  }

  onChangeInput(event: ChangeEvent<HTMLInputElement>) {
    this.input = event.currentTarget.value;
  }
  selectCollection(event: ChangeEvent<HTMLInputElement>) {
    const isChecked = event.target.checked;
    const value = event.target.value;

    if (isChecked && !this.selectedCollections.has(value)) {
      this.selectedCollections.add(value);
    } else {
      this.selectedCollections.delete(value);
    }
  }
  async getCollectionList({
    filter,
    options,
  }: {
    filter?: CollectionListFilter;
    options?: CollectionListOptions;
  }) {
    this.isLoading = true;

    if (this.filterStore.filter && 'verified' in this.filterStore.filter) {
      const collections = await getCollectionListUseCase({
        filter: { ...filter, verified: this.filterStore.filter.verified },
        options,
      });
      runInAction(() => {
        this.collections = collections;
        this.isLoading = false;
      });
    } else {
      const collections = await getCollectionListUseCase({
        filter,
        options,
      });
      runInAction(() => {
        this.collections = collections;
        this.isLoading = false;
      });
    }
  }

  submit() {
    this.filterStore.filterChange({
      collections: [...this.selectedCollections],
    });
    this.persistReset();
  }
}
