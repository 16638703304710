import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { PriceRendererCard } from './atoms';
import { SellingTimer } from './molecules';
import { TNft } from 'shared/types';
import { isOwner } from 'shared/lib/is-owner';
import { useIntl } from 'react-intl';
import { Image, ModalContext } from 'shared/ui';
import { isGoodNumber } from '@broxus/js-utils';
import { ModalIdx } from 'shared/config';
import { wallet } from 'shared/lib/wallet';
import { isDomain } from 'shared/lib/is';
import { useContext } from 'react';

export const NftCard = observer(({ nft }: { nft: TNft }) => {
  const sellingMode = nft.sellingShape?.sellingMode;

  if (nft.sellingShape && sellingMode === 'DIRECT_SELL') {
    return <DirectSell nft={nft} />;
  }

  if (nft.sellingShape && sellingMode === 'AUCTION') {
    return <Auction nft={nft} />;
  }

  return <NotForSale nft={nft} />;
});

const DirectSell = observer(({ nft }: { nft: TNft }) => {
  const { handleModal } = useContext(ModalContext);
  const intl = useIntl();
  if (nft.sellingShape?.sellingMode !== 'DIRECT_SELL') {
    return null;
  }

  const {
    name,
    address,
    manager,
    image,
    owner,
    mimetype,

    collection: { name: collectionName },
    sellingShape: { timer, price, expiredAt, createdAt, topOffer, status },
  } = nft;

  if (!timer) {
    return null;
  }

  return (
    <div className="nft-card">
      <div className="mb-4 relative overflow-hidden">
        {wallet.isConnected && !isOwner(owner) && (
          <div className="absolute inset-0 nft-card-overlay ">
            <div>
              {/* <div>reload</div>
          <div>like</div> */}
            </div>
            <div className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] grid gap-y-2">
              {timer?.isSellActive && (
                <button
                  className="btn primary "
                  data-color="blue"
                  onClick={() =>
                    handleModal({ id: ModalIdx.BUY_NOW_MODAL, content: nft })
                  }
                >
                  {intl.formatMessage({
                    id: 'button.buy_now',
                    defaultMessage: 'Buy now',
                  })}
                </button>
              )}
              <button
                className="btn primary "
                data-color="blue"
                onClick={() =>
                  handleModal({ id: ModalIdx.MAKE_OFFER_MODAL, content: nft })
                }
              >
                {intl.formatMessage({
                  id: 'button.make_an_offer',
                  defaultMessage: 'Make an offer',
                })}
              </button>
            </div>
          </div>
        )}

        {timer?.isActive && timer.isSellActive && timer.endTime > 0 && (
          <div className="absolute bottom-2 inset-x-0 px-2 z-10">
            <div className="auction-timer text-white">
              {intl.formatMessage({
                id: 'text.sale_ends_in',
                defaultMessage: 'Sale ends in',
              })}

              <SellingTimer
                timer={timer}
                startTime={createdAt}
                endTime={expiredAt ?? 0}
              />
            </div>
          </div>
        )}
        {timer?.isActive && !timer.isSellActive && (
          <div className="absolute bottom-2 inset-x-0 px-2 z-10">
            <div className="auction-timer text-white">
              {intl.formatMessage({
                id: 'text.sale_starts_in',
                defaultMessage: 'Sale starts in',
              })}
              <SellingTimer
                timer={timer}
                startTime={createdAt}
                endTime={expiredAt ?? 0}
              />
            </div>
          </div>
        )}
        {!timer?.isSellActive &&
          timer?.endTime > 0 &&
          Date.now() > timer.endTime && (
            <div className="absolute bottom-2 inset-x-0 px-2 z-10">
              <div className="auction-timer text-white">
                {intl.formatMessage({
                  id: 'text.sale_ended',
                  defaultMessage: 'Sale ended',
                })}
              </div>
            </div>
          )}

        <div className="image ">
          <Image
            mimetype={mimetype}
            src={image ?? 'http://'}
            className=" h-full max-w-none"
          />
        </div>
      </div>
      <div>
        <div className="mb-4 grid grid-flow-col justify-between items-center">
          <div>
            <div>{collectionName}</div>
            <div className="font-medium text-black text-base">
              <Link to={`/nft/${address}`}>{name}</Link>
            </div>
          </div>

          {/* <Menu>
            <Menu.Button className="dropdown-btn">
              <DotsVerticalIcon className="w-5 h-5 fill-grey-600 hover:cursor-pointer" />
            </Menu.Button>
            <Menu.Items as="ul" className="dropdown-list w-[240px] ">
              <Menu.Item className="dropdown-item" as="li">
                {intl.formatMessage({
                  id: 'menu.item.report',
                  defaultMessage: 'Report',
                })}
              </Menu.Item>
              <Menu.Item className="dropdown-item" as="li">
                {intl.formatMessage({
                  id: 'button.share',
                  defaultMessage: 'Share',
                })}
              </Menu.Item>
            </Menu.Items>
          </Menu> */}
        </div>
        <div className="grid grid-cols-2 items-center gap-x-2">
          <div className="bg-grey-950 p-2">
            <div className="text-xs">
              {intl.formatMessage({
                id: 'text.price',
                defaultMessage: 'Price',
              })}
            </div>
            <div className="text-black font-bold">
              <PriceRendererCard
                disableIcon
                token={price.priceToken}
                price={price.price}
              />
            </div>
          </div>
          {/* {topOffer && (
            <div className="bg-grey-950 p-2">
              <div className="text-xs">
                {intl.formatMessage({
                  id: 'text.highest_offer',
                  defaultMessage: 'Highest offer',
                })}
              </div>
              <div className="text-black font-bold">
                <PriceRendererCard
                  disableIcon
                  token={topOffer.price.priceToken}
                  price={topOffer.price.price}
                />
              </div>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
});
const Auction = observer(({ nft }: { nft: TNft }) => {
  const intl = useIntl();
  const { handleModal } = useContext(ModalContext);

  if (nft.sellingShape?.sellingMode !== 'AUCTION') {
    return null;
  }

  const {
    sellingShape: {
      bidToken,

      maxBid,
      startBid,
      createdAt,
      finishedAt,
      timer,
      offers,
    },
    collection: { name: collectionName },
    name,
    owner,
    manager,
    description,
    image,
    mimetype,
    address,
  } = nft;

  return (
    <div className="nft-card">
      <div className="mb-4 relative overflow-hidden">
        {wallet.isConnected && !isOwner(owner) && (
          <div className="absolute inset-0 nft-card-overlay ">
            <div>
              {/* <div>reload</div>
          <div>like</div> */}
            </div>
            <div className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]">
              {timer?.isSellActive && (
                <button
                  className="btn primary "
                  data-color="blue"
                  onClick={() =>
                    handleModal({ id: ModalIdx.MAKE_BID_MODAL, content: nft })
                  }
                >
                  {intl.formatMessage({
                    id: 'button.make_a_bid',
                    defaultMessage: 'Place a bid',
                  })}
                </button>
              )}
            </div>
          </div>
        )}

        {timer.isActive && (
          <div className="absolute bottom-2 inset-x-0 px-2 z-10">
            <div className="auction-timer">
              {timer.isSellActive
                ? intl.formatMessage({
                    id: 'text.auction_ends_in',
                    defaultMessage: 'Auction ends in',
                  })
                : intl.formatMessage({
                    id: 'text.auction_starts_in',
                    defaultMessage: 'Auction starts in',
                  })}
              <SellingTimer
                timer={timer}
                startTime={createdAt}
                endTime={finishedAt}
              />
            </div>
          </div>
        )}
        {!timer.isSellActive && Date.now() > timer.endTime && (
          <div className="absolute bottom-2 inset-x-0 px-2 z-10">
            <div className="auction-timer">
              {intl.formatMessage({
                id: 'text.auction_finished',
                defaultMessage: 'Auction finished',
              })}
            </div>
          </div>
        )}

        <div className="image ">
          <Image
            mimetype={mimetype}
            src={image ?? 'http://'}
            className=" h-full max-w-none"
          />
        </div>
      </div>
      <div>
        <div className="mb-4 grid grid-flow-col justify-between items-center">
          <div>
            <div>{collectionName}</div>
            <div className="font-medium text-black text-base ">
              <Link to={`/nft/${address}`}>{name}</Link>
            </div>
          </div>
          {/* <div className="relative">
            <Menu>
              <Menu.Button className="dropdown-btn">
                <DotsVerticalIcon className="w-5 h-5 fill-grey-600 hover:cursor-pointer" />
              </Menu.Button>
              <Menu.Items as="ul" className="dropdown-list w-[240px] ">
                <Menu.Item className="dropdown-item" as="li">
                  {intl.formatMessage({
                    id: 'menu.item.report',
                    defaultMessage: 'Report',
                  })}
                </Menu.Item>
                <Menu.Item className="dropdown-item" as="li">
                  {intl.formatMessage({
                    id: 'button.share',
                    defaultMessage: 'Share',
                  })}
                </Menu.Item>
              </Menu.Items>
            </Menu>
          </div> */}
        </div>
        <div className="grid grid-cols-2 items-center gap-x-2">
          <div className="bg-grey-950 p-2">
            <div className="text-xs">
              {intl.formatMessage({
                id: 'text.start_bid',
                defaultMessage: 'Start bid',
              })}
            </div>
            <div className="text-black font-bold">
              <PriceRendererCard
                disableIcon
                price={startBid!}
                token={bidToken}
              />
            </div>
          </div>
          {maxBid && isGoodNumber(maxBid) && (
            <div className="bg-grey-950 p-2">
              <div className="text-xs">
                {intl.formatMessage({
                  id: 'text.highest_bid',
                  defaultMessage: 'Highest bid',
                })}
              </div>
              <div className="text-black font-bold">
                <PriceRendererCard
                  disableIcon
                  price={maxBid}
                  token={bidToken}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
});
const NotForSale = observer(({ nft }: { nft: TNft }) => {
  const { handleModal } = useContext(ModalContext);
  const intl = useIntl();

  if (nft.sellingShape?.sellingMode !== 'NOT_FOR_SALE') {
    return null;
  }
  const {
    image,
    collection: { name: collectionName },
    manager,
    owner,
    name,
    address,
    mimetype,
    type,

    sellingShape: { topOffer, domainControls, offers },
  } = nft;

  if (isDomain(type) && !domainControls) {
    return (
      <div className="nft-card">
        <div className="mb-4 relative overflow-hidden">
          <div className="image ">
            <Image
              mimetype={mimetype}
              src={image ?? 'http://'}
              className=" h-full max-w-none"
            />
          </div>
        </div>
        <div>
          <div className="mb-4 grid grid-flow-col justify-between items-center">
            <div>
              <div>{collectionName}</div>
              <div className="font-medium text-black text-base ">
                <Link to={`/nft/${address}`}>{name}</Link>
              </div>
            </div>
            {/* <div className="relative">
              <Menu>
                <Menu.Button className="dropdown-btn">
                  <DotsVerticalIcon className="w-5 h-5 fill-grey-600 hover:cursor-pointer" />
                </Menu.Button>
                <Menu.Items as="ul" className="dropdown-list w-[240px] ">
                  <Menu.Item className="dropdown-item" as="li">
                    {intl.formatMessage({
                      id: 'menu.item.report',
                      defaultMessage: 'Report',
                    })}
                  </Menu.Item>
                  <Menu.Item className="dropdown-item" as="li">
                    {intl.formatMessage({
                      id: 'button.share',
                      defaultMessage: 'Share',
                    })}
                  </Menu.Item>
                </Menu.Items>
              </Menu>
            </div> */}
          </div>
          <div className="grid grid-cols-2 items-center gap-x-2">
            <div className="bg-grey-950 p-2">
              <div className="text-xs">
                {intl.formatMessage({
                  id: 'text.price',
                  defaultMessage: 'Price',
                })}
              </div>
              <div className="text-black font-bold">
                {intl.formatMessage({
                  id: 'text.not_for_sale',
                  defaultMessage: 'Not for sale',
                })}
              </div>
            </div>
            {/* {topOffer && (
              <div className="bg-grey-950 p-2">
                <div className="text-xs">
                  {intl.formatMessage({
                    id: 'text.highest_offer',
                    defaultMessage: 'Highest offer',
                  })}
                </div>
                <div className="text-black font-bold">
                  <PriceRendererCard
                    disableIcon
                    price={topOffer.price.price}
                    token={topOffer.price.priceToken}
                  />
                </div>
              </div>
            )} */}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="nft-card">
      <div className="mb-4 relative overflow-hidden">
        {wallet.isConnected && !isOwner(owner) && (
          <div className="absolute inset-0 nft-card-overlay ">
            <div>
              {/* <div>reload</div>
          <div>like</div> */}
            </div>
            <div className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]">
              <button
                className="btn primary "
                data-color="blue"
                onClick={() =>
                  handleModal({
                    id: ModalIdx.MAKE_OFFER_MODAL,
                    content: nft,
                  })
                }
              >
                {intl.formatMessage({
                  id: 'button.make_an_offer',
                  defaultMessage: 'Make an offer',
                })}
              </button>
            </div>
          </div>
        )}
        <div className="image ">
          <Image
            mimetype={mimetype}
            src={image ?? 'http://'}
            className=" h-full max-w-none"
          />
        </div>
      </div>
      <div>
        <div className="mb-4 grid grid-flow-col justify-between items-center">
          <div>
            <div>{collectionName}</div>
            <div className="font-medium text-black text-base ">
              <Link to={`/nft/${address}`}>{name}</Link>
            </div>
          </div>
          {/* <div className="relative">
            <Menu>
              <Menu.Button className="dropdown-btn">
                <DotsVerticalIcon className="w-5 h-5 fill-grey-600 hover:cursor-pointer" />
              </Menu.Button>
              <Menu.Items as="ul" className="dropdown-list w-[240px] ">
                <Menu.Item className="dropdown-item" as="li">
                  {intl.formatMessage({
                    id: 'menu.item.report',
                    defaultMessage: 'Report',
                  })}
                </Menu.Item>
                <Menu.Item className="dropdown-item" as="li">
                  {intl.formatMessage({
                    id: 'button.share',
                    defaultMessage: 'Share',
                  })}
                </Menu.Item>
              </Menu.Items>
            </Menu>
          </div> */}
        </div>
        <div className="grid grid-cols-2 items-center gap-x-2">
          <div className="bg-grey-950 p-2">
            <div className="text-xs">
              {intl.formatMessage({
                id: 'text.price',
                defaultMessage: 'Price',
              })}
            </div>
            <div className="text-black font-bold">
              {intl.formatMessage({
                id: 'text.not_for_sale',
                defaultMessage: 'Not for sale',
              })}
            </div>
          </div>
          {/* {topOffer && (
            <div className="bg-grey-950 p-2">
              <div className="text-xs">
                {intl.formatMessage({
                  id: 'text.highest_offer',
                  defaultMessage: 'Highest offer',
                })}
              </div>
              <div className="text-black font-bold">
                <PriceRendererCard
                  disableIcon
                  price={topOffer.price.price}
                  token={topOffer.price.priceToken}
                />
              </div>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
});
