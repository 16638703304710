import { Address, apiClient, Collection, CollectionsBody } from 'api';
export interface CollectionListOptions
  extends Omit<CollectionsBody, 'name' | 'verified' | 'owners'> {
  exclude?: string[];
}

export type CollectionListFilter = Omit<
  CollectionsBody,
  'exclude' | 'limit' | 'offset'
>;

class CollectionRepository {
  async fetchCollections({
    options = {},
    filter = {},
  }: {
    options?: CollectionListOptions;
    filter?: CollectionListFilter;
  }) {
    return apiClient.listCollections({
      ...options,
      ...filter,
    });
  }

  async fetchCollection(address: Address): Promise<Collection> {
    return apiClient.collectionDetailsPost({
      collection: address,
    });
  }
}

export const collectionRepository = new CollectionRepository();
