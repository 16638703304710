import { formatDigits } from '@broxus/js-utils';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { AccountWithAddress } from 'shared/ui';
import { collection } from '../model';

export const CollectionMeta = observer(() => {
  const intl = useIntl();

  if (collection.isLoading || !collection.data) {
    return <div className="loader"></div>;
  }
  const {
    data: {
      wallpaper,
      logo,
      name,
      ownersCount,
      createdAt,
      description,
      owner,
      nftCount,
    },
  } = collection;

  return (
    <div className=" mb-20">
      <div className="max-h-[280px] overflow-hidden ">
        <img src={wallpaper} className="w-max-none w-full " />
      </div>
      <div className="grid grid-cols-5 gap-x-4">
        <div className="text-center col-span-2">
          <div
            className={`${
              wallpaper && 'translate-y-[-50%]'
            } rounded-[50%] border-4 border-white overflow-hidden w-[120px] h-[120px] mx-auto`}
          >
            <img src={logo} className="w-max-none w-full " />
          </div>
          <div className="-mt-10">
            <div className="text-black font-bold text-3xl mb-2">{name}</div>
            {owner && (
              <div className="grid grid-flow-col justify-center gap-x-2 mb-3">
                {intl.formatMessage({
                  id: 'text.created_by',
                  defaultMessage: 'Created by',
                })}
                <AccountWithAddress
                  canCopy
                  address={owner}
                  size="small"
                  href={`/profile/${owner}`}
                />
              </div>
            )}
          </div>
        </div>
        <div className="col-span-3  pt-10">
          <div className="grid grid-cols-3 justify-left gap-x-4 gap-y-12 mb-12">
            <div>
              <div className="mb-1">
                {intl.formatMessage({
                  id: 'table.column.items',
                  defaultMessage: 'Items',
                })}
              </div>
              <div className="text-lg text-black font-medium">
                {formatDigits(nftCount)}
              </div>
            </div>
            <div>
              <div className="mb-1">
                {intl.formatMessage({
                  id: 'table.column.owners',
                  defaultMessage: 'Owners',
                })}
              </div>
              <div className="text-lg text-black font-medium">
                {formatDigits(ownersCount)}
              </div>
            </div>
            <div>
              <div className="mb-1">
                {intl.formatMessage({
                  id: 'table.column.created_date',
                  defaultMessage: 'Created date',
                })}
              </div>
              <div className="text-lg text-black font-medium">
                {format(createdAt, 'dd MMMM yyyy')}
              </div>
            </div>
            {/* <div>
          <div className="mb-1">
            {intl.formatMessage({
              id: 'table.column.floor_price',
              defaultMessage: 'Floor price',
            })}
          </div>
          <div className="text-lg text-black font-medium">
            {formatDigits('300000')}
          </div>
        </div>
        <div>
          <div className="mb-1">
            {intl.formatMessage({
              id: 'table.column.total_volume',
              defaultMessage: 'Total volume',
            })}
          </div>
          <div className="text-lg text-black font-medium">
            {formatDigits('300000')}
          </div>
        </div> */}
          </div>
          <div>{description}</div>
        </div>
      </div>
    </div>
  );
});
