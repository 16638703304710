import { Address } from 'api';
import { domainContract } from 'shared/config';
import { toAddress } from 'shared/lib/utils';
import { nftRepository } from 'shared/repository';
import { nftCreator } from './helpers/nft-creators';

export async function getNftInteractor(nftAddress: Address) {
  const data = await nftRepository.fetchNft(nftAddress);

  if (!data || !data.collection || !data.nft) {
    return null;
  }

  let domainControls = false;
  if (data.nft.type === 'Everscale Domain') {
    const domainCtr = domainContract(toAddress(data.nft.address));
    const { status } = await domainCtr.methods
      .getStatus({ answerId: 0 })
      .call();

    domainControls = Boolean(['3', '4'].includes(status));
  }

  const directBuy = data?.directBuy
    ? Object.values(data.directBuy).filter(
        (item) => item.nft === data.nft?.address,
      )
    : null;

  const collection = data.collection[data.nft.collection];
  const sellingShape = await nftCreator({
    auction: data.auction ?? null,
    directBuy: directBuy,
    directSell: data.directSell ?? null,
    nft: data.nft,
    domainControls,
    collection,
  });

  return {
    ...data.nft,
    collection,
    sellingShape,
  };
}
