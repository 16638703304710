import { formattedTokenAmount } from 'shared/lib/formatters';
import { format, secondsToMilliseconds } from 'date-fns';
import { Address } from 'api';
import { defaultDateFormat } from 'shared/config';
import { TVariables, TToken } from 'shared/types';

type Data = Partial<{
  amount: string;
  token: TToken;
  startDate: number;
  endDate: number;
  nftAddress: Address;
  userAddress: Address;
}>;
export function notificationMapper({
  amount,
  token,
  startDate,
  endDate,
  nftAddress,
  userAddress,
}: Data): TVariables {
  return {
    userAddress,
    ...(amount &&
      token && { amount: formattedTokenAmount(amount, -token?.decimals) }),
    nftAddress,
    ...(token && { token: token?.symbol }),
    ...(startDate && {
      startDate: format(secondsToMilliseconds(startDate), defaultDateFormat),
    }),
    ...(endDate && {
      expirationDate: format(secondsToMilliseconds(endDate), defaultDateFormat),
    }),
  };
}
