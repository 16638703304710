import { notificationMapper } from 'mappers';
import { tokenService } from 'services/token-service';
import { directSellRepository } from 'shared/repository/direct-sell-repository';
import { TSellParams } from 'shared/types';
import { notificationStore } from 'shared/ui';

export async function startSellUseCase(params: TSellParams) {
  try {
    const { notificationData } = await directSellRepository.startSell(params);

    if (notificationData.event === 'directSellDeployed') {
      const { paymentToken, nft, sender, amount, duration, startTime } =
        notificationData.response;
      const token = await tokenService.getToken(paymentToken.toString());
      const data = notificationMapper({
        amount,
        nftAddress: nft.toString(),
        token,
        userAddress: sender.toString(),
        startDate: startTime,
        endDate: duration ? duration + startTime : 0,
      });
      notificationStore.enqueueNotify({
        type: 'DirectSellDeployed',
        amount: data?.amount ?? '',
        expirationDate: data?.expirationDate ?? '',
        nftAddress: data?.nftAddress ?? '',
        startDate: data?.startDate ?? '',
        token: data?.token ?? '',
      });
      return;
    }

    notificationStore.enqueueNotify({
      type: 'DirectSellDeclined',
    });
  } catch (error) {
    console.error(error);
  }
}
