import { matchPath } from 'react-router-dom';
import {} from 'api';
import {
  getBidsByOwnerInUseCase,
  getBidsByOwnerOutUseCase,
} from 'application/get-bids';
import { CreateFilterStore } from 'shared/lib/create-filter';
import { ListConstructor } from 'shared/lib/list-contructor';

export const profileBidsOut = new ListConstructor({
  request: getBidsByOwnerOutUseCase,
});
export const profileBidsIn = new ListConstructor({
  request: getBidsByOwnerInUseCase,
});

export const filterBidsIn = new CreateFilterStore({
  request(params, options) {
    const matchRes = matchPath<'profileAddress', string>(
      '/profile/:profileAddress',
      location.pathname,
    );
    if (!matchRes) {
      return;
    }
    const profileAddress = matchRes.params.profileAddress;

    profileBidsIn.getList(
      { ...params, owner: profileAddress },
      { ...options, limit: 2 },
    );
  },
  filter: { verified: false },
});

export const filterBidsOut = new CreateFilterStore({
  request(params, options) {
    const matchRes = matchPath<'profileAddress', string>(
      '/profile/:profileAddress',
      location.pathname,
    );
    if (!matchRes) {
      return;
    }
    const profileAddress = matchRes.params.profileAddress;

    profileBidsOut.getList(
      { ...params, owner: profileAddress },
      { ...options, limit: 2 },
    );
  },
  filter: { verified: false },
});
