export const AUCTION_TIP_3 = {
	"ABI version": 2,
	"version": "2.2",
	"header": ["pubkey", "time", "expire"],
	"functions": [
		{
			"name": "constructor",
			"inputs": [
				{"name":"_price","type":"uint128"},
				{"name":"_markerRootAddr","type":"address"},
				{"name":"_tokenRootAddr","type":"address"},
				{"name":"_nftOwner","type":"address"},
				{"name":"_deploymentFee","type":"uint128"},
				{"name":"_marketFee","type":"uint128"},
				{"name":"_marketFeeDecimals","type":"uint8"},
				{"name":"_auctionStartTime","type":"uint64"},
				{"name":"_auctionDuration","type":"uint64"},
				{"name":"_bidDelta","type":"uint16"},
				{"name":"_paymentToken","type":"address"},
				{"name":"sendGasTo","type":"address"}
			],
			"outputs": [
			]
		},
		{
			"name": "onTokenWallet",
			"inputs": [
				{"name":"value","type":"address"}
			],
			"outputs": [
			]
		},
		{
			"name": "getTypeContract",
			"inputs": [
			],
			"outputs": [
				{"name":"value0","type":"string"}
			]
		},
		{
			"name": "onAcceptTokensTransfer",
			"inputs": [
				{"name":"token_root","type":"address"},
				{"name":"amount","type":"uint128"},
				{"name":"sender","type":"address"},
				{"name":"value3","type":"address"},
				{"name":"original_gas_to","type":"address"},
				{"name":"payload","type":"cell"}
			],
			"outputs": [
			]
		},
		{
			"name": "finishAuction",
			"inputs": [
				{"name":"sendGasTo","type":"address"},
				{"name":"callbackId","type":"uint32"}
			],
			"outputs": [
			]
		},
		{
			"name": "buildPlaceBidPayload",
			"inputs": [
				{"name":"answerId","type":"uint32"},
				{"name":"callbackId","type":"uint32"},
				{"name":"buyer","type":"address"}
			],
			"outputs": [
				{"name":"value0","type":"cell"}
			]
		},
		{
			"name": "getInfo",
			"inputs": [
			],
			"outputs": [
				{"components":[{"name":"auctionSubject","type":"address"},{"name":"subjectOwner","type":"address"},{"name":"_paymentToken","type":"address"},{"name":"walletForBids","type":"address"},{"name":"startTime","type":"uint64"},{"name":"duration","type":"uint64"},{"name":"finishTime","type":"uint64"},{"name":"_price","type":"uint128"},{"name":"_nonce","type":"uint64"},{"name":"status","type":"uint8"}],"name":"value0","type":"tuple"}
			]
		},
		{
			"name": "upgrade",
			"inputs": [
				{"name":"newCode","type":"cell"},
				{"name":"newVersion","type":"uint32"},
				{"name":"sendGasTo","type":"address"}
			],
			"outputs": [
			]
		},
		{
			"name": "nft",
			"inputs": [
			],
			"outputs": [
				{"name":"nft","type":"address"}
			]
		},
		{
			"name": "price",
			"inputs": [
			],
			"outputs": [
				{"name":"price","type":"uint128"}
			]
		},
		{
			"name": "markerRootAddr",
			"inputs": [
			],
			"outputs": [
				{"name":"markerRootAddr","type":"address"}
			]
		},
		{
			"name": "tokenRootAddr",
			"inputs": [
			],
			"outputs": [
				{"name":"tokenRootAddr","type":"address"}
			]
		},
		{
			"name": "nftOwner",
			"inputs": [
			],
			"outputs": [
				{"name":"nftOwner","type":"address"}
			]
		},
		{
			"name": "deploymentFee",
			"inputs": [
			],
			"outputs": [
				{"name":"deploymentFee","type":"uint128"}
			]
		},
		{
			"name": "marketFee",
			"inputs": [
			],
			"outputs": [
				{"name":"marketFee","type":"uint128"}
			]
		},
		{
			"name": "marketFeeDecimals",
			"inputs": [
			],
			"outputs": [
				{"name":"marketFeeDecimals","type":"uint8"}
			]
		},
		{
			"name": "bidDelta",
			"inputs": [
			],
			"outputs": [
				{"name":"bidDelta","type":"uint16"}
			]
		},
		{
			"name": "currentBid",
			"inputs": [
			],
			"outputs": [
				{"components":[{"name":"addr","type":"address"},{"name":"value","type":"uint128"}],"name":"currentBid","type":"tuple"}
			]
		},
		{
			"name": "maxBidValue",
			"inputs": [
			],
			"outputs": [
				{"name":"maxBidValue","type":"uint128"}
			]
		},
		{
			"name": "nextBidValue",
			"inputs": [
			],
			"outputs": [
				{"name":"nextBidValue","type":"uint128"}
			]
		}
	],
	"data": [
		{"key":1,"name":"nonce_","type":"uint64"},
		{"key":2,"name":"nft","type":"address"}
	],
	"events": [
		{
			"name": "AuctionCreated",
			"inputs": [
				{"components":[{"name":"auctionSubject","type":"address"},{"name":"subjectOwner","type":"address"},{"name":"_paymentToken","type":"address"},{"name":"walletForBids","type":"address"},{"name":"startTime","type":"uint64"},{"name":"duration","type":"uint64"},{"name":"finishTime","type":"uint64"},{"name":"_price","type":"uint128"},{"name":"_nonce","type":"uint64"},{"name":"status","type":"uint8"}],"name":"value0","type":"tuple"}
			],
			"outputs": [
			]
		},
		{
			"name": "AuctionActive",
			"inputs": [
				{"components":[{"name":"auctionSubject","type":"address"},{"name":"subjectOwner","type":"address"},{"name":"_paymentToken","type":"address"},{"name":"walletForBids","type":"address"},{"name":"startTime","type":"uint64"},{"name":"duration","type":"uint64"},{"name":"finishTime","type":"uint64"},{"name":"_price","type":"uint128"},{"name":"_nonce","type":"uint64"},{"name":"status","type":"uint8"}],"name":"value0","type":"tuple"}
			],
			"outputs": [
			]
		},
		{
			"name": "BidPlaced",
			"inputs": [
				{"name":"buyer","type":"address"},
				{"name":"value","type":"uint128"},
				{"name":"nextBidValue","type":"uint128"}
			],
			"outputs": [
			]
		},
		{
			"name": "BidDeclined",
			"inputs": [
				{"name":"buyer","type":"address"},
				{"name":"value","type":"uint128"}
			],
			"outputs": [
			]
		},
		{
			"name": "AuctionComplete",
			"inputs": [
				{"name":"seller","type":"address"},
				{"name":"buyer","type":"address"},
				{"name":"value","type":"uint128"}
			],
			"outputs": [
			]
		},
		{
			"name": "AuctionCancelled",
			"inputs": [
			],
			"outputs": [
			]
		},
		{
			"name": "AuctionUpgrade",
			"inputs": [
			],
			"outputs": [
			]
		}
	],
	"fields": [
		{"name":"_pubkey","type":"uint256"},
		{"name":"_timestamp","type":"uint64"},
		{"name":"_constructorFlag","type":"bool"},
		{"name":"nonce_","type":"uint64"},
		{"name":"nft","type":"address"},
		{"name":"price","type":"uint128"},
		{"name":"markerRootAddr","type":"address"},
		{"name":"tokenRootAddr","type":"address"},
		{"name":"nftOwner","type":"address"},
		{"name":"deploymentFee","type":"uint128"},
		{"name":"marketFee","type":"uint128"},
		{"name":"marketFeeDecimals","type":"uint8"},
		{"name":"paymentToken","type":"address"},
		{"name":"tokenWallet","type":"address"},
		{"name":"auctionStartTime","type":"uint64"},
		{"name":"auctionDuration","type":"uint64"},
		{"name":"auctionEndTime","type":"uint64"},
		{"name":"bidDelta","type":"uint16"},
		{"components":[{"name":"addr","type":"address"},{"name":"value","type":"uint128"}],"name":"currentBid","type":"tuple"},
		{"name":"maxBidValue","type":"uint128"},
		{"name":"nextBidValue","type":"uint128"},
		{"name":"state","type":"uint8"},
		{"name":"currentVersion","type":"uint32"}
	]
} as const;
