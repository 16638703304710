import {
  Select,
  SelectOption,
  NftCardSmall,
  DatePicker,
  ModalContext,
} from 'shared/ui';
import { observer } from 'mobx-react-lite';
import { createAuctionForm, auctionFormSchema } from '../model';
import { useContext, useEffect } from 'react';
import { wallet } from 'shared/lib/wallet';
import { reservedDates, WEVER_ADDRESS } from 'shared/config';
import { TNft } from 'shared/types';
import { tokenSelectMapper } from 'shared/lib/select-mappers';
import { tokenService } from 'services/token-service';
import { useForm, useWatch } from 'react-hook-form';
import { InputN } from 'shared/ui/molecules/input';
import { zodResolver } from '@hookform/resolvers/zod';
import { addDateUnit } from 'shared/lib/add-date-unit';
import { useIntl } from 'react-intl';
import { addMinutes, endOfDay, isToday, startOfDay } from 'date-fns';
import { AuctionFormSchema } from '../model/create-auction';

const filterPassedTime = (time: Date | number) => {
  const currentDate = new Date();
  const selectedDate = new Date(time);

  return currentDate.getTime() < selectedDate.getTime();
};
export const StartAuctionModalContent = observer(
  ({ content }: { content: TNft }) => {
    const intl = useIntl();
    const { handleModal } = useContext(ModalContext);
    const {
      control,
      setValue,
      reset,
      getFieldState,
      resetField,
      handleSubmit,
      formState: { isDirty, isValid, errors, isValidating, isSubmitSuccessful },
    } = useForm<AuctionFormSchema>({
      mode: 'onChange',
      resolver: zodResolver(auctionFormSchema),
      defaultValues: {
        startTime: new Date(),
        minBid: {
          paymentToken: WEVER_ADDRESS,
          value: '',
        },
        endTime: addDateUnit({
          date: new Date(),
          value: reservedDates[0].value,
        }),
        selectDuration: reservedDates[0].value,
      },
    });

    const selectDurationField = useWatch({
      control,
      name: 'selectDuration',
    });
    const startDateField = useWatch({
      control,
      name: 'startTime',
    });
    const endDateField = useWatch({
      control,
      name: 'endTime',
    });
    const selectPaymentToken = useWatch({
      control,
      name: 'minBid.paymentToken',
    });
    useEffect(() => {
      reset();
    }, [isSubmitSuccessful, reset]);

    useEffect(() => {
      resetField('minBid.value');
    }, [selectPaymentToken, resetField]);
    useEffect(() => {
      if (!selectDurationField || !startDateField) {
        resetField('endTime');

        return;
      }

      setValue(
        'endTime',
        addDateUnit({
          date: startDateField,
          value: selectDurationField,
        }),
      );
    }, [selectDurationField, startDateField, setValue]);

    // console.log('form ', toJS(watch()));
    // console.log('errors', errors);
    if (!content) {
      return null;
    }
    const {
      address,
      name,
      image,
      mimetype,
      collection: { name: collectionName },
      owner,
    } = content;

    return (
      <div className="max-w-[417px]">
        <div className="modal-title title">
          {intl.formatMessage({
            id: 'text.start_auction',
            defaultMessage: 'Start auction',
          })}
        </div>
        <div className="modal-description">
          {intl.formatMessage(
            {
              id: 'text.start_auction.description',
              defaultMessage:
                'You are about put your item {name} from the Name collection up for auction',
            },
            { name: name },
          )}
        </div>
        <div className="mb-9">
          <NftCardSmall
            media={{ src: image, mimetype }}
            collection={collectionName ?? ''}
            name={name}
            sellingMode="AUCTION"
            isOwner={wallet.address === owner}
          />
        </div>
        <div className="mb-4 form-control">
          <label className="text-black mb-1 inline-block">
            {intl.formatMessage({
              id: 'text.minimum_bid',
              defaultMessage: 'Minimum bid',
            })}
          </label>
          <div className=" grid grid-cols-[minmax(125px,_135px)_1fr] items-start gap-x-2">
            <Select
              controller={{
                name: 'minBid.paymentToken',
                control,
              }}
              options={tokenSelectMapper(tokenService.tokens)}
            >
              {tokenService.tokens.map((token, index) => (
                <SelectOption
                  key={index}
                  value={token.address}
                  className="grid grid-flow-col items-center justify-start gap-x-2"
                >
                  <img src={token.logoURI} className="w-5 h-5" />
                  {token.symbol}
                </SelectOption>
              ))}
            </Select>

            <InputN
              disabled={Boolean(getFieldState('minBid.paymentToken').error)}
              controller={{
                name: 'minBid.value',
                control,
              }}
              number
            />
          </div>
        </div>
        <div className="mb-4 form-control">
          <label className="text-black mb-1 inline-block">
            {intl.formatMessage({
              id: 'text.start_date',
              defaultMessage: 'Start date',
            })}
          </label>

          <DatePicker
            controller={{
              name: 'startTime',
              control,
            }}
            startDate={new Date()}
            minDate={new Date()}
            filterTime={filterPassedTime}
            selectsStart
            showTimeSelect
          />
          {errors['startTime'] && (
            <div className="helper-text" data-invalid={true}>
              {errors['startTime']?.message}
            </div>
          )}
        </div>
        <div className="mb-4 form-control">
          <label className="text-black mb-1 inline-block">
            {intl.formatMessage({
              id: 'text.end_date',
              defaultMessage: 'End date',
            })}
          </label>
          <div className="grid-cols-[minmax(125px,_135px)_1fr] grid items-start gap-x-2">
            <Select
              controller={{
                name: 'selectDuration',
                control,
              }}
              options={reservedDates}
            >
              {reservedDates.map((item, index) => (
                <SelectOption key={index} value={item.value}>
                  {item.label}
                </SelectOption>
              ))}
            </Select>

            <DatePicker
              controller={{ name: 'endTime', control }}
              readOnly={selectDurationField !== 'custom-date'}
              selectsEnd
              // @ts-expect-error
              endDate={endDateField}
              showTimeSelect
              startTime={startDateField}
              minDate={startDateField}
              minTime={
                isToday(endDateField)
                  ? addMinutes(startDateField, 30)
                  : startOfDay(endDateField)
              }
              maxTime={endOfDay(endDateField)}
            />
          </div>
          {errors['endTime'] && (
            <div className="helper-text" data-invalid={true}>
              {errors['endTime']?.message}
            </div>
          )}
        </div>

        <div className="mb-9 grid gap-y-2">
          {/* <div className="grid grid-flow-col items-center justify-between gap-x-2">
              <div>Royalty</div>
              <div className="text-black">5%</div>
            </div>
            <div className="grid grid-flow-col items-center justify-between gap-x-2">
              <div>Marketplace fee</div>
              <div className="text-black">5%</div>
            </div>
            <div className="grid grid-flow-col items-center justify-between gap-x-2">
              <div>You will receive</div>
              <div className="text-black">5%</div>
            </div>
            <div className="grid grid-flow-col items-center justify-between gap-x-2">
              <div>Service fee</div>
              <div className="text-black">5%</div>
            </div> */}
        </div>
        <div className="modal-actions">
          <button
            className="btn secondary"
            data-color="blue"
            onClick={() => handleModal()}
          >
            {intl.formatMessage({
              id: 'button.close',
              defaultMessage: 'Close',
            })}
          </button>
          <button
            className={`btn primary ${isValidating && 'loading'}`}
            data-color="blue"
            onClick={handleSubmit((data) =>
              createAuctionForm.startAuction(data, address),
            )}
            disabled={!isDirty || !isValid || isValidating}
          >
            {intl.formatMessage({
              id: 'button.start_auction',
              defaultMessage: 'Start auction',
            })}
          </button>
        </div>
      </div>
    );
  },
);
