import { ListConstructor } from 'shared/lib/list-contructor';
import { getNftListInteractor } from 'interactor/get-nft-list';
import { CreateFilterStore } from 'shared/lib/create-filter';
import { matchPath } from 'react-router-dom';
import { makeAutoObservable, runInAction } from 'mobx';
import { getCollectionUseCase } from 'application/get-collection';
import { Collection as TCollection } from 'api';

export const collectionNfts = new ListConstructor({
  request: getNftListInteractor,
});
export const filterNfts = new CreateFilterStore({
  request(params, options) {
    const matchRes = matchPath<'collectionAddress', string>(
      '/collections/:collectionAddress',
      location.pathname,
    );
    if (!matchRes) {
      return;
    }
    const collectionAddress = matchRes.params.collectionAddress ?? '';

    collectionNfts.getList(
      { ...params, collections: [collectionAddress] },
      options,
    );
  },
});

class Collection {
  data: TCollection | null = null;
  isLoading = false;
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }
  reset() {
    this.data = null;
  }
  async getCollection(address: string) {
    this.isLoading = true;

    const collection = await getCollectionUseCase(address);

    runInAction(() => {
      this.data = collection;
      this.isLoading = false;
    });
  }
}

export const collection = new Collection();
