import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { Pagination } from 'shared/ui';
import { Layout } from 'widgets/layout';
import Sidebar from 'widgets/layout/sidebar';

export const StatsPage = observer((): JSX.Element => {
  const [page, setPage] = useState(1);
  const [sidebarHidden, setSidebarHidden] = useState(true);

  return (
    <>
      <Layout onMenu={() => setSidebarHidden(false)}>
        <div className="container">
          <h1 className="text-black text-[44px] leading-[48px] font-semibold mt-9 lg:mt-16">
            Top NFTs
          </h1>

          <div className="stats-table mt-9 lg:mt-5">
            <header className="stats-row">
              <div className="stats-cell">#</div>
              <div className="stats-cell">Collection</div>
              <div className="stats-cell text-right">Volume</div>
              <div className="stats-cell text-right">Floor price</div>
              <div className="stats-cell text-right">Owners</div>
              <div className="stats-cell text-right">Items</div>
            </header>
            <div className="stats-row">
              <div className="stats-cell">1</div>
              <div className="stats-cell">
                <div className="flex items-center">
                  <div className="w-9 h-9 rounded-full bg-grey-950 mr-1.5" />
                  <span>Some unique name</span>
                </div>
              </div>
              <div className="stats-cell">
                <div className="stats-label">Volume</div>
                <div className="stats-value text-right">
                  <div>1 200 000 EVER</div>
                  <div className="flex items-center justify-end text-xs">
                    <span className="text-grey-300">$7 200 000</span>
                    <span className="ml-2 mr-1 border-solid border-b-green-400 border-b-4 border-x-transparent border-x-4 border-t-0" />
                    <span className="text-green-400">8.81%</span>
                  </div>
                </div>
              </div>
              <div className="stats-cell">
                <div className="stats-label">Floor price</div>
                <div className="stats-value text-right">
                  <div>1 000 EVER</div>
                  <div className="text-xs text-grey-300">$4 000</div>
                </div>
              </div>
              <div className="stats-cell">
                <div className="stats-label">Owners</div>
                <div className="stats-value text-right">2 103</div>
              </div>
              <div className="stats-cell">
                <div className="stats-label">Items</div>
                <div className="stats-value text-right">3 000</div>
              </div>
            </div>
            <div className="stats-row">
              <div className="stats-cell">2</div>
              <div className="stats-cell">
                <div className="flex items-center">
                  <div className="w-9 h-9 rounded-full bg-grey-950 mr-1.5" />
                  <span>Some unique name</span>
                </div>
              </div>
              <div className="stats-cell">
                <div className="stats-label">Volume</div>
                <div className="stats-value text-right">
                  <div>1 200 000 EVER</div>
                  <div className="flex items-center justify-end text-xs">
                    <span className="text-grey-300">$7 200 000</span>
                    <span className="ml-2 mr-1 border-solid border-b-green-400 border-b-4 border-x-transparent border-x-4 border-t-0" />
                    <span className="text-green-400">8.81%</span>
                  </div>
                </div>
              </div>
              <div className="stats-cell">
                <div className="stats-label">Floor price</div>
                <div className="stats-value text-right">
                  <div>1 000 EVER</div>
                  <div className="text-xs text-grey-300">$4 000</div>
                </div>
              </div>
              <div className="stats-cell">
                <div className="stats-label">Owners</div>
                <div className="stats-value text-right">2 103</div>
              </div>
              <div className="stats-cell">
                <div className="stats-label">Items</div>
                <div className="stats-value text-right">3 000</div>
              </div>
            </div>
          </div>

          <div className="flex justify-end mt-5">
            <Pagination page={page} total={10} onChange={setPage} />
          </div>
        </div>
      </Layout>
      <Sidebar
        mobile={true}
        hidden={sidebarHidden}
        onHide={() => setSidebarHidden(true)}
      />
    </>
  );
});
