import { useMemo } from 'react';
import { uniqueKey } from '@broxus/js-utils';

export type AccountIconProps = {
  address: string;
  className?: string;
  size?: 'xsmall' | 'small' | 'medium' | 'large' | number;
};

const circles = [
  {
    cx: 3,
    cy: 3,
    fill: 0,
    r: 7,
  },
  {
    cx: 3,
    cy: 13,
    fill: 4,
    r: 7,
  },
  {
    cx: 3,
    cy: 23,
    fill: 8,
    r: 7,
  },
  {
    cx: 3,
    cy: 33,
    fill: 12,
    r: 7,
  },
  {
    cx: 13,
    cy: 3,
    fill: 1,
    r: 7,
  },
  {
    cx: 13,
    cy: 13,
    fill: 5,
    r: 7,
  },
  {
    cx: 13,
    cy: 23,
    fill: 9,
    r: 7,
  },
  {
    cx: 13,
    cy: 33,
    fill: 13,
    r: 7,
  },
  {
    cx: 23,
    cy: 3,
    fill: 2,
    r: 7,
  },
  {
    cx: 23,
    cy: 13,
    fill: 6,
    r: 7,
  },
  {
    cx: 23,
    cy: 23,
    fill: 10,
    r: 7,
  },
  {
    cx: 23,
    cy: 33,
    fill: 14,
    r: 7,
  },
  {
    cx: 33,
    cy: 3,
    fill: 3,
    r: 7,
  },
  {
    cx: 33,
    cy: 13,
    fill: 7,
    r: 7,
  },
  {
    cx: 33,
    cy: 23,
    fill: 11,
    r: 7,
  },
  {
    cx: 33,
    cy: 33,
    fill: 15,
    r: 7,
  },
];

const sizes = {
  default: 32,
  large: 40,
  medium: 36,
  small: 24,
  xsmall: 20,
};

function getSize(size?: AccountIconProps['size']) {
  const isNumber = size ? !Number.isNaN((size as any) * 1) : false;
  return typeof size === 'number' || isNumber ? size : sizes[size ?? 'default'];
}

function getColors(address: string): string[] {
  const hash = address.split(':')[1] ?? address.split('x')[1] ?? [];

  const colors: string[] = [];
  for (let i = 0; i < 16; i++) {
    colors.push(
      `#${hash[0]}${hash[i * 4]}${hash[i * 4 + 1]}${hash[i * 4 + 2]}${
        hash[63] || hash[39]
      }${hash[i * 4 + 3]}`,
    );
  }

  return colors;
}

export function AccountIcon(props: AccountIconProps): JSX.Element {
  const { address, className, size } = props;

  const computedColors = useMemo(() => getColors(address), [address]);
  const computedSize = useMemo(() => getSize(size), [size]);
  const clipPathId = useMemo(() => uniqueKey(7, address), [address]);

  return (
    <svg
      className={className}
      fill="none"
      height={computedSize}
      viewBox="0 0 36 36"
      width={computedSize}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#${clipPathId})`}>
        {circles.map(({ fill, ...circle }) => (
          <circle
            key={`${Object.values(circle).join()}${fill}`}
            {...circle}
            fill={computedColors[fill]}
          />
        ))}
      </g>
      <defs>
        <clipPath id={clipPathId}>
          <rect width={36} height={36} rx={18} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
