import { notificationStore } from 'shared/ui';
import { auctionRepository } from 'shared/repository';
import { notificationMapper } from 'mappers';
import { tokenService } from 'services/token-service';

export async function placeBidUseCase({
  value,
  paymentToken,
  auctionAddress,
}: {
  value: string;
  paymentToken: string;
  auctionAddress: string;
}) {
  try {
    const { notificationData } = await auctionRepository.placeBid({
      amount: value,
      paymentToken,
      auctionAddress,
    });

    if (notificationData.event === 'bidPlacedCallback') {
      const { amount, nft, paymentToken } = notificationData.response;

      const token = await tokenService.getToken(paymentToken);
      const data = notificationMapper({
        nftAddress: notificationData.response.nft.toString(),
        amount,
        token,
      });
      notificationStore.enqueueNotify({
        type: 'AuctionBidPlaced',
        amount: data?.amount ?? '',
        nftAddress: data?.nftAddress ?? '',
        token: data?.token ?? '',
      });

      return;
    }

    const { nft } = notificationData.response;

    notificationStore.enqueueNotify({
      type: 'AuctionBidDeclined',
      nftAddress: nft.toString(),
    });
  } catch (error) {
    console.error(error);
  }
}
