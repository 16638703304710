import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Link, useParams } from 'react-router-dom';
import { NftCard } from 'modules/nft';
import { Filter } from 'shared/ui';
import { collectedNftList, filterNfts } from '../model';

const ITEMS_PER_PAGE = 28;

export const CollectedNftList = observer(() => {
  useEffect(
    () => () => {
      filterNfts.clear();
      collectedNftList.reset();
    },
    [],
  );
  return (
    <>
      <Filter
        onClearFilter={() => filterNfts.clear({ refetch: true })}
        filterCollectionsStore={filterNfts.filterCollectionsStore}
        filterSaleTypeStore={filterNfts.filterSaleTypeStore}
      />
      <Nfts />
    </>
  );
});
const NoNfts = () => {
  const intl = useIntl();

  return (
    <div className="flex flex-col items-center mt-10">
      <h3 className="text-black text-lg font-semibold leading-6">
        {intl.formatMessage({
          id: 'collected.no_nfts_title',
          defaultMessage: "It seems you don't have any NFTs yet",
        })}
      </h3>
      <p className="text-black text-sm mt-3">
        {intl.formatMessage({
          id: 'collected.no_nfts_description',
          defaultMessage: "It seems you don't have any NFTs yet",
        })}
      </p>
      <Link
        className="btn primary mt-6 w-auto font-medium px-10 py-3"
        data-color="blue"
        to="/"
      >
        {intl.formatMessage({
          id: 'button.explore_nfts',
          defaultMessage: 'Explore NFTs',
        })}
      </Link>
    </div>
  );
};
const Nfts = observer(() => {
  const { profileAddress } = useParams<'profileAddress'>();
  const intl = useIntl();

  useEffect(() => {
    collectedNftList.getList(
      { owners: [profileAddress!], verified: false },
      { limit: ITEMS_PER_PAGE },
    );
  }, [profileAddress]);

  const {
    data: { items, totalCount },
  } = collectedNftList;

  const handleLoadMore = () => {
    collectedNftList.getList(
      { owners: [profileAddress!], verified: false, ...filterNfts.filter },
      { limit: ITEMS_PER_PAGE, offset: items.length },
    );
  };

  if (collectedNftList.isLoading) {
    return <div className="loader"></div>;
  }

  if (totalCount <= 0) {
    return <NoNfts />;
  }

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mb-5">
        {items.map((nft, index) => (
          <NftCard nft={nft} key={index} />
        ))}
      </div>
      {items.length < totalCount && (
        <button
          className={`btn explore-link ${
            collectedNftList.isLoading && 'loading'
          }`}
          disabled={collectedNftList.isLoading}
          onClick={handleLoadMore}
        >
          {intl.formatMessage({
            id: 'button.load_more_nfts',
            defaultMessage: 'Load more NFTs',
          })}
        </button>
      )}
    </>
  );
});
