import { makeAutoObservable, runInAction } from 'mobx';

import { placeBidUseCase } from 'application/place-bid';
import { tokenService } from 'services/token-service';

import { z } from 'zod';
import BigNumber from 'bignumber.js';

import { formattedTokenAmount } from '@broxus/js-utils';
import { wallet } from 'shared/lib/wallet';
import { nftStore, bidsStore, BIDS_LIMIT } from 'modules/nft';

export const placeBidFormSchema = z.object({
  bid: z
    .object({
      value: z.string().refine(Number, { message: "Can't be zero or empty" }),
      paymentToken: z.string(),
      minBid: z.string(),
    })
    .superRefine(
      async ({ paymentToken, value: rawValue, minBid: rawMinBid }, ctx) => {
        const value = Number(rawValue);
        const minBid = Number(rawMinBid);
        try {
          const token = tokenService.getToken(paymentToken);
          if (!token) {
            return;
          }
          const rawBalance = await wallet.getBalance(token.address);

          if (minBid > value) {
            ctx.addIssue({
              code: 'custom',
              message: 'Bid should be more than minimum bid',
              path: ['value'],
            });
          }

          const balance = Number(
            formattedTokenAmount(rawBalance, token.decimals, {
              preserve: true,
            }),
          );

          if (balance <= 0 || balance < value) {
            ctx.addIssue({
              code: 'custom',
              message: 'Insufficient balance',
              path: ['value'],
            });
          }
        } catch (error) {
          ctx.addIssue({
            code: 'custom',
            message: 'Insufficient balance',
            path: ['paymentToken'],
          });
        }
      },
    ),
});
export type PlaceBidFormSchema = z.infer<typeof placeBidFormSchema>;

class MakeBidForm {
  isLoading = false;
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  async placeBid({ bid }: PlaceBidFormSchema, auctionAddress: string) {
    this.isLoading = true;

    try {
      const token = tokenService.getToken(bid.paymentToken);

      if (!token) {
        return;
      }

      const bidTo = new BigNumber(bid.value)
        .shiftedBy(token.decimals)
        .toFixed(0, BigNumber.ROUND_UP);

      await placeBidUseCase({
        auctionAddress,
        paymentToken: token.address,
        value: bidTo,
      });
      nftStore.fetchNftDetails(nftStore.data?.address!);

      bidsStore.getBids({ auction: auctionAddress }, { limit: BIDS_LIMIT });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }
}

export const makeBidForm = new MakeBidForm();
