import { observer } from 'mobx-react-lite';
import { MouseEvent, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { Layout } from 'widgets/layout';
import Sidebar from 'widgets/layout/sidebar';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';

export const GuidePage = observer((): JSX.Element => {
  const [sidebarHidden, setSidebarHidden] = useState(true);
  const intl = useIntl();

  const handleClick = useCallback((e: MouseEvent<HTMLElement>) => {
    e.currentTarget.classList.toggle('_expanded')
  }, []);

  return (
    <>
      <Layout onMenu={() => setSidebarHidden(false)}>
        <div className="px-4 lg:px-0">
          <h1 className="text-black font-bold text-4xl mb-10">
            {intl.formatMessage({
              id: 'guide.title',
              defaultMessage: 'Guide'
            })}
          </h1>

          <dl className="guide">
            <dt className="guide-term" onClick={handleClick}>
              {intl.formatMessage({
                id: 'guide.getting_started',
                defaultMessage: 'Getting Started'
              })}
              <CrossIcon className="icon" />
            </dt>
            <dd className="guide-desc">
              <h2>
                {intl.formatMessage({
                  id: 'guide.getting_started_0.title',
                  defaultMessage: 'Getting Started with Tokstock'
                })}
              </h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: 'guide.getting_started_0.text',
                    defaultMessage: '<p>To get started, you just need to connect your wallet to the website. No registrations, it\'s WEB3! To do this, click on "Connect wallet" in the upper right corner of the screen and connect your EVER Wallet. To disconnect your wallet, click on the balance in the upper right corner, and then on "Log out."</p>'
                  }, undefined, { ignoreTag: true })
                }}
              />
              <h2>
                {intl.formatMessage({
                  id: 'guide.getting_started_1.title',
                  defaultMessage: 'What is EVER Wallet'
                })}
              </h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: 'guide.getting_started_1.text',
                    defaultMessage: `
                      <p>EVER Wallet is a wallet for Everscale developed by Broxus. Extensions for Chrome and Firefox are available, as well as mobile versions for iOS and Android. You can download the app from the official website: <a href="https://everwallet.net" target="_blank">everwallet.net</a>.</p>
                      <p>If you have any questions, please follow the <a href="https://docs.everwallet.net/" target="_blank">instructions</a>.</p>
                    `
                  }, undefined, { ignoreTag: true })
                }}
              />
              <h2>
                {intl.formatMessage({
                  id: 'guide.getting_started_2.title',
                  defaultMessage: 'What are smart contracts'
                })}
              </h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: 'guide.getting_started_2.text',
                    defaultMessage: `
                      <p>Smart contracts are programs that operate inside of a blockchain. The code of many smart contracts is open and anyone can get acquainted with how a program works and be sure that it will not perform any hidden actions with user funds.</p>
                      <p>WEB3 is based on user interaction with smart contracts, as opposed to the current iteration of the internet (WEB2), which is based on user interaction with a centralized server. Usually in WEB2, a user does not know for sure what will happen to their data after they have provided it to a website. WEB2 is often criticized for allowing server owners full control over user data.</p>
                    `
                  }, undefined, { ignoreTag: true })
                }}
              />
              <h2>
                {intl.formatMessage({
                  id: 'guide.getting_started_3.title',
                  defaultMessage: 'What is an NFT'
                })}
              </h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: 'guide.getting_started_3.text',
                    defaultMessage: `
                      <p>An NFT or non-fungible token is a kind of digital token that is unique and cannot be replaced by another. In the case of, for example, Bitcoin, it does not matter which individual bitcoin is on your wallet. They are the same and one bitcoin can easily be replaced with another. With NFTs, it is important which token is on your wallet.</p>
                      <p>The application of NFTs is much broader than simply transferring images to the blockchain. For example, in the evername.io project each domain name is an NFT. By owning the NFT of a specific domain, you can independently decide which address your domain will be linked to. However, you do not need to contact any central authority to change the domain assignment. All you need is an NFT on your wallet. In addition, NFTs can be used as game items that players really own and continue to own even if the game project is closed by developers.</p>
                      <p>Event tickets and lottery tickets are also great use cases for NFTs, along with club cards.</p>
                      <p>We hope that our marketplace will make the world of NFTs clearer for you and allow you to sell and buy really useful tokens.</p>
                    `
                  }, undefined, { ignoreTag: true })
                }}
              />
              <h2>
                {intl.formatMessage({
                  id: 'guide.getting_started_4.title',
                  defaultMessage: 'Search for collections on the website'
                })}
              </h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: 'guide.getting_started_4.text',
                    defaultMessage: `
                      <p>Verified collections are displayed in the Explore NFTs section. To verify your collection, contact us here: <a href="mailto:nick@bf.rocks" target="_blank">nick@bf.rocks</a></p>
                      <p>To go to any NFT page in Everscale, enter its address in the search bar at the top of the website.</p>
                    `
                  }, undefined, { ignoreTag: true })
                }}
              />
            </dd>

            <dt className="guide-term" onClick={handleClick}>
              {intl.formatMessage({
                id: 'guide.how_to_buy',
                defaultMessage: 'How to buy'
              })}
              <CrossIcon className="icon" />
            </dt>
            <dd className="guide-desc">
              <h2>
                {intl.formatMessage({
                  id: 'guide.how_to_buy_0.title',
                  defaultMessage: 'Purchase EVER'
                })}
              </h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: 'guide.how_to_buy_0.text',
                    defaultMessage: `
                        <p>EVER is the native currency of the Everscale network. It is required to pay for any transactions on the network. You can buy EVER for both fiat and cryptocurrency. Choose the right way to buy EVER <a href="https://everkit.org/coin#buy" target="_blank">here</a>.</p>
                        <p>Your Everscale address can be seen in the EVER Wallet app, just click "Receive."</p>
                    `
                  }, undefined, { ignoreTag: true })
                }}
              />
              <h2>
                {intl.formatMessage({
                  id: 'guide.how_to_buy_1.title',
                  defaultMessage: 'Exchange tokens on FlatQube'
                })}
              </h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: 'guide.how_to_buy_1.text',
                    defaultMessage: `
                        <p>Tokstock allows you to sell NFTs for different currencies: USDT, WEVER, WBTC, USDC, etc. You can exchange the tokens you have for the tokens you need on the decentralized flatqube.io exchange.</p>
                        <p>Detailed video and text instructions for exchanging tokens on <a href="https://flatqube.io" target="_blank">flatqube.io</a> can be found <a href="https://everkit.org/en/docs/articles/dex" target="_blank">here</a>.</p>
                    `
                  }, undefined, { ignoreTag: true })
                }}
              />
              <h2>
                {intl.formatMessage({
                  id: 'guide.how_to_buy_2.title',
                  defaultMessage: 'Transfer tokens via OctusBridge'
                })}
              </h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: 'guide.how_to_buy_2.text',
                    defaultMessage: `
                        <p>If you have assets on other networks, such as Ethereum, Polygon or BNB Smart Chain - you can easily transfer them to the Everscale network using <a href="https://octusbridge.io" target="_blank">octusbridge.io</a>.</p>
                        <p>Detailed video and textual instructions for transferring assets between networks using <a href="https://octusbridge.io" target="_blank">octusbridge.io</a> can be found <a href="https://everkit.org/en/docs/articles/bridge" target="_blank">here</a>.</p>
                    `
                  }, undefined, { ignoreTag: true })
                }}
              />
              <h2>
                {intl.formatMessage({
                  id: 'guide.how_to_buy_3.title',
                  defaultMessage: 'How to buy an NFT'
                })}
              </h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: 'guide.how_to_buy_3.text',
                    defaultMessage: `
                        <p>To view the NFTs available for sale in Explore NFTs, use the "Buy now" filter in the "Items" section.</p>
                        <p>To buy an NFT, go to the token page and click "Buy now", then confirm the action by clicking "Buy now" again in the window that opens.</p>
                        <p>If you are viewing an NFT in the Explore NFTs or NFT for sale section, the "Buy now" button will appear when you hover over the image.</p>
                        <p>After you confirm your purchase, the NFT will be sent to your wallet. You can see it in the Profile section of Tokstock, as well as the NFT section of Ever Wallet.</p>
                    `
                  }, undefined, { ignoreTag: true })
                }}
              />
              <h2>
                {intl.formatMessage({
                  id: 'guide.how_to_buy_4.title',
                  defaultMessage: 'How to participate in an auction'
                })}
              </h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: 'guide.how_to_buy_4.text',
                    defaultMessage: `
                        <p>To view current auctions in Explore NFT's, use the Live auction filter in the Items tab.</p>
                        <p>When hovering over the NFT or when you’re on the NFT page, click Place A Bid. In the window that opens, enter a bid equal to or greater than the minimum and click "Place A Bid" again.</p>
                        <p>Your bid will now appear on the NFT page as well as your profile in the "Bids" section.</p>
                        <p>If your bid is surpassed, an "Overbid" inscription will appear next to it on your profile page.</p>
                        <p>If your bid is the highest upon the completion of the auction, you will receive the NFT on your wallet. Otherwise, the funds will be returned to you when someone will make the higher bid.</p>
                    `
                  }, undefined, { ignoreTag: true })
                }}
              />
              <h2>
                {intl.formatMessage({
                  id: 'guide.how_to_buy_5.title',
                  defaultMessage: 'How to make an offer'
                })}
              </h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: 'guide.how_to_buy_5.text',
                    defaultMessage: `
                        <p>You can make an offer for any NFT, even if it's not at the auction. If the NFT owner accepts your offer, you will receive the NFT on your wallet.</p>
                        <p>To make an offer, go to the NFT page and click "Make An Offer.</p>
                        <p>In the window that opens, select the token and the amount you want to offer and click "Make An Offer" again and sign the transaction. If you want, you can set a validity period for the offer.</p>
                        <p>Your offer will appear on the NFT page in the "Offers" section, as well as in the "Offers" section of your profile.</p>
                    `
                  }, undefined, { ignoreTag: true })
                }}
              />
              <h2>
                {intl.formatMessage({
                  id: 'guide.how_to_buy_6.title',
                  defaultMessage: 'How to cancel an offer'
                })}
              </h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: 'guide.how_to_buy_6.text',
                    defaultMessage: `
                        <p>You can cancel an offer at any time on the NFT page or in your profile.</p>
                        <p>If you purchased an NFT for which you previously made an offer, this offer can be canceled in your profile in the "Offers received" section of the "Offers" tab.</p>
                    `
                  }, undefined, { ignoreTag: true })
                }}
              />
            </dd>

            <dt className="guide-term" onClick={handleClick}>
              {intl.formatMessage({
                id: 'guide.how_to_sell',
                defaultMessage: 'How to sell'
              })}
              <CrossIcon className="icon" />
            </dt>
            <dd className="guide-desc">
              <h2>
                {intl.formatMessage({
                  id: 'guide.how_to_sell_0.title',
                  defaultMessage: 'How to put an NFT up for sale'
                })}
              </h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: 'guide.how_to_sell_0.text',
                    defaultMessage: `
                        <p>To list an NFT for sale, go to the NFT page and click Put On Sale.</p>
                        <p>In the window that opens, select the token you want to sell and enter the amount. In addition, you can choose the start date of the sale if you want to make a deferred sale. To limit the sales time, specify an end date.</p>
                        <p>After filling in the data, click Put On Sale again and sign the transaction.</p>
                        <p>You can view the NFTs you have put up for sale in your profile in the "Collected" tab using the "Buy now" filter, or on the Collection page or NFT Explorer.</p>
                    `
                  }, undefined, { ignoreTag: true })
                }}
              />
              <h2>
                {intl.formatMessage({
                  id: 'guide.how_to_sell_1.title',
                  defaultMessage: 'How to remove an NFT from the for sale list'
                })}
              </h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: 'guide.how_to_sell_1.text',
                    defaultMessage: `
                        <p>You can remove an NFT from the for sale list at any time. To do this, go to the NFT page and click "Cancel Sell."</p>
                        <p>In the window that opens, click "Confirm" and sign the transaction.</p>
                    `
                  }, undefined, { ignoreTag: true })
                }}
              />
              <h2>
                {intl.formatMessage({
                  id: 'guide.how_to_sell_2.title',
                  defaultMessage: 'How to auction an NFT'
                })}
              </h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: 'guide.how_to_sell_2.text',
                    defaultMessage: `
                        <p>To auction an NFT, go to the NFT page and click "Start Auction."</p>
                        <p>In the window that opens, select the token you want to accept bids in, and also specify the minimum bid.</p>
                        <p>Select a start and end date for the auction. Click "Start Auction" and sign the transaction.</p>
                    `
                  }, undefined, { ignoreTag: true })
                }}
              />
              <h2>
                {intl.formatMessage({
                  id: 'guide.how_to_sell_3.title',
                  defaultMessage: 'How to finish an auction'
                })}
              </h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: 'guide.how_to_sell_3.text',
                    defaultMessage: `
                        <p>You can only finish an auction after it ends. To do this, go to the NFT page and click "Finish Auction."</p>
                        <p>If the auction had bids, the auction would end in favor of the highest bidder. If there were no bids, the NFT would return to the owner.</p>
                        <p>The auction can be finished by the owner of the NFT and the user who made the largest bid.</p>
                    `
                  }, undefined, { ignoreTag: true })
                }}
              />
            </dd>

            <dt className="guide-term" onClick={handleClick}>
              {intl.formatMessage({
                id: 'guide.your_profile',
                defaultMessage: 'Your Profile'
              })}
              <CrossIcon className="icon" />
            </dt>
            <dd className="guide-desc">
              <h2>
                {intl.formatMessage({
                  id: 'guide.your_profile_0.title',
                  defaultMessage: 'How to log into your profile'
                })}
              </h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: 'guide.your_profile_0.text',
                    defaultMessage: `
                        <p>To view your profile, connect your wallet to the website, click on the balance in the upper right corner and select "Profile" from the drop-down menu.</p>
                    `
                  }, undefined, { ignoreTag: true })
                }}
              />
              <h2>
                {intl.formatMessage({
                  id: 'guide.your_profile_1.title',
                  defaultMessage: 'Collected'
                })}
              </h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: 'guide.your_profile_1.text',
                    defaultMessage: `
                        <p>The "Collected" tab of the profile displays all NFTs that you own. For more convenient viewing, you can sort NFTs by specific collections, as well as by Buy now and Live auction statuses.</p>
                    `
                  }, undefined, { ignoreTag: true })
                }}
              />
              <h2>
                {intl.formatMessage({
                  id: 'guide.your_profile_2.title',
                  defaultMessage: 'Offers'
                })}
              </h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: 'guide.your_profile_2.text',
                    defaultMessage: `
                        <p>The "Offers" tab displays all the offers you have made, as well as the offers that other members made for your NFTs.</p>
                        <p>In this section, you can cancel your offers and accept offers made for your NFTs.</p>
                        <p>For a more convenient view, use the filters.</p>
                    `
                  }, undefined, { ignoreTag: true })
                }}
              />
              <h2>
                {intl.formatMessage({
                  id: 'guide.your_profile_3.title',
                  defaultMessage: 'Bids'
                })}
              </h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: 'guide.your_profile_3.text',
                    defaultMessage: `
                        <p>The "Bids" tab displays the bids you have made at auctions, as well as bids received during auctions on your NFTs.</p>
                        <p>The "Bids received" tab displays the maximum bid for each auction.</p>
                        <p>If your bid was beaten by another bidder in the "Bids made" section, it will be marked with an "overbid" inscription.</p>
                        <p>For a more convenient view, use the filters.</p>
                    `
                  }, undefined, { ignoreTag: true })
                }}
              />
            </dd>

            <dt className="guide-term" onClick={handleClick}>
              {intl.formatMessage({
                id: 'guide.security',
                defaultMessage: 'Security'
              })}
              <CrossIcon className="icon" />
            </dt>
            <dd className="guide-desc">
              <h2>
                {intl.formatMessage({
                  id: 'guide.security_0.title',
                  defaultMessage: 'How to secure your account'
                })}
              </h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: 'guide.security_0.text',
                    defaultMessage: `
                        <p>In the WEB3 world, your account is your wallet. No one can access your wallet if you're doing everything right. At the same time, no one can help you regain access if you lose it.</p>
                        <p>The most important thing is to reliably save the seed phrase in several places and not show it to anyone. Anyone with access to your seed phrase can access your wallet. You can find out about ways to securely store seed phrases <a href="https://everkit.org/en/docs/articles/seed-phrase" target="_blank">here</a>.</p>
                        <p>In addition, always check that you have gone to the real website you intended to visit. Scam websites can be designed to look like other, legitimate websites. If you connect your wallet to a fraudulent site and sign a transaction, you can lose all your funds.</p>
                        <p>Check your website address. The address of our website is <a href="https://tokstock.io" target="_blank">tokstock.io</a>. Any changes in the address indicate that you are not on our real website. If you're ever not sure, don't connect your wallet. Take your time and ask for help to make sure your funds are safe.</p>
                        <p>You should not tell anyone your seed phrase.</p>
                    `
                  }, undefined, { ignoreTag: true })
                }}
              />
              <h2>
                {intl.formatMessage({
                  id: 'guide.security_1.title',
                  defaultMessage: 'Where your NFTs are stored'
                })}
              </h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: 'guide.security_1.text',
                    defaultMessage: `
                        <p>For each sale or auction, a smart contract is automatically created to which the NFT is sent.</p>
                        <p>Smart contracts work strictly according to the instructions written into them in advance and cannot change their behavior. Once a sales smart contract is created, it cannot be changed.</p>
                        <p>When making deals in the WEB3 world, you don't need to trust people, as everyone makes their own smart contracts. This is the advantage of WEB3 over WEB2. You control your assets on your own.</p>
                    `
                  }, undefined, { ignoreTag: true })
                }}
              />
              <h2>
                {intl.formatMessage({
                  id: 'guide.security_2.title',
                  defaultMessage: 'Where your funds are stored'
                })}
              </h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: 'guide.security_2.text',
                    defaultMessage: `
                        <p>For each offer or the last bid, a smart contract is automatically created, to which the buyer's funds are subsequently transferred. When funds arrive, the smart contract sends them to the seller, and the NFT is sent to the buyer.</p>
                    `
                  }, undefined, { ignoreTag: true })
                }}
              />
              <h2>
                {intl.formatMessage({
                  id: 'guide.security_3.title',
                  defaultMessage: 'How can you make sure that a smart contract is secure?'
                })}
              </h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: 'guide.security_3.text',
                    defaultMessage: `
                        <p>Tokstock smart contract code is in the public domain. You can find it here: <a href="https://github.com/blockchain-family/nft-marketplace-contracts" target="_blank">https://github.com/blockchain-family/nft-marketplace-contracts</a></p>
                    `
                  }, undefined, { ignoreTag: true })
                }}
              />
            </dd>
          </dl>
        </div>
      </Layout>
      <Sidebar
        mobile={true}
        hidden={sidebarHidden}
        onHide={() => setSidebarHidden(true)}
      />
    </>
  );
});
