import { useIntl } from 'react-intl';
import { PriceRendererCard } from 'modules/nft';
import { NftMedia, SellingMode, TToken } from 'shared/types';
import { Image } from './image';

type TNftCardSmall = {
  media: NftMedia;
  mimetype?: string;
  collection: string;
  name: string;
  price?: string;
  isOwner?: boolean;
  token?: TToken;
};

export function NftCardSmall({
  media,
  collection,
  name,
  price,
  sellingMode,
  isOwner = false,
  token,
}: TNftCardSmall & { sellingMode?: SellingMode }) {
  const intl = useIntl();
  return (
    <div className="grid grid-cols-[97px_1fr] gap-x-4 items-center">
      <div className="border border-grey-850 p-2 ">
        <Image src={media.src} className="w-full" mimetype={media.mimetype} />
      </div>
      <div className="overflow-hidden">
        <div className="pb-2 mb-2 border-b border-grey-850">
          <div className="font-medium text-xs">{collection}</div>
          <div className="text-base text-black overflow-text">{name}</div>
        </div>
        {!isOwner && (
          <div className="">
            {sellingMode === 'DIRECT_SELL' ? (
              <div>
                {intl.formatMessage({
                  id: 'text.price',
                  defaultMessage: 'Price',
                })}
              </div>
            ) : sellingMode === 'AUCTION' ? (
              <div>
                {intl.formatMessage({
                  id: 'text.minimum_bid',
                  defaultMessage: 'Minimum bid',
                })}
              </div>
            ) : (
              ''
            )}
            <div>
              {price && token && (
                <PriceRendererCard token={token} price={price} />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
