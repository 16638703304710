import { Listbox, Transition } from '@headlessui/react';
import { ReactElement, ReactNode, useMemo } from 'react';
import {
  FieldValues,
  useController,
  UseControllerProps,
  UseFormTrigger,
  FieldPath,
} from 'react-hook-form';
import CheckIcon from 'assets/icons/check-icon.svg';

type SelectProps<T> = {
  options: T[];
  children: ReactNode[] | ReactNode;
};
export function Select<
  T extends { label: string; value: string },
  Fields extends FieldValues,
>({
  options,
  children,
  triggerList,
  disableInnerError,
  controller,
  trigger,
}: SelectProps<T> & {
  controller: UseControllerProps<Fields>;
  trigger?: UseFormTrigger<Fields>;
  triggerList?: FieldPath<Fields>[];
  disableInnerError?: boolean;
}) {
  const {
    field,
    fieldState: { error, isDirty, isTouched },
  } = useController(controller);

  const handleChange = (params: any) => {
    if (trigger && triggerList) {
      trigger(triggerList);
    }
    field.onChange(params);
  };

  const selectData = useMemo(
    () => (Array.isArray(children) ? children.flat() : children),
    [children],
  ) as ReactElement<T> | ReactElement<T>[];

  return (
    <div>
      <Listbox value={field.value} onChange={handleChange} name={field.name}>
        <div className="relative">
          <Listbox.Button className="listbox-btn" onBlur={field.onBlur}>
            {Array.isArray(selectData)
              ? selectData.find((item) => item.props.value === field.value)
              : selectData}
          </Listbox.Button>
          <Transition
            as={'div'}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute mt-1 max-h-36 w-full overflow-auto  bg-white py-1 text-base border border-grey-750 z-20">
              {Array.isArray(selectData) ? (
                selectData.map((item, index) => {
                  return (
                    <Listbox.Option value={item.props.value} key={index}>
                      <div
                        className="listbox-option"
                        data-active={item.props.value === field.value}
                      >
                        {item}
                        {item.props.value === field.value ? (
                          <span className="absolute inset-y-0 right-0 flex items-center pr-3 text-amber-600">
                            <img src={CheckIcon} />
                          </span>
                        ) : null}
                      </div>
                    </Listbox.Option>
                  );
                })
              ) : (
                <Listbox.Option value={selectData.props.value}>
                  <div
                    className="listbox-option"
                    data-active={selectData.props.value === field.value}
                  >
                    {selectData}
                    {selectData.props.value === field.value ? (
                      <span className="absolute inset-y-0 right-0 flex items-center pr-3 text-amber-600">
                        <img src={CheckIcon} />
                      </span>
                    ) : null}
                  </div>
                </Listbox.Option>
              )}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
      {!disableInnerError && isTouched && error ? (
        <div className="helper-text" data-invalid={Boolean(error)}>
          {error.message}
        </div>
      ) : null}
    </div>
  );
}

export function SelectOption({
  children,
  className = '',
}: {
  children: any;
  className?: string;
  value: string;
}) {
  return <span className={`block truncate ${className}`}>{children}</span>;
}
