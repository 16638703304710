import { makeAutoObservable, runInAction } from 'mobx';
import { TNft, PaginationOptions, PaginationItems } from 'shared/types';
import { getNftInteractor } from 'interactor/get-nft';
import { Address, Event, EventsBody } from 'api';
import { getActivityUseCase } from 'application/get-activity';

class NftDetailsActivityStore {
  activity: PaginationItems<Event> = { items: [], totalCount: 0 };
  isLoading: boolean = false;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  async getActivity(
    params: Omit<EventsBody, keyof PaginationOptions>,
    options?: PaginationOptions,
  ) {
    const activity = await getActivityUseCase(params, options);

    if (activity.totalCount <= 0) {
      return;
    }

    runInAction(() => {
      this.activity = activity;
    });
  }
}
export const activityStore = new NftDetailsActivityStore();

class CreateNftStore {
  data: TNft | null = null;

  isLoading: boolean = false;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  reset() {
    this.data = null;
  }
  async fetchNftDetails(address: Address) {
    this.isLoading = true;
    const nft = await getNftInteractor(address);

    runInAction(() => {
      this.data = nft;
      this.isLoading = false;
    });
  }
}

export const nftStore = new CreateNftStore();
