type PathImpl<T, K extends keyof T> = K extends string
  ? T[K] extends Record<string, any>
    ? T[K] extends ArrayLike<any>
      ? K | `${K}.${PathImpl<T[K], Exclude<keyof T[K], keyof any[]>>}`
      : K | `${K}.${PathImpl<T[K], keyof T[K]>}`
    : K
  : never;

export type Path<T> = PathImpl<T, keyof T> | keyof T;

export type PathValue<
  T,
  P extends Path<T>,
> = P extends `${infer K}.${infer Rest}`
  ? K extends keyof T
    ? Rest extends Path<T[K]>
      ? PathValue<T[K], Rest>
      : never
    : never
  : P extends keyof T
  ? T[P]
  : never;

export function getValue<T, P extends Path<T>>(
  object: T,
  keys: P,
): PathValue<T, P> | undefined {
  let splittedKeys;
  if (Array.isArray(keys)) {
    splittedKeys = keys;
  }
  if (typeof keys === 'string') {
    splittedKeys = keys.split('.');
  }

  if (splittedKeys) {
    if (splittedKeys.length > 1) {
      //@ts-expect-error
      return getValue(object[splittedKeys[0]], splittedKeys.slice(1));
    }
    return object[splittedKeys[0]];
  }

  return undefined;
}

export function setValue<T, P extends Path<T>>(
  object: T,
  keys: P,
  val: any,
): void {
  let splittedKeys;
  if (Array.isArray(keys)) {
    splittedKeys = keys;
  }
  if (typeof keys === 'string') {
    splittedKeys = keys.split('.');
  }

  if (splittedKeys) {
    if (splittedKeys.length > 1) {
      object[splittedKeys[0]] = object[splittedKeys[0]] || {};
      //@ts-expect-error
      return setValue(object[splittedKeys[0]], splittedKeys.slice(1), val);
    }
    object[splittedKeys[0]] = val;
  }
}
