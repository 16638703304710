import { domainContract } from 'shared/config';
import { toAddress } from 'shared/lib/utils';
import { NftListOptions, nftRepository } from 'shared/repository';
import { TNft, NftListFilter } from 'shared/types';
import { nftCreator } from './helpers/nft-creators';

export async function getNftListInteractor(
  filter?: NftListFilter,
  options?: NftListOptions,
) {
  try {
    const {
      items,
      totalCount,
      collection: rawCollection,
      auction,
      directSell,
      directBuy,
    } = await nftRepository.fetchNftList({
      filter,
      options,
    });

    const directBuys = directBuy ? Object.values(directBuy) : [];
    const mappedNfts: TNft[] = [];
    for (const nft of items) {
      const collection = rawCollection?.[nft.collection] ?? null;

      if (!collection || !nft) {
        continue;
      }
      try {
        let domainControls = false;
        if (nft.type === 'Everscale Domain') {
          const domainCtr = domainContract(toAddress(nft.address));
          const { status } = await domainCtr.methods
            .getStatus({ answerId: 0 })
            .call();

          domainControls = Boolean(['3', '4'].includes(status));
        }

        const directBuy = directBuys.filter((item) => item.nft === nft.address);

        const sellingShape = await nftCreator({
          nft,
          auction: auction ?? null,
          directBuy,
          directSell: directSell ?? null,
          domainControls,
          collection,
        });

        mappedNfts.push({
          ...nft,
          collection,
          sellingShape,
        });
      } catch (error) {
        console.error(error);

        continue;
      }
    }

    return { items: mappedNfts, totalCount };
  } catch (error) {
    console.error(error);
    return { items: [], totalCount: 0 };
  }
}
