import { NftCardSmall, ModalContext } from 'shared/ui';
import { observer } from 'mobx-react-lite';
import { makeBidForm } from '../model';
import { useContext, useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { placeBidFormSchema } from '../model/make-bid';
import { zodResolver } from '@hookform/resolvers/zod';
import { InputN } from 'shared/ui/molecules/input';
import { WEVER_ADDRESS } from 'shared/config';
import { useIntl } from 'react-intl';
import { TNft } from 'shared/types';

export const MakeBidModalContent = observer(
  ({ content }: { content: TNft }) => {
    const intl = useIntl();
    const { handleModal } = useContext(ModalContext);
    const {
      control,
      watch,
      getValues,
      setValue,
      reset,
      getFieldState,
      resetField,
      handleSubmit,
      trigger,
      register,
      formState: { isDirty, isValid, errors, isValidating, isSubmitSuccessful },
    } = useForm({
      mode: 'onChange',
      resolver: zodResolver(placeBidFormSchema),
      defaultValues: {
        bid: {
          paymentToken: WEVER_ADDRESS,
          value: '',
          minBid: '',
        },
      },
    });
    const selectPaymentToken = useWatch({
      control,
      name: 'bid.paymentToken',
    });

    useEffect(() => {
      resetField('bid.value');
    }, [selectPaymentToken, resetField]);

    useEffect(() => {
      reset();
    }, [isSubmitSuccessful, reset]);

    //TODO nasty hack
    useEffect(() => {
      if (
        content.sellingShape &&
        content.sellingShape?.sellingMode === 'AUCTION'
      ) {
        const { minBid, bidToken } = content.sellingShape;
        setValue('bid.minBid', minBid!);
        setValue('bid.paymentToken', bidToken.address);
      }
    }, [setValue]);

    if (content.sellingShape?.sellingMode !== 'AUCTION') {
      return null;
    }

    if (!content) {
      return null;
    }

    const {
      image,
      mimetype,
      name,
      collection: { name: collectionName },
      sellingShape: { auctionAddress, minBid, bidToken },
    } = content;

    return (
      <div className="max-w-[417px]">
        <div className="modal-title title">
          {intl.formatMessage({
            id: 'button.make_a_bid',
            defaultMessage: 'Place a bid',
          })}
        </div>
        <div className="modal-description">
          {intl.formatMessage(
            {
              id: 'button.make_a_bid.description',
              defaultMessage:
                'You are about to place a bid for {name} from {collectionName}, please check token and cost information before proceeding',
            },
            { name: name, collectionName: collectionName },
          )}
        </div>
        <div className="mb-9">
          <NftCardSmall
            media={{ src: image, mimetype }}
            collection={collectionName}
            price={minBid}
            token={bidToken}
            name={name}
            sellingMode="AUCTION"
          />
        </div>

        <div className="mb-9">
          <label className="text-black mb-1 inline-block">
            {intl.formatMessage({
              id: 'text.amount',
              defaultMessage: 'Amount',
            })}
          </label>
          <input hidden {...register('bid.minBid')} />
          <InputN
            disableInnerError
            disabled={Boolean(getFieldState('bid.paymentToken').error)}
            controller={{
              name: 'bid.value',
              control,
            }}
            number
          />
          {errors.bid && (
            <div className="helper-text" data-invalid={Boolean(errors.bid)}>
              {errors.bid?.paymentToken?.message || errors.bid?.value?.message}
            </div>
          )}
        </div>

        <div className="modal-actions">
          <button
            className="btn secondary"
            data-color="blue"
            onClick={() => handleModal()}
          >
            {intl.formatMessage({
              id: 'button.close',
              defaultMessage: 'Close',
            })}
          </button>
          <button
            className={`btn primary ${isValidating && 'loading'}`}
            data-color="blue"
            onClick={handleSubmit((data) =>
              makeBidForm.placeBid(data, auctionAddress),
            )}
            disabled={!isDirty || !isValid || isValidating}
          >
            {intl.formatMessage({
              id: 'button.make_a_bid',
              defaultMessage: 'Place a bid',
            })}
          </button>
        </div>
      </div>
    );
  },
);
