import { TToken } from '../types';

type MappedSelect = {
  value: string;
  label: string;
  decimals: number;
  logo?: string;
};

export function tokenSelectMapper<T = TToken | TToken[]>(
  tokens: T,
): T extends TToken[] ? MappedSelect[] : MappedSelect;
export function tokenSelectMapper(
  tokens: TToken[] | TToken,
): MappedSelect[] | MappedSelect {
  if (Array.isArray(tokens)) {
    return tokens.map((token) => ({
      value: token.address,
      label: token.symbol,
      decimals: token.decimals,
      logo: token.logoURI,
    }));
  }

  return {
    value: tokens.address,
    label: tokens.symbol,
    decimals: tokens.decimals,
    logo: tokens.logoURI,
  };
}
