import { makeAutoObservable, reaction } from 'mobx';
import {
  Permissions,
  hasEverscaleProvider,
  FullContractState,
  ContractState,
  Address,
  Contract,
  Subscription,
} from 'everscale-inpage-provider';
import { TOKEN_WALLET_ABI } from 'abi';
import {
  tokenRootContract,
  walletContract,
  walletProvider,
} from 'shared/config';
import { connectToWallet } from 'shared/lib/connect-to-wallet';
import { setValue, Path, PathValue } from 'shared/lib/set-get-prop';
import { TAddress } from 'shared/types';
import { toAddress } from './utils';

export type Account = Permissions['accountInteraction'];

export type WalletData = {
  account?: Account;
  balance: string;
  version?: string;
  contract?: ContractState | FullContractState;
};

type TCreateWallet = {
  walletData: WalletData;
  hasProvider: boolean;
  isConnecting: boolean;
  isInitialized: boolean;
  isInitializing: boolean;
  isUpdatingContract: boolean;
  address: string;
  isConnected: boolean;
  isReady: boolean;
  connect(): Promise<void>;
  disconnect(): Promise<void>;
  init(): Promise<void>;
  setState(
    path: Path<TCreateWallet>,
    value: PathValue<TCreateWallet, Path<TCreateWallet>>,
  ): void;
  getTokenWallet(
    tokenRoot: Address | string,
  ): Promise<Contract<typeof TOKEN_WALLET_ABI> | undefined>;
};

type V = PathValue<TCreateWallet, Path<TCreateWallet>>;

class WalletService {
  hasProvider = false;
  isConnecting = false;
  isInitialized = false;
  isInitializing = false;
  isUpdatingContract = false;
  walletData: WalletData = {
    account: undefined,
    balance: '0',
    contract: undefined,
  };

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });

    reaction(
      () => this.walletData.contract?.balance,
      (balance) => {
        this.setState('walletData.balance', balance || '0');
      },
      { fireImmediately: true },
    );

    reaction(
      () => this.walletData.account,
      (account, prevAccount) => {
        if (prevAccount?.address?.toString() === account?.address?.toString()) {
          this.setState('isConnecting', false);
          return;
        }

        this.onAccountChange(account).then(() => {
          this.setState('isConnecting', false);
        });
      },
      { fireImmediately: true },
    );

    this.init();
  }

  setState(
    path: Path<TCreateWallet>,
    value: PathValue<TCreateWallet, Path<TCreateWallet>>,
  ) {
    setValue(this as TCreateWallet, path, value);
  }

  /**
   * Manually connect to the wallet
   * @returns {Promise<void>}
   */
  async connect() {
    if (this.isConnecting) {
      return;
    }

    this.setState('isConnecting', true);

    try {
      const hasProvider = await hasEverscaleProvider();

      this.setState('hasProvider', hasProvider);
    } catch (e) {
      this.setState('hasProvider', false);
      return;
    }

    if (!this.hasProvider) {
      return;
    }

    try {
      await connectToWallet();
    } catch (e) {
      console.error(e);
    } finally {
      this.setState('isConnecting', false);
    }
  }

  /**
   * Manually disconnect from the wallet
   * @returns {Promise<void>}
   */
  async disconnect() {
    if (this.isConnecting) {
      return;
    }

    this.setState('isConnecting', true);

    try {
      await walletProvider.disconnect();
      // this.reset()
    } catch (e) {
      // error('Wallet disconnect error', e)
    } finally {
      this.setState('isConnecting', false);
    }
  }

  /**
   * Returns `true` if wallet is connected
   * @returns {boolean}
   */
  get isConnected() {
    return this.walletData.account?.address.toString() !== undefined;
  }

  /**
   * Returns computed wallet address value
   * @returns {string | undefined}
   */
  get address() {
    return this.walletData.account?.address.toString();
  }

  /**
   * Returns `true` if connection to RPC is initialized and connected
   * @returns {boolean}
   */
  get isReady() {
    return (
      !this.isInitializing &&
      !this.isConnecting &&
      this.isInitialized &&
      this.isConnected
    );
  }

  /**
   * Wallet initializing. It runs
   * @returns {Promise<void>}
   * @protected
   */
  async init() {
    this.setState('isInitializing', true);

    let hasProvider = false;

    try {
      await walletProvider.ensureInitialized();
    } catch (e) {
      return;
    }

    try {
      hasProvider = await hasEverscaleProvider();
    } catch (e) {
      console.log(e);
    }

    if (!hasProvider) {
      this.setState('hasProvider', false);
      this.setState('isInitializing', false);

      return;
    }

    this.setState('hasProvider', hasProvider);
    this.setState('isConnecting', true);

    const permissionsSubscriber = await walletProvider.subscribe(
      'permissionsChanged',
    );
    permissionsSubscriber.on('data', (event) => {
      this.setState('walletData.account', event.permissions.accountInteraction);
    });

    const currentProviderState = await walletProvider.getProviderState();

    if (currentProviderState.permissions.accountInteraction === undefined) {
      this.setState('isConnecting', false);
      this.setState('isInitialized', true);
      this.setState('isInitializing', false);

      return;
    }

    this.setState('walletData.version', currentProviderState.version);

    await connectToWallet();

    this.setState('isConnecting', false);
    this.setState('isInitialized', true);
    this.setState('isInitializing', false);
  }

  async getTokenWallet(
    tokenRoot: Address | string,
  ): Promise<Contract<typeof TOKEN_WALLET_ABI> | undefined> {
    if (!this.address) {
      return undefined;
    }

    return getTokenWallet(tokenRoot, this.address);
  }
  /**
   * Internal callback to subscribe for contract and transactions updates.
   *
   * Run it when account was changed or disconnected.
   * @param {Account} [account]
   * @returns {Promise<void>}
   * @protected
   */
  protected async onAccountChange(account?: Account): Promise<void> {
    if (this.contractSubscriber !== undefined) {
      if (account !== undefined) {
        try {
          await this.contractSubscriber.unsubscribe();
        } catch (e) {
          console.error('Wallet contract unsubscribe error', e);
        }
      }
      this.contractSubscriber = undefined;
    }

    if (account === undefined) {
      return;
    }

    this.setState('isUpdatingContract', true);

    try {
      const { state } = await walletProvider.getFullContractState({
        address: account.address,
      });

      this.setState('walletData.contract', state);
      this.setState('isUpdatingContract', false);
    } catch (e) {
      console.error('Get account full contract state error', e);
    } finally {
      this.setState('isUpdatingContract', false);
    }

    try {
      this.contractSubscriber = await walletProvider.subscribe(
        'contractStateChanged',
        {
          address: account.address,
        },
      );

      this.contractSubscriber.on('data', (event) => {
        console.info(
          "%cRPC%c The wallet's `contractStateChanged` event was captured",
          'font-weight: bold; background: #4a5772; color: #fff; border-radius: 2px; padding: 3px 6.5px',
          'color: #c5e4f3',
          event,
        );

        this.setState('walletData.contract', event.state);
      });
    } catch (e) {
      console.error('Contract subscribe error', e);
      this.contractSubscriber = undefined;
    }
  }

  get contract(): WalletData['contract'] {
    return this.walletData.contract;
  }

  async getBalance(token: string) {
    const tokenWallet = await this.getTokenWallet(token);

    if (!tokenWallet) {
      return 0;
    }

    const { value0: balance } = await tokenWallet.methods
      .balance({ answerId: 0 })
      .call();

    return balance;
  }

  private contractSubscriber: Subscription<'contractStateChanged'> | undefined;
}

export const wallet = new WalletService();

export async function getTokenWallet(
  root: TAddress,
  owner: TAddress,
): Promise<Contract<typeof TOKEN_WALLET_ABI>> {
  const rootInstance = tokenRootContract(toAddress(root));
  const { value0: tokenWallet } = await rootInstance.methods
    .walletOf({
      walletOwner: toAddress(owner),
      answerId: 0,
    })
    .call({ responsible: true });

  return wallet.isConnected
    ? walletContract(tokenWallet, walletProvider)
    : walletContract(tokenWallet);
}

export async function isTokenWalletDeployed(
  root: TAddress,
  owner: TAddress,
): Promise<boolean> {
  try {
    const tokenWallet = await getTokenWallet(root, owner);

    const { value0: balance } = await tokenWallet.methods
      .balance({ answerId: 0 })
      .call({ responsible: true });

    return true;
  } catch (e) {
    console.debug(e);
    return false;
  }
}
