import { makeAutoObservable, runInAction } from 'mobx';

import { CollectionListOptions, CollectionListFilter } from 'shared/repository';
import { getCollectionListUseCase } from 'application/get-collection-list';
import { Collection } from 'api';

class Collections {
  collections: { items: Collection[]; totalCount: number } = {
    items: [],
    totalCount: 0,
  };
  isLoading = false;
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  reset() {
    this.collections = { items: [], totalCount: 0 };
  }
  async getCollections({
    filter = {},
    options = {},
  }: {
    filter?: CollectionListFilter;
    options?: CollectionListOptions;
  }) {
    this.isLoading = true;
    const { items } = this.collections;
    if (items.length <= 0) {
      const collections = await getCollectionListUseCase({
        filter,
        options,
      });

      runInAction(() => {
        this.collections = collections;
        this.isLoading = false;
      });

      return;
    }

    const offset = options?.offset ?? 0;
    const limit = options?.limit ?? 30;

    const collections = await getCollectionListUseCase({
      filter: { ...filter },
      options: {
        ...options,
        offset,
        limit,
      },
    });

    if (collections.items.length < 0) {
      return;
    }

    runInAction(() => {
      this.collections = {
        items: [...this.collections.items, ...collections.items],
        totalCount: collections.totalCount,
      };
      this.isLoading = false;
    });
  }
}

export const nftCollections = new Collections();
