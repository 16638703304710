import { makeAutoObservable, runInAction } from 'mobx';

import { getTime, millisecondsToSeconds } from 'date-fns';

import BigNumber from 'bignumber.js';
import { startSellUseCase } from 'application/start-sell';
import { tokenService } from 'services/token-service';
import { z } from 'zod';
import { nonEmptyOrZeroToken } from 'shared/lib/validation-utils';
import { nftStore } from 'modules/nft';

export const saleFormSchema = z.object({
  price: nonEmptyOrZeroToken,
  startTime: z.date().optional(),
  endTime: z
    .union([z.null(), z.date()])
    .optional()
    .refine((date) => {
      if (!date) {
        return true;
      }
      return date > new Date();
    }, 'Invalid date'),
  selectDuration: z.string().optional(),
});

export type SaleFormSchema = z.infer<typeof saleFormSchema>;

class DirectSellForm {
  isLoading = false;
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  async startSell(
    { price: incomePrice, startTime, endTime }: SaleFormSchema,
    nftAddress: string,
  ) {
    this.isLoading = true;

    try {
      const token = tokenService.getToken(incomePrice.paymentToken);

      if (!token) {
        return;
      }

      const price = new BigNumber(incomePrice.value)
        .shiftedBy(token.decimals)
        .toFixed(0, BigNumber.ROUND_UP);
      const start =
        millisecondsToSeconds(getTime(startTime!)) ||
        millisecondsToSeconds(Date.now());
      const end = endTime && millisecondsToSeconds(getTime(endTime));

      // const startTime = millisecondsToSeconds(
      //   addMinutes(Date.now(), 1).getTime(),
      // );
      // const durationTime = 300;
      const params = {
        startTime: start,
        duration: end ? end - start : 0,
        nftAddress: nftAddress,
        paymentToken: token.address,
        price,
      };
      console.log('DirectSellParams: ', params);

      await startSellUseCase(params);
      nftStore.fetchNftDetails(nftAddress);
    } catch (error) {
      console.error(error);
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }
}

export const directSellForm = new DirectSellForm();
