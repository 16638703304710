import { makeAutoObservable } from 'mobx';
import { FilterCollectionsStore, FilterSaleTypeStore } from 'features/filter';
import { PaginationOptions, NftListFilter } from 'shared/types';

export class CreateFilterStore {
  filter: NftListFilter | null;
  filterCollectionsStore: FilterCollectionsStore;
  filterSaleTypeStore: FilterSaleTypeStore;

  request;
  constructor({
    request,
    filter,
  }: {
    request: (params: NftListFilter, options?: PaginationOptions) => void;
    filter?: NftListFilter;
  }) {
    this.request = request;
    this.filter = filter ?? null;
    this.filterCollectionsStore = new FilterCollectionsStore(this);
    this.filterSaleTypeStore = new FilterSaleTypeStore(this);
    makeAutoObservable(this, {}, { autoBind: true });
    console.log(filter);
  }

  async filterChange(filter: NftListFilter, options?: PaginationOptions) {
    this.filter = { ...this.filter, ...filter };

    this.request(this.filter, options);
  }
  clear(options?: { refetch?: boolean }) {
    if (options?.refetch) {
      this.filter = {};
      this.filterChange(this.filter);
    }

    this.filterSaleTypeStore.reset({ refetch: false });
    this.filterCollectionsStore.reset({ refetch: false });
  }
}
