import { getNftListInteractor } from 'interactor/get-nft-list';
import { matchPath } from 'react-router';
import { CreateFilterStore } from 'shared/lib/create-filter';
import { ListConstructor } from 'shared/lib/list-contructor';

const ITEMS_PER_PAGE = 28;

export const collectedNftList = new ListConstructor({
  request: getNftListInteractor,
});
export const filterNfts = new CreateFilterStore({
  request(params, options) {
    const matchRes = matchPath<'profileAddress', string>(
      '/profile/:profileAddress',
      location.pathname,
    );
    if (!matchRes) {
      return;
    }
    const profileAddress = matchRes.params.profileAddress;

    collectedNftList.getList(
      { ...params, owners: [profileAddress!], verified: false },
      { ...options, limit: ITEMS_PER_PAGE },
    );
  },
  filter: {
    verified: false,
  },
});
