import { ModalContext, NftCardSmall } from 'shared/ui';

import { observer } from 'mobx-react-lite';
import { purchaseForm } from '../model';
import { useIntl } from 'react-intl';
import { TNft } from 'shared/types';
import { useContext } from 'react';

export const PurchaseModalContent = observer(
  ({ content }: { content: TNft }) => {
    const intl = useIntl();
    const { handleModal } = useContext(ModalContext);
    if (content.sellingShape?.sellingMode !== 'DIRECT_SELL') {
      return null;
    }

    const {
      name,
      image,
      manager,
      mimetype,
      collection: { name: collectionName },
      sellingShape: { price },
    } = content;

    return (
      <div className="max-w-[417px]">
        <div className="modal-title title">
          {intl.formatMessage({
            id: 'modal.purchase_title',
            defaultMessage: 'Purchase',
          })}
        </div>
        <div className="modal-description">
          {intl.formatMessage({
            id: 'purchase.description',
            defaultMessage:
              'You intend to buy NFT, please check token and cost information before proceeding',
          })}
        </div>
        <div className="mb-9">
          <NftCardSmall
            media={{ src: image, mimetype }}
            collection={collectionName!}
            price={price.price}
            token={price.priceToken}
            name={name}
            sellingMode="DIRECT_SELL"
          />
        </div>

        <div className="modal-actions">
          <button
            className="btn secondary"
            data-color="blue"
            onClick={() => handleModal()}
          >
            {intl.formatMessage({
              id: 'button.close',
              defaultMessage: 'Close',
            })}
          </button>
          <button
            className={`btn primary `}
            data-color="blue"
            onClick={() =>
              purchaseForm.buy({
                directSellAddress: manager!,
                price,
              })
            }
          >
            {intl.formatMessage({
              id: 'button.buy_now',
              defaultMessage: 'Buy now',
            })}
          </button>
        </div>
      </div>
    );
  },
);
