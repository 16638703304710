import { sliceAddress } from '@broxus/js-utils';
import { Tab } from '@headlessui/react';
import { observer } from 'mobx-react-lite';
import { useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import EditIcon from 'assets/icons/edit-icon.svg';
import ShareIcon from 'assets/icons/share-icon-black.svg';
import {
  OffersIn,
  OffersOut,
  BidsIn,
  BidsOut,
  CollectedNftList,
} from 'modules/profile';
import { AccountIcon, AccountWithAddress } from 'shared/ui';
import { Layout } from 'widgets/layout';
import Sidebar from 'widgets/layout/sidebar';
import { useIntl } from 'react-intl';
import { NotFound } from 'widgets/layout/not-found';

export const ProfilePage = observer((): JSX.Element | null => {
  const intl = useIntl();
  const tabs = useMemo(
    () => ({
      collected: intl.formatMessage({
        id: 'text.collected',
        defaultMessage: 'Collected',
      }),
      offers: intl.formatMessage({
        id: 'text.offers',
        defaultMessage: 'Offers',
      }),
      bids: intl.formatMessage({ id: 'text.bids', defaultMessage: 'Bids' }),
      // created: 'Created',
      // favorited: 'Favorited',
      // activity: 'Activity',
    }),
    [],
  );
  const { profileAddress } = useParams<'profileAddress'>();

  const [searchParams, setSearchParams] = useSearchParams('?tab=collected');
  const selectedIndex = useMemo(
    () => Object.keys(tabs).indexOf(searchParams.get('tab') ?? 'collected'),
    [searchParams.get('tab')],
  );
  const [sidebarHidden, setSidebarHidden] = useState(true);
  const handleTab = (index: number) =>
    setSearchParams(
      {
        tab: Object.keys(tabs)[index],
      },
      { replace: true },
    );

  if (!profileAddress) {
    return <NotFound />;
  }

  return (
    <>
      <Layout onMenu={() => setSidebarHidden(false)}>
        <div className="container">
          <div>
            <div className="bg-[#F7F7F9] h-[138px] lg:h-[232px] xl:h-auto xl:aspect-[5/1]"></div>

            <div className="flex flex-col items-center -mt-[40px] md:-mt-[60px]">
              <div className="relative w-[80px] h-[80px] md:w-[120px] md:h-[120px]">
                <AccountIcon
                  className="w-full h-full"
                  address={profileAddress}
                />
                <label className="absolute top-0 left-0 flex items-center justify-center w-full h-full rounded-full bg-[rgba(0,0,0,.43)] opacity-0 hover:opacity-100 cursor-pointer">
                  <img src={EditIcon} alt="edit" />
                  <input className="hidden" type="file" accept="image/*" />
                </label>
              </div>

              <h2 className="text-[#2E2F33] text-[28px] leading-[32px] font-semibold mt-10 md:mt-5">
                {sliceAddress(profileAddress)}
              </h2>

              <div className="flex gap-x-2 items-center mt-4">
                <div className="bg-grey-950 px-3 py-2">
                  <AccountWithAddress
                    hideIcon
                    canCopy
                    asText
                    address={profileAddress}
                  />
                </div>
                <button className="h-9 bg-grey-950 px-2 hover:bg-[#e4e5ea]">
                  <img src={ShareIcon} alt="share" />
                </button>
                {/* <div className="relative">
                  <Menu>
                    <Menu.Button className="h-9 bg-grey-950 px-2 hover:bg-[#e4e5ea]">
                      <img src={DotsIcon} alt="menu" />
                    </Menu.Button>
                    <Menu.Items
                      as="ul"
                      className="dropdown-list w-[140px] mt-1"
                    >
                      <Menu.Item className="dropdown-item" as="li">
                        <button onClick={settingsModal.open}>Settings</button>
                      </Menu.Item>
                      <Menu.Item className="dropdown-item" as="li">
                        Report
                      </Menu.Item>
                    </Menu.Items>
                  </Menu>
                </div> */}
              </div>
            </div>
          </div>

          <div className="mt-20">
            <Tab.Group
              selectedIndex={Math.max(selectedIndex, 0)}
              onChange={handleTab}
            >
              <Tab.List className="flex overflow-x-auto">
                {Object.entries(tabs).map(([key, value], index) => (
                  <Tab
                    key={key}
                    className={({ selected }) =>
                      `tab-btn grow ${selected ? 'active' : ''}`
                    }
                  >
                    {value}
                  </Tab>
                ))}
              </Tab.List>
              <Tab.Panels className="pt-10 px-2">
                <Tab.Panel>
                  <CollectedNftList />
                </Tab.Panel>

                <Tab.Panel>
                  <div className="mb-40">
                    <OffersIn />
                  </div>
                  <div>
                    <OffersOut />
                  </div>
                </Tab.Panel>
                <Tab.Panel>
                  <div className="mb-40">
                    <BidsIn />
                  </div>
                  <div>
                    <BidsOut />
                  </div>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </div>
        </div>
      </Layout>
      <Sidebar
        mobile={true}
        hidden={sidebarHidden}
        onHide={() => setSidebarHidden(true)}
      />
    </>
  );
});
