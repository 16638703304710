import { formattedTokenAmount } from 'shared/lib/formatters';
import { secondsToMilliseconds } from 'date-fns';
import { DirectBuyBody, DirectBuyBody1 } from 'api';
import { offerRepository } from 'shared/repository';
import { tokenRepository } from 'shared/repository/token-repository';
import { Offer, PaginationItems, PaginationOptions } from 'shared/types';
import { offerMapper } from 'mappers/offer-mapper';

export async function getOffersForNftUseCase(
  params: DirectBuyBody,
  options?: PaginationOptions,
): Promise<PaginationItems<Offer>> {
  try {
    const { items, totalCount, collection, nft } =
      await offerRepository.getOffersByNft(params, options);

    if (!items || !collection || !nft) {
      return { items: [], totalCount: 0 };
    }

    const offers = items.map((offer) => {
      const token = tokenRepository.tokens.get(offer.price.priceToken)!;
      const tempNft = nft[offer.nft];
      const tempCollection = collection[tempNft.collection];
      return offerMapper({
        buyer: offer.buyer,
        status: offer.status,
        address: offer.address,
        nft: offer.nft,
        nftOwner: tempNft.owner,
        collectionName: tempCollection.name,
        isCollectionVerified: tempCollection.verified,
        nftImage: { src: tempNft.image, mimetype: tempNft.mimetype },
        nftName: tempNft.name,
        nftType: tempNft.type,
        price: {
          ...offer.price,
          price: formattedTokenAmount(offer.price.price, -token.decimals),
          priceToken: token,
        },
        saleType: tempNft.auction
          ? 'auction'
          : tempNft.forsale
          ? 'forsale'
          : '',
        createdAt: secondsToMilliseconds(offer.createdAt),
        expiredAt: secondsToMilliseconds(offer.expiredAt ?? 0),
        finishedAt: secondsToMilliseconds(offer.finishedAt ?? 0),
      });
      // return {
      //   buyer: offer.buyer,
      //   status: offer.status,
      //   offerAddress: offer.address,
      //   nftAddress: offer.nft,
      //   nftOwner: nft[offer.nft].owner,
      //   createdAt: format(
      //     secondsToMilliseconds(offer.createdAt),
      //     defaultDateFormat,
      //   ),
      //   price: {
      //     ...offer.price,
      //     priceToken: token,
      //     price: formattedTokenAmount(offer.price.price, -token.decimals),
      //   },
      //   expiredAt:
      //     offer.expiredAt &&
      //     format(secondsToMilliseconds(offer.expiredAt), defaultDateFormat),
      //   finishedAt:
      //     offer.finishedAt &&
      //     format(secondsToMilliseconds(offer.finishedAt), defaultDateFormat),
      //   saleType: nft[offer.nft].forsale || nft[offer.nft].auction,
      // };
    });

    return { items: offers, totalCount: totalCount! };
  } catch (error) {
    console.log(error);

    return { items: [], totalCount: 0 };
  }
}

export type OwnerOffersOut = Awaited<
  ReturnType<typeof getOffersByOwnerOutUseCase>
>;
export async function getOffersByOwnerOutUseCase(
  params: Omit<DirectBuyBody1, 'limit' | 'offset'>,
  options?: PaginationOptions,
): Promise<PaginationItems<Offer>> {
  try {
    const { items, collection, totalCount, nft } =
      await offerRepository.getOffersByOwnerOut(params, options);
    const offers: Offer[] = [];

    if (!items || !collection || !nft) {
      return { items: [], totalCount: 0 };
    }

    for (const offer of items) {
      const tempNft = nft[offer.nft];
      const tempCollection = collection[tempNft.collection];
      const token = tokenRepository.tokens.get(offer.price.priceToken)!;

      const mappedOffer = offerMapper({
        buyer: offer.buyer,
        status: offer.status,
        address: offer.address,
        nft: offer.nft,
        nftOwner: tempNft.owner,
        collectionName: tempCollection.name,
        isCollectionVerified: tempCollection.verified,
        nftImage: { src: tempNft.image, mimetype: tempNft.mimetype },
        nftName: tempNft.name,
        nftType: tempNft.type,
        price: {
          ...offer.price,
          price: formattedTokenAmount(offer.price.price, -token.decimals),
          priceToken: token,
        },
        saleType: tempNft.auction
          ? 'auction'
          : tempNft.forsale
          ? 'forsale'
          : '',
        createdAt: secondsToMilliseconds(offer.createdAt),
        expiredAt: secondsToMilliseconds(offer.expiredAt ?? 0),
        finishedAt: secondsToMilliseconds(offer.finishedAt ?? 0),
      });

      offers.push(mappedOffer);
    }

    return { items: offers, totalCount };
  } catch (error) {
    console.log(error);

    return { items: [], totalCount: 0 };
  }
}

export async function getOffersByOwnerInUseCase(
  params: Omit<DirectBuyBody1, 'limit' | 'offset'>,
  options?: PaginationOptions,
) {
  try {
    const { items, collection, totalCount, nft } =
      await offerRepository.getOffersByOwnerIn(params, options);
    const offers: Offer[] = [];

    if (!items || !collection || !nft) {
      return { items: [], totalCount: 0 };
    }

    for (const offer of items) {
      const tempNft = nft[offer.nft];
      const tempCollection = collection[tempNft.collection];
      const token = tokenRepository.tokens.get(offer.price.priceToken)!;

      const mappedOffer = offerMapper({
        buyer: offer.buyer,
        status: offer.status,
        address: offer.address,
        nft: offer.nft,
        nftOwner: tempNft.owner,
        collectionName: tempCollection.name,
        isCollectionVerified: tempCollection.verified,
        nftImage: { src: tempNft.image, mimetype: tempNft.mimetype },
        nftName: tempNft.name,
        nftType: tempNft.type,
        price: {
          ...offer.price,
          price: formattedTokenAmount(offer.price.price, -token.decimals),
          priceToken: token,
        },
        saleType: tempNft.auction
          ? 'auction'
          : tempNft.forsale
          ? 'forsale'
          : '',
        createdAt: secondsToMilliseconds(offer.createdAt),
        expiredAt: secondsToMilliseconds(offer.expiredAt ?? 0),
        finishedAt: secondsToMilliseconds(offer.finishedAt ?? 0),
      });

      offers.push(mappedOffer);
    }

    return { items: offers, totalCount };
  } catch (error) {
    console.log(error);

    return { items: [], totalCount: 0 };
  }
}
